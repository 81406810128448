<template>
    <div class="titleCase" v-if="isWechatState == true">
        <ul class="titlecontent">请长按网址复制后使用浏览器访问</ul>
        <ul class="titlecontent">或点击右上角三个点"..."选择"在浏览器打开"</ul>
        <ul class="titlecontent">https://jw.modewe.com/美都汇商城.apk</ul>
    </div>
</template>
  
<script>
export default {
    data() {
        return { isWechatState: true };
    },
    mounted() {
        this.isWechatState = this.isWechat()
        this.doDownload()
        console.log(this.isWechatState);
    },
    methods: {
        isWechat() {
            var ua = window.navigator.userAgent.toLowerCase();
            if (ua.match(/micromessenger/i) == 'micromessenger') {
                return true;
            } else {
                return false;
            }
        },
        doDownload() {
            if (this.isWechatState == false) {
                window.location.href = "https://jw.modewe.com/美都汇商城.apk"
            }
        }
    },
};
</script>
  
<style scoped>
.titleCase {
    width: 100%;
    margin-top: 220px;

}

.titlecontent {
    text-align: center;
    font-size: 20px;
}

.titleCase .titlecontent:nth-child(1) {
    letter-spacing: 2px;
}

.titleCase .titlecontent:nth-child(3) {
    margin-top: 40px;
}
</style>
  