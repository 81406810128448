<template>
    <div class="bodyContent">
        <!-- 标题栏 -->
        <div class="header">
            <ul class="header_content">
                <li class="pageSign">
                    <img src="../../assets/image/homePage/sign_text.png" alt="">
                    <img src="../../assets/image/homePage/sign.png" alt="" @click="ababab(1, 0)">
                </li>
                <li class="homePage">
                    <a href="#/">首页</a>
                </li>
                <li class="download">
                    <p><a href="https://jw.modewe.com/shop.apk">下载美都汇</a></p>
                    <van-icon name="back-top" class="download_sign" />
                </li>
                <li class="aboutUas" @click="changeState" :style="aboutUasUnfold">
                    <div>
                        <p>关于我们</p>
                        <van-icon v-if="aboutState == false" name="arrow-down" class="aboutUas_sign" />
                        <van-icon v-else name="arrow-up" class="aboutUas_sign" />
                        <p class="introduction" @click="ababab(2, 1)">
                            <a href="#/companyInfo">公司简介</a>
                        </p>
                        <p class="introduction">
                            <a href="#/privacypolicy">隐私协议</a>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="bodyContent">
            <ul class="bodyContent_title">
                <li v-for="(item, index) in list" :class="{ actived: active == index }" @click="change(index)">
                    <p @click="test">{{ item }}</p>
                </li>
            </ul>
        </div>
        <p class="MsoNormal bodytitle" align="center" style="text-align:center;">
            <b>隐私协议</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>
                <span class="title_1 title_topadd">特别提示</span>
                <p></p>
                <span class="title_top">本政策仅适用于济南美都汇电子商务有限公司及其关联公司，以下或称</span> <span>(</span><span>“我们”或“美都汇”</span><span>)
                </span>
                <span>提供的产品和服务及其延伸的功能</span><span>(</span><span>以下简称“美都汇服务”</span><span>)</span><span>，包括美都汇</span><span>APP</span><span>、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用，该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。如我们关联公司的产品或服务中使用了美都汇提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。</span>
            </b><b></b>
        </p>
        <p class="MsoNormal">
            <b
                class="title_weight title_top title_topclean">请仔细阅读《美都汇商城隐私政策》并确定了解我们对您个人信息的处理规则。阅读过程中，如有您任何疑问，可通过《美都汇商城隐私政策》中的联系方式咨询我们。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_1">引言</b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_top"><span>欢迎您使用美都汇</span><span>app!</span><span>我们非常重视用户的隐私和个人信息保护。您在使用我们的产品与</span><span>/</span><span>或服务时，我们可能会收集和使用您的相关信息。我们希望通过《美都汇商城隐私政策》
                </span><span>(</span><span>“本隐私政策”</span><span>)
                </span><span>向您说明我们在您使用我们的产品与</span><span>/</span><span>或服务时如何收集、使用、保存、共享和转让这些信息，以及我们为您提供的访问、更新、删除和保护这些信息的方式</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_top">本隐私政策将帮助您了解以下内容</b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_top"> 一、我们如何收集和使用您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>二、我们如何使用</span> <span>Cookies </span><span>和同类技术</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>三、我们如何共享、转让、公开披露您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>五、我们如何管理您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>六、未成年人的个人信息保护</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>七、通知和修订</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>八、极光推送 SDK</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>九、如何联系我们</b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_weight title_top"><span>本隐私政策与您所使用的美都汇服务以及该服务所包括的各种业务功能</span><span>(</span><span>以下统称“我们的产品与</span><span>/</span><span>或服务”</span><span>)
                </span><span>息息相关，希望您在使用我们的产品与</span><span>/</span><span>或服务前仔细阅读并确认您已经充分理解本隐私政策所写明的内容，并让您可以按照本隐私政策的指引做出您为适当的选择。本隐私政策中涉及的相关术语，我们尽量以简明扼要的表述，并提供进一步说明的链接，以便您更好地理解。请您特别注意</span><span>:</span><span>本隐私政策适用于美都汇提供的产品或服务。如我们关联公司的产品或服务中使用了美都汇提供的产品或服务但未设置独立隐私政策的，则本隐私政策同样适用于该部分产品或服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_1">一、我们如何收集和使用您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b
                class="title_top"><span>个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。我们会遵循正当、合法、必要的原则，出于本政策所述的以下目的，收集和使用您在使用产品与</span><span>/</span><span>或服务过程中主动提供或因使用产品与</span><span>/</span><span>或服务而产生的个人信息，以及从第三方获取您的相关个人信息。如果我们要将您的个人信息用于本政策未载明的其它用途，或将基于特定目的收集而来的信息用于其他目的，我们将以合理的方式向您告知，并在使用前再次征得您的同意。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b
                class="title_top title_topclean"><span>(</span><span>一</span><span>)</span><span>您需要授权我们收集和使用个人信息的场景我们会遵循正当、合法、必要的原则，出于本政策所述的下列目的收集和使用您的个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、帮助您成为我们的用户</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>
                <span>
                    为遵守法律法规的要求，以及向您提供更优质、更便捷的服务，在您注册成为美都汇用户时，您需要事少提供手机号码以创建美都汇账号，并完善相关的网络身份识别信息
                </span>
                <span>(</span>
                <span>如头像、昵称及登录密码等</span>
                <span>)</span>
                <span>。</span>
                <span>如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息</span>
                <span>(</span><span>如头像、昵称、地区、性别等信息</span>
                <span>)</span><span>与您的美都汇账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</span>
                <span>如您选择授权使用第三方账号登录时，我们会从第三方获取您共享的账号信息</span>
                <span>(</span><span>如头像、昵称、地区、性别等信息</span><span>)</span>
                <span>与您的美都汇账号进行绑定用于快捷登录，我们会依据与第三方的约定，在符合相关法规的前提下，使用您的个人信息。</span>
            </b>
            <b>

            </b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、向您提供基于地理位置的服务</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>当您使用我们提供的产品</span><span>/</span><span>服务时，我们会收集您的浏览、搜索、点击、收藏、添加购物车、交易、售后、关注、分享、发布等信息并作为有关网络日志进行保存，其中包括您的</span><span>IP</span><span>地址、浏览器的类型、使用的语言、操作系统的版本、访问的日期和时间、电信运营商、网络请求等。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>*</span><span>、为您提供商品或服务信息展示</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>在您使用我们服务过程中，为保障您的账户安全、识别账户异常状态和风控、了解产品适配性、向您提供更契合您需求的页面展示和搜索结果，适配不同地理位置的所对应商家所能提供的不同产品，我们可能会自动收集您的使用情况并存储为网络日志信息，包括</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、为您提供预订、下单、订单管理功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您在美都汇上预订</span><span>/</span><span>下单时，您需要根据所选择的服务类型提供联系人信息</span><span>(</span><span>姓名、性别、电话号码等</span><span>)</span><span>、地址信息等。为向您展示您账号的订单信息及便于您对订单信息的管理，我们会收集您在使用我们服务过程中产生的订单信息。</span><span>您在美都汇上生成的订单中</span><span>,</span><span>将可能包含您的身份信息、联络信息、支付信息等，这些都属于敏感信息请您谨慎向他人展示或对外提供，我们也将会在展示订单信息时</span><span>,</span><span>在保证信息可用性的基础上尽量去标识化处理。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、为您提供写评论、签到信息发布功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您在美都汇上主动对商家的产品</span><span>/</span><span>服务进行评价或发布其他内容</span><span>(</span><span>如分享心情、状态、签到、攻略等信息</span><span>)</span><span>我们将收集您发布的信息，并展示您的昵称、头像和发布内容等。如您选择以匿名方式发布评价信息时，我们将不展示您的昵称、头像。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、帮助您完成支付您在美都汇上进行订单支付时</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您可以选择美都汇及其关联方</span><span>(</span><span>如余额支付</span><span>)</span><span>或者美都汇的第三方合作机构</span><span>(</span><span>如微信支付、支付宝、云闪付等合作方</span><span>)</span><span>所提供的服务进行支付。我们需要收集您的美都汇订单信息、对账信息及其他法律要求的必要信息并与这些合作机构共享，以确认您的支付指令并帮助您完成支付。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>6</span><span>、为您提供</span><span>/</span><span>展示支付金融产品</span><span>/</span><span>服务</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>当您使用美都汇上提供</span><span>/</span><span>展示的金融产品</span><span>/</span><span>服务时</span><span>,</span><span>我们还可能会收集您的身份证件信息、银行卡信息、增票资质等，具体的信息收集和使用规则请参见与您另行签署的具体服务协议</span><span>/</span><span>文件</span><span>/</span><span>规则。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>7</span><span>、我们的电话客服和售后功能会使用您的账号信息和订单信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>为保证您的账号安全，我们的在线客服会使用您的账号信息与您核验您的身份，当您需要我们提供与您订单信息相关的客服与售后服务时，我们将会查询您的订单信息。您有可能会在与我们的客服人员沟通时提供给出上述信息外的其他信息，如当您要求我们变更配送地址、联系人或联系电话。为了实现对您的商品退换货服务，我们将收集或查询您购买商品的质保起止期、退换货商品快递公司、快递单号、退货地址、投递时间信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>8</span><span>、为您提供安全保障</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>为了保障您的账号安全、交易安全以及系统运行安全，满足法律法规和我们协议规则的相关要求，在您访问、使用、后台运行我们的产品</span><span>/</span><span>服务过程中，经您授权我们会获取您的设备信息，包括您使用的设备型号、硬件序列号、设备</span><span>MAC</span><span>地址、唯一设备识别码(如IMEI/androidID/IDFA/OPENUDID/GUID/OAID</span><span>、</span><span>SIM</span><span>卡</span><span>IMSI</span><span>、</span><span>ICCID</span><span>信息等设备标识符</span><span>)</span><span>、移动应用列表、电信运营商等软硬件特征信息。为提高您使用我们提供的服务的安全性，更准确地预防钓鱼网站欺诈和木马病毒，我们可能会使用或整合您的个人信息以乃我们的关联方合作伙伴取得您授权成考依法共享的信息</span><span>,</span><span>根据您的使用习惯和常用软件信息等来综合判断您的账号及交易风险，包括验证身份，预防、发现、调查可能存在的欺诈、网络病毒、网络攻击等安全风险以及违反我们或关联方协议、政策或规则等行为，以保护您、其他用户、我们或关联方的合法权益，并记录一些我们认为有风险的链接</span><span>(</span><span>UR</span></b><b>L)</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>9</span><span>、为了提供及时的消息触达</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>针对商城在用户下单、退货、退款、资产变动等多种应用场景的重要信息推送的触达率和及时率的问题我们接入了友盟推送服务</span><span>.</span><span>需要及时有效的收到这些信息自启动和关联启动是一种强需求</span><span>,</span><span>如果关掉会影响相应</span><span>APP</span><span>的必要信息推送。遵循“公开透明”和“最小必要”原则。美都汇商城向用户告知且经用户同意的情况下进行自启动或关联启动并仅在合理的使用场景下以为终端用户提供服务为目的的自启动与关联启动帮助启动应用后台进程从而收到消息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>二</span><span>)</span><span>您可以选择授权我们收集和使用个人信息的场景为向您提供优质的服务，您可以选择使用我们提供的拓展功能，我们会在符合法律规定并根据您具体授权的情况下收集并使用如下信息。这类信息将在您选择的具体功能和业务场景中进行收集，如果您不提供这些信息，不会影响您使用美都汇的基本功能。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、基于相机授权的拓展功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您可以选择开启系统的相机权限，通过使用拍照、录视频，扫码等功能授权美都汇访问您的相机，以便于您通过拍摄照片或录制视频等方式发布内容，如果您需要录制并发布有声视频时，您还需开启麦克风权限。我们会收集您上传发布的上述信息，此项功能您可以在系统权限中关闭</span><span>,</span><span>一旦关闭您将可能无法实现扫码功能。或者无法通过拍摄图片、视频等方式进行点评或互动，但不会影响您享受美都汇服务的基本功能。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、基于相册授权的拓展功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>您可以选择开启系统的相册权限，通过主动上传图片、视频等方式授权我们访问您的相册，以便于您通过上传照片或上传视频等方式发布内容。我们会收集您选择上传发布的上述信息，此项功能您可以在系统权限中关闭，一旦关闭您将可能无法通过上传图片、视频等方式进行更换头像、发布带图点评等，但不会影响您享受美都汇服务的基本功能。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、基于日历授权的拓展功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>当您使用直播订阅、秒杀活动提醒、签到提醒等功能时，我们会申请获取您的设备日历权限。在您开启相关权限后</span><span>,</span><span>我们会访问您的日历，帮助您预约或调整已预约的直播订阅、秒杀活动提醒、签到提醒的时间，以在您预约的时间通过日历功能提醒您。拒绝授权将无法使用预约服务，但不会影响美都汇其他功能的正常使用。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、基于剪切板授权的拓展功能</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>在您分享或接收被分享的信息、参加活动等情形下我们需要在本地访问您的剪切板权限，读取其中包含的口令、分享码、链接</span><span>,</span><span>以实现跳转、分享、活动联动等功能或服务。我们仅在本地识别出剪切板内容属于美都汇跳转、分享、活动联动等指令时才会将其上传我们的服务器。除此之外，我们不会上传您剪切板的其他信息至我们的服务器。有些手机设备的系统会提示应用</span><span>(App)</span><span>正在读取剪切板，但该系统提示无法区分是本地处理还是上传，且由于统计方式的差异，不排除误报的可能。因此，如果您发现类似提示并有疑问，请与我们联系，以便我们定位问题。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>三</span><span>)</span><span>您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的授权同意</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、与国家安全、国防安全直接相关的</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、与公共安全、公共卫生、重大公共利益直接相关的</span><span>;</span><span>与刑事侦查、起诉、审判和判决执行等直接相关的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、所涉及的个人信息是个人信息主体自行向社会公众公开的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>6</span><span>、从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>7</span><span>、根据您的要求签订和履行合同所必需的</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>8</span><span>、用于维护所提供的产品与</span><span>/</span><span>或服务的安全稳定运行所必需的，例如发现、处置产品与</span><span>/</span><span>或服务的故障</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>9</span><span>、与个人信息控制者履行法律法规规定的义务相关的。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>四</span><span>)</span><span>我们从第三方获得您个人信息的情形</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>我们可能从第三方获取您授权共享的账户信息</span>
                <span>(</span><span>头像、昵称及授权页面提示的其他信息</span><span>)</span><span>，并在您同意本隐私政策后将您的第三方账户与您的美都汇账户绑定，使您可以通过第三方账户直接登录并使用我们的产品与</span><span>/</span><span>或服务。我们会将依据与第三方的约定、对个人信息来源的合法性进行确认后，在符合相关法律和法规规定的前提下，使用您的这些个人信息。您也可以使用本机号码来快捷登录，我们会将您的网络信息提供给运营商完成注册或登录。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>五</span><span>)</span><span>美都汇</span><span>APP</span><span>涉及嵌入第三方代码、插件传输个人信息的情形逐项列举</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>为保障美都汇相关功能的实现和应用的安全稳定运行，我们会接入由第三方提供的软件开发包</span>
                <span>(SDK)</span><span>实现该目的。我们会对第三方进行严格的安全检测，并要求合作伙伴采取严格的措施来保护您的个人数据。在满足新的服务需求及业务功能变更时，我们可能会调整我们接入的第三方可能因为版本升级、策略调整等原因导致数据类型存在一些变化，附其官方网站链接或相关隐私保护说明链接供参考，具体请以其公示的官方说明为准。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b class="title_top"><span>美都汇接入的第三方</span><span>SDK</span><span>列表如下</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>Android</span><span>端如下</span></b><b></b>
        </p>
        <table class="MsoNormal title_topclean" border="1" cellspacing="0" style="border:none;">
            <tbody>
                <tr>
                    <td style="width: 120px;">
                        <p class="" style="text-align:center;">
                            <span>第三方名称</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>第三方公司名称</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>收集的信息类型</span>
                        </p>
                    </td>
                    <td style="width: 120px;">
                        <p class="" style="text-align:center;">
                            <span>收集信息的目的</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>第三方隐私政策链接</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>微信</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>深圳市腾讯计算机系统有限公司</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>常用设备信息(包括设备识别号、MAC地址、IMEI、IDFA、AndroidID、OAID等)</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>第三方登录/第三方分享</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>https://www.wechat.com/zh_CN/privacy-policy.html</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>微信支付</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>深圳市腾讯计算机系统有限公司</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>常用设备信息(包括设备识别号、MAC地址、IMEI、IDFA、AndroidID、OAID等)</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>第三方支付</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>https://www.wechat.com/zh_CN/privacy-policy.html</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>支付宝支付</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>支付宝(中国)网络技术有限公司</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>不可变更设备唯一标识、可变更设备唯一标识、基本设备信息、软件安装列表以及网络信息</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>第三方支付</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>https://opendocsalipay.com/open/54/01g6qm</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>华为</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>华为软件技术有限公司</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>推送消息</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>https://developer.huawei.com/consumer/cn/doc/de</span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>HMSSDK</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span></span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span></span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span></span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>velopment/HMScore-Guides/sdk-data-security-000o001050042177
                            </span>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td style="width: 200px;">
                        <p class="">
                            <span>华为统一扫码</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>华为技术有限公司</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="">
                            <span>终端语言、终端版本、终端操作系统名、终端操作系统版本号、终端型号、时区、联网方式、基带版本</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>构建扫码能力</span>
                        </p>
                    </td>
                    <td style="width: 200px;">
                        <p class="" style="text-align:center;">
                            <span>https://developer.huawei.com/consumer/cn/doc/dGuides/service-introduction-o000o01050041994section1720695013254</span>
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
        <p class="MsoNormal">
            <b class="title_top"><span>(六</span><span>) </span><span>您个人信息使用的规则</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、我们会根据本隐私政策的内容，并为实现我们的产品与</span><span>/</span><span>或服务功能对所收集的个人信息进行使用。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、在收集您的个人信息后，我们将通过技术手段对数据进行去标识化处理，去标识化处理的信息将无法识别主体。请您了解并同意，在此情况下我们有权使用已经去标识化的信息，并在不透露您个人信息的前提下，我们有权对用户数据库进行分析并予以商业化的利用。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、我们会对我们的产品与</span><span>/</span><span>或服务使用情况进行统计，并可能会与公众或第三方共享这些统计信息，以展示我们的产品与</span><span>/</span><span>或服务的整体使用趋势。但这些统计信息不包含您的任何身份识别信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、当我们展示您的个人信息时，我们会采用包括内容替换、匿名化或者去标识化处理方式对您的信息进行脱敏，以保护您的信息安全。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、当我们要将您的个人信息用于本隐私政策未载明的其它用途时，或基于特定目的收集而来的信息用于其他目的时，会事先征求您的明示同意。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>请您理解，我们向您提供的产品与</span><span>/</span><span>或服务将不断更新变化。如果您选择使用本隐私政策中尚未列明的其他产品与</span><span>/</span><span>或服务时，我们会在收集您的个人信息前通过协议、页面提示等方式向您详细说明信息收集的目的、方式、范围并征求您的同意。若您不同意提供前述信息，您可能无法使用该项产品与</span><span>/</span><span>或服务，但不影响您使用现有产品与</span><span>/</span><span>或服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>二、我们如何使用</span><span>Cookies</span><span>和同类技术</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>一</span><span>)</span><span>、</span><span>Cookie</span><span>的使用</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>为确保网站正常高效运转、为您获得更轻松的访问体验，我们会在您的设备终端</span><span>/</span><span>系统上存储名为</span><span>Cookie</span><span>的小数据文件。</span><span>Cookie</span><span>会帮助您在后续访问我们网站时调用您的信息，简化您填写个人信息</span><span>(</span><span>例如一键登录等</span><span>)</span><span>的流程</span><span>:</span><span>保护您的数据安全等。我们不会将</span><span>Cookie</span><span>用于本隐私政策所述目的之外的任何用途。您可根据自己的偏好管理或删除</span><span>Cookie</span><span>。您可以清除计算机或移动设备</span><span>.</span><span>上保存的所有</span><span>Cookie,</span><span>您有权接受或拒绝</span><span>Cookie</span><span>。大多数浏览器会自动接受</span><span>Cookie,</span><span>但您通常可根据自己的需要来修改浏览器的设置以拒绝</span><span>Cookie;</span><span>另外</span><span>,</span><span>您也可以清除软件内保存的所有</span><span>Cookie</span><span>。但您可能因此无法完全体验我们某些便捷性和安全性的服务功能。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>二</span><span>)</span><span>、网络</span><span>Beacon</span><span>和同类技术的使用</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>除</span><span>Cookie</span><span>外，我们网页上可能会包含一些电子图象</span><span>(</span><span>称为</span><span>"</span><span>单像素</span><span>"GIF</span><span>文件或</span><span>"</span><span>网络</span><span>Beacon)</span><span>等其他同类技术，它们可以帮助网站计算浏览网页的用户或访问某些</span><span>Cookie</span><span>。我们使用网络</span><span>Beacon</span><span>的方式有</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、我们通过在我们网站上使用网络</span><span>Beacon,</span><span>计算用户访问数量，并通过访问</span><span>Cookie</span><span>辨认注册用户</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、我们通过得到的</span><span>Cookie</span><span>信息，可以在我们网站提供更优质服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>三、我们如何共享、转让、公开披露您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>事先获得您明确的同意或授权</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2) </span><span>根据适用的法律法规、行政或司法部门强制性要求进行提供</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>在法律法规允许的范围内，为维护美都汇、美都汇的关联方或合作伙伴、您或其他美都汇用户或社会公众利益、财产或安全免遭损害而有必要提供</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>只有共享您的个人信息，才能实现我们的产品与</span><span>/</span><span>或服务的核心功能或提供您需要的服务</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(5) </span><span>应您需求为您处理您与他人的纠纷或争议</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(6)</span><span>符合与您签署的相关协议 </span><span>(</span><span>包括在线签署的电子协议以及相应的平台规则</span><span>)
                </span><span>或其他的法律文件约定所提供</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(7)</span><span>基于学术研究而使用</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(8)</span><span>基于符合法律法规的社会公共利益、突发公共卫生事件而使用。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途。我们的合作伙伴包括以下类型</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>商品或专业技术服务的供应商。我们可能会将您的个人信息共享给支持我们功能的第三方。这些支持包括为我们的供货或提供基础设施技术服务、物流配送服务、支付服务、数据处理等。我们共享这些信息的目的是可以实现我们产品与</span><span>/</span><span>或服务的核心购物功能，比如我们必须与物流服务提供商共享您的订单信息才能安排送货</span><span>:</span><span>当您下单的商品或服务由供应商直接发货或提供服务时，我们需要将您订单中的订购信息和收货信息共享给供应商，以指示其交付商品或提供服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2) </span><span>第三方商家。我们必须将您的订单信息与交易有关的必要信息与第三方商家共享来实现您向其购买商品或服务的需求，并促使其可以完成后续的售后服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、为了遵守法律、执行或适用我们的使用条件和其他协议，或者为了保护美都汇、您或其他美都汇客户的权利及其财产或安全，比如为防止欺诈等违法活动和减少信用风险，而与其他公司和组织交换信息。不过这并不包括违反本隐私政策中所作的承诺而为获利目的出售、出租、共享或以其它方式披露的个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、对我们与之共享个人信息的公司、组织和个人我们会与其签署严格的保密协定，要求他们按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。在个人敏感数据使用上，我们要求第三方采用数据脱敏和加密技术，从而更好地保护用户数括。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、关于我们共享的合作伙伴等请见本隐私政策第一条第</span><span>(</span><span>五</span><span>)</span><span>款中美都汇接入的第三方</span><span>SDK</span><span>列表。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>请您注意，您在使用我们服务时自愿共享甚至公开分享的信息，可能会涉及您或他人的个人信息甚至敏感个人信息。请您共享时谨慎考虑并决定。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>二</span><span>) </span><span>转让</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、事先获得您明确的同意或授权</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、在涉及合并、收购、破产清算或分立时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则</span><span>,</span><span>我们将要求该公司、组织重新向您征求授权同意。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>三</span><span>)</span><span>公开披露</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>我们仅会在以下情况下，且采取符合业界标准的安全防护措施的前提下，才会公开披露您的个人信息</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、根据您明确同意的披露范围披露相应的个人信息</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、根据法律法规、法律程序、诉讼或政府主管部门的强制性要求的情况下，我们可能会公开披露您的人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>四、我们如何保护和保存您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(</span><span>一</span><span>)</span><span>我们保护您个人信息的技术与措施</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>我们非常重视个人信息安全，并采取一切合理可行的措施，保护您的个人信息</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、数据安全技术措施</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>我们会采用符合业界标准的安全防护措施，包括建立合理的制度规范、安全技术来防止您的个人信息遭到未经授权的访问使用、修改避免数据的损坏或丢失。美都汇的网络服务采取了传输层安全协议等加密技术，通过</span><span>https</span><span>等方式提供浏览服务，确保用户数据在传输过程中的安全。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇采取加密技术对用户个人信息进行加密保存并通过隔离技术进行隔离。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>在个人信息使用时，例如个人信息展示、个人信息关联计算，我们会采用包括内容替换、</span><span>SHA256</span><span>在内多种数据脱敏技术增强个人信息在使用中安全性。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇采用严格的数据访问权限控制和多重身份认证技术保护个人信息，避免数据被违规使用。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇采用代码安全自动检查、数据访问日志分析技术进行个人信息安全审计。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、美都汇为保护个人信息采取的其他安全措施</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇通过建立数据分类分级制度、数据安全管理规范、数据安全开发规范来管理规范个人信息的存储和使用。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇通过信息接触者保密协议、监控和审计机制来对数据进行全面安全控制。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>美都汇建立数据安全委员会并下设信息保护专职部门、数据安全应急响应组织来推进和保障个人信息安全。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b>加强安全意识。我们还会举办安全和隐私保护培训课程，加强员工对于保护个人信息重要性的认识。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、我们仅允许有必要知晓这些信息的美都汇及美都汇关联方的员工、合作伙伴访问个人信息，并为此设置了严格的访问权限控制和监控机制。我们同时要求可能接触到您个人信息的所有人员履行相应的保密义务。如果未能履行这些义务，可能会被追究法律责任或被中止与美都汇的合作关系。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、我们会采取一切合理可行的措施，确保未收集无关的个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、互联网并非绝对安全的环境，而且电子邮件、即时通讯、社交软件等与其他用户的交流方式无法确定是否完全加密，我们建议您使用此类工具时请使用复杂密码，并注意保护您的个人信息安全。在通过美都汇与第三方进行网上产品与</span><span>/</span><span>或服务的交易时，您不可避免的要向交易对方或潜在的交易对方披露自己的个人信息，如联络方式或者邮政地址等。请您妥善保护自己的个人信息，仅在必要的情形下向他人提供。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>6</span><span>、安全事件处置</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>为应对个人信息泄露、损毁和丢失等可能出现的风险，美都汇制定了多项制度，明确安全事件、安全漏洞的分类分级标准及相应的处理流程。美都汇也为安全事件建立了专门的应急响应团队，按照安全事件处置规范要求，针对不同安全事件启动安全预案，进行止损、分析、定位、制定补救措施、联合相关部门进行溯源和打击。一旦发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知</span><span>:</span><span>安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们同时将及时将事件相关情况以邮件、信函、电话、推送通知方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>7</span><span>、互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。如果您对我们的个人信息保护有任何疑问，可通过本隐私政策中约定的联系方式联系我们。如您发现自己的个人信息泄密，尤其是您的账户及密码发生泄露，请您立即通过本隐私政策</span><span>{</span><span>八、如何联系我们</span><span>]</span><span>中约定的联系方式联络我们，以便我们采取相应措施。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>二</span><span>)</span><span>您个人信息的保存</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、您的个人信息将存储于中华人民共和国境内。如您使用跨境交易服务，且需要向境外传输您的个人信息完成交易的，我们会单独征得您的授权同意并要求接收方按照我们的说明、本隐私政策以及其他任何相关的保密和安全措施来外理这些个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、在您使用我们的产品与</span><span>/</span><span>或服务期间，您的个人信息将在为了实现本政策所述目的之期限内保存，同时将结合法律有强制的留存要求期限的规定确定，如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。在超出保存期间后，我们会根据适用法律的要求删除您的个人信息，或进行匿名化处理。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、如果我们终止服务或运营，我们会至少提前三十日向您通知，并在终止服务或运营后对您的个人信息进行删除或匿名化处理。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>五、我们如何管理您的个人信息</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>美都汇非常重视您对个人信息的关注，并尽全力保护您对于您个人信息访问、更正、删除以及撤回同意的权利，以使您拥有充分的能力保障您的隐私和安全。您的权利包括</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>访问和更正您的个人信息</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>除法律法规规定外，您有权随时访问和更正您的个人信息，具体包括</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>您的账户信息</span><span>:</span><span>移动端具体路径为</span><span>:</span><span>账户名称、个人资料信息</span><span>:</span><span>首页</span><span>--</span><span>“我的”进入我的美都汇</span><span>--</span><span>右上角“设置”进入账号设置一点击头像进入个人信息页账户密码、电话号码、安全信息</span><span>:</span><span>首页</span><span>--</span><span>“我的进入我的美都汇</span><span>--</span><span>右上角“设置按钮”</span><span>--</span><span>账户与安全兴趣爱好</span><span>:</span><span>首页</span><span>--</span><span>“我的”进入我的美都汇</span><span>--</span><span>商品关注、店铺关注、喜欢的内容</span><span>(</span><span>具体名称以网页内容为准</span><span>)</span><span>。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>您的收货信息</span><span>:</span><span>移动端具体路径为</span><span>:</span><span>首页</span><span>--</span><span>“我的”进入我的美都汇</span><span>--</span><span>右上角“设置按钮”进入账号设置</span><span>--</span><span>地址管理。您也可以将最常用的收货地址设置为默认地址，如此，若您下次购买商品时未更改收货地址
                    您的商品会配送到该默认地址址</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>您的浏览信息</span><span>:</span><span>移动端路径为</span><span>:</span><span>搜索历史记录</span><span>:</span><span>首页</span><span>--</span><span>“我的”进入我的美都汇</span><span>--</span><span>浏览记录</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>您的评论信息</span><span>:</span><span>移动端路径为</span><span>:</span><span>我的美都汇</span><span>--</span><span>待评价</span><span>--</span><span>评价中心。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(5)</span><span>您的发票信息</span><span>:</span><span>移动端路径为首页</span><span>--</span><span>“我的”进入我的美都汇</span><span>--</span><span>右上角“设置”进入账号设置</span><span>--</span><span>增票资质。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(6)</span><span>对于您在使用我们的产品与</span><span>/</span><span>或服务过程中产生的其他个人信息需要访问或更正，请随时联系我们。我们会根据本隐私政策所列明的方式和期限响应您的请求。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(7)</span><span>您无法访问和更正的个人信息</span><span>:</span><span>除上述列明的信息外，您的部分个人信息我们无法为您提供访问和更正的服务，这些信息主要是为了提升您的用户体验和保证交易安全所收集的您的设备信息、您使用扩展功能时产生的个人信息。上述信息我们会在您的授权范围内进行使用，您无法访问和更正，但您可联系我们进行删除或做匿名化处理</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、删除您的个人信息</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您在我们的产品与</span><span>/</span><span>或服务页面中可以直接清除或删除的信息，包括浏览信息、收货地址信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>在以下情形中，您可以向我们提出删除个人信息的请求</span><span>:</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>如果我们处理个人信息的行为违反法律法规</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>如果我们收集、使用您的个人信息，却未征得您的同意</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>如果我们处理个人信息的行为违反了与您的约定</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>如果您不再使用我们的产品或服务，或您注销了美都汇账号</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(5)</span><span>如果我们终止服务及运营或不再为您提供产品或服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。若我们响应您的删除请求，我们不能保证立即从备份系统中删除相应的信息，但会在备份系统更新时删除这些信息。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、改变您授权同意的范围或撤回您的授权</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>您可以通过删除信息、关闭设备功能、在美都汇网站或软件中进行隐私设置等方式改变您授权我们继续收集个人信息的范围或撤回您的授权。您也可以通过注销账户的方式，撤回我们继续收集您个人信息的全部授权。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>请您理解，每个业务功能需要一些基本的个人信息才能得以完成，当您撤回同意或授权后，我们无法继续为您提供撤回同意或授权所对应的服务，也不再处理您相应的个人信息。但您撤回同意或授权的决定，不会影响此前基于您的授权而开展的个人信息处理。注销账号您可以通过移动端</span><span>APP</span><span>访问“我的</span><span>--</span><span>注销</span><span>/</span><span>删除</span><span>-</span><span>联系客服</span><span>-</span><span>注销美都汇</span><span>APP</span><span>商城服务”完成账号注销流程</span><span>;</span><span>您注销账号后，我们将停止为您提供产品与</span><span>/</span><span>或服务，并根据适用法律的要求删除您的个人信息，或进行匿名化处理。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、获取您的个人信息副本</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>您有权获取您的个人信息副本。如您需要获取我们收集的您的个人信息副本，您随时联系我们。在符合相关法律规定且技术可行的前提下，我们将根据您的要求向您提供您的个人信息副本。</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>6</span><span>、如果您不想接受我们给您发送的促销信息，您随时可通过以下方式取消</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>您可以根据促销短信内容提示，来取消我们给您发送的手机促销短信。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>您可以通过移动端</span><span>APP</span><span>“消息中心</span><span>-</span><span>消息设置设置是否接受我们通过“通知”推动给您的商品和促销信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>为了保护您的隐私，我们不会以任何方式和途径向您推送涉及宗教信仰、性取向等相关敏感内容的促销或商品信息给您。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>7</span><span>、响应您的请求</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>如果您无法通过上述方式访问、更正或删除您的个人信息，或您需要访问、更正或删除您在使用我们产品与</span><span>/</span><span>或服务时所产生的其他个人信息或者获取个人信息副本，或您认为美都汇存在任何违反法律法规或与您关于个人信息的收集或使用的约定，您均可以联系客服或通过本隐私政策中的其他方式与我们联系。为了保障安全，我们可能需要您提供书面请求，或提供您的身份证明文件，我们将在收到您反馈并验证您的身份后的</span><span>15</span><span>天内答复您的请求。对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段</span><span>(</span><span>例如，需要开发新系统或从根本上改变现行惯例</span><span>)</span><span>、给他人合法权益带来风险或者非常不切实际
                </span><span>(</span><span>例如，涉及备份磁带上存放的信息</span><span>)</span><span>的请求，我们可能会予以拒绝。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>在以下情形中，按照法律法规要求，我们将无法响应您的请求</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>与个人信息控制者履行法律法规规定的义务相关的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2) </span><span>与国家安全、国防安全有关的</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3) </span><span>与公共安全、公共卫生、重大公共利益有关的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>与刑事侦查、起诉和审判和执行判决等有关的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(5)</span><span>有充分证据表明您存在主观恶意或滥用权利的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(6)</span><span>出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(7) </span><span>响应您的请求将导致您或其他个人、组织的合法权益受到严重损害的</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(8) </span><span>涉及商业秘密的。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>六、未成年人的个人信息保护</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、美都汇非常重视对未成年人个人信息的保护。若您是</span><span>18</span><span>周岁以下的未成年人，在使用我们的产品与</span><span>/</span><span>或服务前，应事先取得您监护人的同意。美都汇根据国家相关法律法规的规定保护未成年人的个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、我们不会主动直接向未成年人收集其个人信息对于经监护人同意而收集未成年人个人信息的情况我们只会在受到法律允许、监护人同意或者保护未成年人所必要的情况下使用、共享、转让或披露此信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、如果有事实证明未成年人在未取得监护人同意的情况下注册使用了我们的产品与</span><span>/</span><span>或服务，我们会与相关监护人协商，并设法尽快删除相关个人信息。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>七、通知和修订</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、为给您提供更好的服务以及随着美都汇业务的发展，本隐私政策也会随之更新。但未经您明确同意，我们不会削减您依据本隐私政策所应享有的权利。我们会通过在美都汇网站、美都汇移动端上发出更新版本并在生效前通过网站公告或以其他适当方式提醒您相关内容的更新，也请您访问美都汇以便及时了解最新的隐私政策。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、对于重大变更，我们还会提供更为显著的通知</span><span>(</span><span>我们会通过包括但不限于邮件、短信或在浏览页面做特别提示等方式，说明隐私政策的具体变更内容</span><span>)</span><span>。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>本隐私政策所指的重大变更包括但不限于</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3) </span><span>个人信息共享、转让或公开披露的主要对象发生变化</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>您参与个人信息处理方面的权利及其行使方式发生重大变化</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(5)</span><span>我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>6)</span><span>个人信息安全影响评估报告表明存在高风险时。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们的服务，即代表您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。我们鼓励您在每次使用我们服务时都查阅本政策。您可以在美都汇</span><span>APP</span><span>通过“我的</span><span>-</span><span>账户设置</span><span>-</span><span>关于美都汇</span><span>APP-</span><span>隐私政</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>策</span><span>”中查看本政策。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>八、极光推送 SDK</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、第三方主体：深圳市和讯华谷信息技术有限公司。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、SDK 用途：为 APP 用户提供信息推送服务。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>3</span><span>、收集个人信息类型：</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>设备参数及系统信息(设备类型、设备型号、系统版本、及相关硬件信息)：用于识别用户的设备类型、设备型号、系统版本等，确保消息准确下发</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>设备标识符（IMEI、IDFA、Android ID、GID、
                    MAC、OAID、VAID、AAID、IMSI、MEID、UAID、SN、ICCID、SIM信息）：用于识别唯一用户，保证推送的精准送达及推送信息的准确统计</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>网络信息(IP 地址、WiFi
                    信息、基站信息、DNS地址、DHCP地址、SSID、BSSID)与位置信息（经纬度）：用于优化SDK与极光服务器的网络连接请求，保证服务的稳定性和连续性，同时实现区域推送功能；</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>应用列表信息(应用崩溃信息、通知开关状态、APP 应用列表及活跃状态、APP 应用页面信息、APP 功能事件相关信息)：当一个设备有多个 APP
                    的推送链路同时活跃时，我们采用合并链路技术，随机合并成一条链路，以达到为用户节省电省流量的目的。</span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>4</span><span>、数据处理方式：通过去标识化、加密传输及其他安全方式：</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>官网链接：https://www.jiguang.cn/push</b>
        </p>
        <p class="MsoNormal">
            <b>隐私政策链接：https://www.jiguang.cn/license/privacy</b>
        </p>
        <p class="MsoNormal">
            <b><span>5</span><span>、第三方 SDK 隐私政策：</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>若通过极光推送 SDK 同时集成了厂商通道，请在《隐私政策》中增加对于厂商通道的隐私政策说明，内容如下：</b>
        </p>
        <p class="MsoNormal">
            <b><span>(1)</span><span>小米推送 SDK
                    涉及的个人信息类型：设备标识符（如 Android ID、OAID、GAID）、设备信息
                    使用目的：推送消息
                    使用场景：在小米手机终端推送消息时使用
                    第三方主体：北京小米移动软件有限公司
                    数据处理方式：通过去标识化、加密传输及其他安全方式
                    <p> 官网链接：https://dev.mi.com/console/appservice/push.html</p>
                    <p> 隐私政策：https://dev.mi.com/console/doc/detail?pId=1822</p>
                </span><span></span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(2)</span><span>华为 HMS SDK
                    涉及的个人信息类型：应用基本信息、应用内设备标识符、设备的硬件信息、系统基本信息和系统设置信息
                    使用目的：推送消息
                    使用场景：在华为手机终端推送消息时使用
                    第三方主体：华为软件技术有限公司
                    数据处理方式：通过去标识化、加密传输及其他安全方式
                    <p> 官网链接：https://developer.huawei.com/consumer/cn/</p>
                    <p> 隐私政策：https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177
                    </p>
                </span><span></span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(3)</span><span>荣耀推送 SDK
                    涉及的个人信息类型：应用匿名标识 (AAID)，应用 Token
                    使用目的：推送消息
                    使用场景：在荣耀手机终端推送消息时使用
                    第三方主体：荣耀终端有限公司
                    数据处理方式：通过去标识化、加密传输及其他安全方式
                    <p> 官网链接：https://developer.hihonor.com/cn/promoteService</p>
                    <p> 隐私政策：https://www.hihonor.com/cn/privacy/privacy-policy/</p>
                </span><span></span></b><b></b>
        </p>

        <p class="MsoNormal">
            <b><span>(4)</span><span>OPPO 推送 SDK
                    涉及的个人信息类型：设备标识符（如 IMEI、ICCID、IMSI、Android ID、GAID）、应用信息（如应用包名、版本号和运行状态）、网络信息（如 IP 或域名连接结果，当前网络类型）
                    使用目的：推送消息
                    使用场景：在 OPPO 手机终端推送消息时使用
                    第三方主体：广东欢太科技有限公司
                    数据处理方式：通过加密传输和处理的安全处理方式
                    <p> 官网链接：https://open.oppomobile.com/new/introduction?page_name=oppopush</p>
                    <p> 隐私政策：https://open.oppomobile.com/wiki/doc#id=10288</p>
                </span><span></span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(4)</span><span>vivo 推送 SDK
                    涉及的个人信息类型：设备信息
                    使用目的：推送消息
                    使用场景：在 vivo 手机终端推送消息时使用
                    第三方主体：广东天宸网络科技有限公司及将来受让运营 vivo 开放平台的公司
                    数据处理方式：通过去标识化、加密传输及其他安全方式
                    <p>官网链接：https://dev.vivo.com.cn/promote/pushNews</p>
                    <p>隐私政策：https://www.vivo.com.cn/about-vivo/privacy-policy</p>
                </span><span></span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>(6)</span><span>魅族推送 SDK
                    涉及的个人信息类型：设备标识信息、位置信息、网络状态信息、运营商信息
                    使用目的：推送消息
                    使用场景：在魅族手机终端推送消息时使用
                    第三方主体：珠海市魅族通讯设备有限公司
                    数据处理方式：通过去标识化、加密传输及其他安全方式
                    官
                </span><span>;</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b>九、如何联系我们</b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>1</span><span>、如您对本隐私政策或您个人信息的相关事宜或者投诉、举报、有任何问题、意见或建议，请通过以下方式与我们联系。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>您还可以随时通过访问电话</span><span>18064626163</span><span>联系我们，或者通过美都汇</span><span>APP</span><span>官方客服联系我们。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>2</span><span>、一般情况下，我们将在</span><span>15</span><span>天内回复。如果您对我们的回复不满意，特别是我们的个人信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门需求帮助及解决方案。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>附</span><span>:</span><span>个人信息及敏感个人信息示例
                </span><span>(</span><span>非美都汇</span><span>APP</span><span>或网站所收集的个人信息，仅作示例说明</span><span>)</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>个人信息是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息，包括生物识别、宗教信仰、特定身份、医疗健康、金融账户、行踪轨迹等信息，以及不满十四周岁未成年人的个人信息。</span><span>(</span><span>本定义出自于《中华人民共和国个人信息保护法》</span><span>)</span><span>。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>注</span><span>:</span><span>本隐私政策版本更新日期为</span><span>2023</span><span>年</span><span>[05]</span><span>月</span><span>[27]</span><span>日，将于</span><span>2023</span><span>年</span><span>[05]</span><span>月</span><span>[27]</span><span>日正式生效。</span></b><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>涉互联网算法专项举报电话</span><span>: 18064626163:</span><span>专项举报邮箱</span><span>: </span></b><a
                href="mailto:mdh_appshangjia@126.com"><b><u><span>mdh_appshangjia@126.com</span></u></b></a><b></b>
        </p>
        <p class="MsoNormal">
            <b><span>开发运营主体：济南美都汇电子商务有限公司</span></b><b></b>
        </p>
        <p class="MsoNormal">
            &nbsp;
        </p>
        <!-- 底部信息部分 -->
        <div class="bottomInfo">
            <ul class="bottomInfo_single">
                <img src="../../assets/image/homePage/sign_text.png" alt="">
            </ul>
            <ul class="bottomInfo_text1">
                <li><a href="https://jw.modewe.com/shop.apk">下载美都汇</a></li>
                <li><router-link to="/companyInfo">公司简介</router-link></li>
                <li><router-link to="/privacypolicy">隐私协议</router-link></li>
                <li>联系我们</li>
            </ul>
            <ul class="bottomInfo_text2">
                <li>www.jw.modewe.com 2021-2023 All Rights Reserved. </li>
                <li>营业执照：91370105MA7G4ED826</li>
                <li>
                    <img src="../../assets/image/homePage/emblem.png" alt="">
                    <p style="white-space: nowrap;"> © 2023-2024 modewe.com 版权所有</p>&nbsp;
                    <a class="bottomInfo_text2a" href="https://beian.miit.gov.cn/#/Integrated/index">
                        鲁ICP备2022009892号-2
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
    
<script>
export default {
    data() {
        return {
            aboutState: false,
            aboutUasUnfold: "",
            active: 1,
            list: ["公司简介", "隐私协议"]
        }
    },

    methods: {
        changeState() {
            this.aboutState == false ? this.aboutState = true : this.aboutState = false
            this.aboutState == false ? this.aboutUasUnfold = "height:100%" : this.aboutUasUnfold = "height:240%"
        },
        ababab(a, b) {
            if (a == 1 && b == 0) {//首页
                if (this.isindex != true) {
                    this.$router.push("/");
                }
            }
        },
        /*切换需要高亮的按钮*/
        change(index) {
            if (index == 0) {
                this.$router.push("/companyInfo");
            }
        },
        test() {
            console.log(this.comName);
        }
    },
};
</script>
<style scoped>
.bodyContent {
    height: auto;
}

.bodyContent_title p {
    margin-top: 34px;
    white-space: nowrap;
}

.actived {
    border-bottom: 4px solid #FF0E3E;
}

.bodyContent_title {
    display: flex;
    height: 85px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
}

.bodyContent_title li:nth-child(1) {
    margin-left: 23.6%;
}

.bodyContent_title li:nth-child(2) {
    margin-left: 103px;
}

/* 内容部分 */
b {
    font-weight: normal;
}

.MsoNormal {
    font-family: PingFangSC-Regular, PingFang SC;
    margin: 0 auto;
    width: 1024px;
    color: #333333;
    line-height: 24px;
    font-size: 16px;
    font-weight: 800;
}

/* 总标题 */
.bodytitle {
    margin-top: 115px;
    font-size: 30px;
    font-weight: 400;
    line-height: 42px;
}

/* 一级标题 */
.title_1 {
    display: inline-block;
    margin-top: 30px;
    height: 25px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
}

/* 顶部距离 */
.title_top {
    display: inline-block;
    margin-top: 30px;
}

.title_topadd {
    margin-top: 62px;
}

.title_topclean {
    margin-top: 20px;
}

/* 字体加粗 */
.title_weight {
    font-weight: 700;
}

.tbody {
    width: 500px !important;
}

* {
    user-select: none;
}

a {
    color: #333333;
}

.header p {
    width: 100%;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}

.homePage p:hover {
    color: #FF0E3E;
}

.download:hover .download_sign,
.aboutUas:hover .aboutUas_sign {
    color: #FF0E3E;
}

.introduction:hover a,
.protocols:hover a {
    color: #FF0E3E;
}

.header {
    height: 100px;
}

.header_content {
    height: 50%;
    width: 500px;
    margin: 0 auto;
    position: relative;
    top: 24px;
    display: flex;
    white-space: nowrap;
}



.pageSign,
.homePage,
.download {
    width: 25%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.pageSign img:nth-child(1) {
    position: absolute;
    top: 0;
    left: -66px;
    transform: scale(0.5);
}

.aboutUas {
    width: 25%;
    height: 100%;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s;
    background-color: white;
}

.pageSign img {
    height: 100%;
}

.download_sign {
    font-size: 18px;
    transform: rotate(180deg);
}



.aboutUas p {
    margin-top: 14px;
}

.aboutUas_sign {
    margin-top: 15px;
    position: absolute;
    top: 0;
    left: 82%;
}

.aboutUas_sign,
.download_sign {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* body样式 */
.body_content,
.shop_content,
.body_download {
    height: 1080px;

    overflow: hidden;
}

.body_content img {
    position: relative;
    width: 100%;
    height: 100%;
}

/* 商品页 */
.text_1 {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 188px;
}

.text_2 {
    text-align: center;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 33px;
    margin-top: 22px;
}

.shop_Introduction {
    margin-top: 100px;

    display: flex;
    justify-content: center;
}

.shop_Introduction img {
    width: 230px;
    height: 498px;
}

.shop_Introduction img:nth-child(2) {
    margin: 0 10px 0 10px;
}

.shop_Introduction img:nth-child(3) {
    margin-right: 10px;
}

.body_downloadcont {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 200px;
}

.body_downloadoperate {

    margin-top: 200px;
    display: flex;
    justify-content: center;
}

.body_downloadoperate li:nth-child(2) {
    margin: 0 10px 0 10px;
}

.downClassify_ad,
.downClassify_ios,
.downClassify {
    width: 290px;
    height: 290px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    position: relative;
}

.downClassify_ad:hover,
.downClassify_ios:hover {
    background-color: #FF0E3E;
}

.downClassify_ad:hover p,
.downClassify_ios:hover p {
    color: white;
}

.downClassify_ad:hover .active,
.downClassify_ios:hover .active {
    display: block;
}

.downClassify_ios img {
    width: 60px;
    height: 74px;
}

.downClassify_ad img {
    width: 60px;
    height: 72px;
}

.body_downloadoperate p {
    width: 52px;
    height: 25px;
    font-size: 18px;
    font-family: STKaitiSC-Regular, STKaitiSC;
    font-weight: 400;
    color: #777777;
    line-height: 25px;
    margin-top: 20px;
}


.downClassify_ad p {
    margin-right: 14px;
}

.active {
    position: absolute;
    top: 86px;
    display: none;
}

.bottomInfo {
    padding-top: 100px;
    height: 400px;
    background: rgba(239, 239, 239, 0.77);
}

/* 底部信息 */
.bottomInfo_single {
    width: 220px;
    height: 100px;
    margin: 0 auto;
}

.bottomInfo_single img {
    width: 100%;
    height: 100%;
}

.bottomInfo_text1 {
    margin: 0 auto;

    width: 26%;
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
}

.bottomInfo_text1 li,
.bottomInfo_text1 a {

    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 22px;

}

.bottomInfo_text1 li {
    width: 80px;
    white-space: nowrap;
}

.bottomInfo_text2 {
    margin: 0 auto;
    margin-top: 68px;
    width: 357px;
    height: 72px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bottomInfo_text2 img {
    width: 15px;
    height: 14px;
}

.bottomInfo_text2 li:nth-child(3) {
    display: flex;
    align-items: center;

}

.bottomInfo_text2a {
    white-space: nowrap;
    color: #777777;
}

.bottomInfo_text2a:hover {
    color: #FF0E3E;
}
</style>


    