<template>
    <div class="bodycontent">
        <div class="active_bgc" v-if="activeloading == true">
            <li class="activer_content"></li>
        </div>
        <div class="coupon_bgcbox" v-if="couponloading == true">
            <ul class="coupon_bgc">
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
            </ul>
            <ul class="coupon_bgc">
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
            </ul>
            <ul class="coupon_bgc">
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
                <li class="coupon_content"></li>
            </ul>
        </div>
        <div class="rotate_bgcbox" v-if="rotateloading == true">
            <ul class="rotate_bgc"></ul>
            <ul class="rotate_bgc"></ul>
            <ul class="rotate_bgc"></ul>
            <ul class="rotate_bgc"></ul>
            <ul class="rotate_bgc"></ul>
            <ul class="rotate_bgc"></ul>
        </div>

    </div>
</template>
  
<script>
export default {
    props: ['activeloading', 'couponloading', 'rotateloading'],
    data() {
        return {

        }
    },
    created() {
    },
    methods: {

    },
}
</script>


  
<style scoped>
* {
    border-radius: 12px;

}

.bodycontent {
    padding: 0 12px 0 12px;

}

.active_bgc,
.coupon_bgcbox,
.rotate_bgcbox {
    position: fixed;
    top: 0;
    left: 0;
}

.coupon_bgcbox {
    top: 314px
}

.rotate_bgcbox {
    top: 380px;
}

.active_bgc {
    width: 100%;
    height: 314px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activer_content {
    width: 80%;
    height: 280px;
}

.coupon_bgcbox {
    width: 100%;
    height: 64px;
    margin-top: -12px;
    display: flex;
    justify-content: center;
}

.coupon_bgc {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.coupon_bgcbox .coupon_bgc:nth-child(2) {
    margin: 0 5px 0 5px;
}

.coupon_content {
    width: 85%;
    height: 15px;
    border-radius: 5px;
    margin-left: 4px;
}

.coupon_content:nth-child(n+2) {
    width: 50%;
}

.rotate_bgcbox {
    width: 100%;
    height: 392px;
    margin-top: 13px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.rotate_bgc {
    width: 30%;
    height: 159px;
}

.rotate_bgc:nth-child(2),
.rotate_bgc:nth-child(5) {
    margin: 0 5px 0 5px;
}

/* --------------------------禁止修改区域 */
/* 背景 */
.active_bgc,
.coupon_bgc,
.rotate_bgcbox {
    animation: loading 1.4s ease infinite;
    -webkit-animation: loading 1.4s ease infinite;
    background-image: (linear, left top, right top, color-stop(25%, #f0f0f0), color-stop(37%, #e3e3e3), color-stop(63%, #f0f0f0));
    background-image: -o-linear-gradient(left, #f0f0f0 25%, #e3e3e3 37%, #f0f0f0 63%);
    background-image: linear-gradient(90deg, #f0f0f0 25%, #e3e3e3 37%, #f0f0f0 63%);
    background-size: 400% 100%
}

/* 内容 */
.activer_content,
.coupon_content,
.rotate_bgc {
    animation: loading 1.4s ease infinite;
    -webkit-animation: loading 1.4s ease infinite;
    background-image: (linear, left top, right top, color-stop(25%, #f0f0f0), color-stop(37%, #ffffff), color-stop(63%, #f0f0f0));
    background-image: -o-linear-gradient(left, #f0f0f0 25%, #ffffff 37%, #f0f0f0 63%);
    background-image: linear-gradient(90deg, #f0f0f0 25%, #ffffff 37%, #f0f0f0 63%);
    background-size: 400% 100%
}

@-webkit-keyframes loading {
    0% {
        background-position: 100% 50%
    }
    to {
        background-position: 0 50%
    }
}

@keyframes loading {
    0% {
        background-position: 100% 50%
    }

    to {
        background-position: 0 50%
    }
}
</style>
  