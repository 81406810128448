<template>
  <div style="width: 100%;">
    <div class="img_item">
      <div v-for="item in col1" @click="toGood">
        <good_item :item="item" :state="state"  @good-click="toGoodInfo" ></good_item>
      </div>
    </div>
    <div class="img_item">
      <div v-for="item in col2" @click="toGood">
        <good_item :item="item" :state="state"  @good-click="toGoodInfo"></good_item>
      </div>
    </div>
  </div>
</template>
<script>
import good_item from "@/components/good_item.vue";
export default {
  props: ['list', 'state'],
  data() {
    return {
      col1: [],
      col2: [],
      id:''
    }
  },
  components: { good_item },
  mounted() {
  },
  watch: {
    list: {
      handler(newArray) {
        // 当A页面的array发生变化时，B组件的array也会相应地变化
        this.list = newArray;
        this.arrangeList()
      },
      immediate: true // 立即执行watch回调
    }


  },
  methods: {

    arrangeList() {
      // console.log(this.list);
      this.col1 = []
      this.col2 = []
      let i = 0
      let coll = []
      let colr = []
      if(this.list===undefined){
        return
      }
      this.list.forEach(item => {
        if (coll.length <= colr.length) {
          coll.push(item);
        } else {
          colr.push(item);
        }
        i++;
      })
      this.col1 = this.col1.concat(coll)
      this.col2 = this.col2.concat(colr)
    },
    toGoodInfo(id) {
      this.$emit('good-click', id)
      this.id=id
    },
    toGood(){
      this.$emit('good-click1', this.id)
    }
  }
};
</script>
<style>
.img_item {
  /* margin: 0 auto; */
  /* border: 1px solid #000; */
  width: 48%;
  /* border: 1px solid #000; */
  margin: 1%;
  display: inline-block;
  vertical-align: top;
}
</style>