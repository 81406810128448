<template>
    <van-popup v-model="showpopup" :style="{ background: 'none' }">
			<img class="popupbg" src="@/assets/image/popupbg.png" alt="">
			<div class="popupdiv">
				<div class="textpopup">
					<p>{{ textpopup }}</p>
					<p>您可以看看其他活动哦~</p>
				</div>
				<div class="butpopup" @click="goHome">去首页</div>
			</div>
		</van-popup>
</template>
<script>
export default {
    props: ['textpopup', 'showpopup'],
    data() {
        return {
        }
    },

    mounted() {
    },
    watch: {
		showpopup: {
            handler(showpopup) {
				console.log(showpopup);
                // 当A页面的array发生变化时，B组件的array也会相应地变化
                this.showpopup = showpopup;
                // console.log(JSON.parse(JSON.stringify(this.list)) );
            },
            immediate: true // 立即执行watch回调
        }
    },
    methods: {
        goHome() {
            this.$emit('home-click');
        }
    }
};
</script>
<style lang="scss" scoped>
.popupbg {
	width: 256px;
	height: 193px;
	position: relative;
}

.popupdiv {
	position: absolute;
	top: 73px;
	width: 100%;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
}

.textpopup {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-bottom: 14px;
}

.butpopup {
	width: 158px;
	height: 42px;
	background: #FF536A;
	box-shadow: inset 0px 2px 8px 0px rgba(255, 255, 255, 0.8);
	border-radius: 21px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>