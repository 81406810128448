<template>
    <div>
        <div class="module">
            <div class="goods" v-for="item in list" @click="jump(id, activiId, item.id)">
                <img class="goods_img" :src="item.thumbnail" alt="">
                <div class="goods_bom">
                    <img class="bom_img" v-if="hall==''" :src="globalData.staticUrl + '/images/childDay/limitcarth.png'" alt="">
                    <img class="bom_img" v-if="hall=='zongzi'" :src="globalData.staticUrl +'/images/zongziDay/zongzi_rob.png'" alt="">
                    <div class="goods_info">
                        <p>￥</p>{{ item.marketPrice }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { globalData } from "@/utils/config";
export default {
    props: ['list', 'id', 'activiId','hall'],
    data() {
        return {
            globalData: globalData,
        }
    },

    mounted() {
    },
    watch: {
        list: {
            handler(newArray) {
                // 当A页面的array发生变化时，B组件的array也会相应地变化
                this.list = newArray;
                // console.log(JSON.parse(JSON.stringify(this.list)) );
            },
            immediate: true // 立即执行watch回调
        }
    },
    methods: {
        jump(id, activiId, sortGoodsId) {
            this.$emit('jump-click', id, activiId, sortGoodsId);
        }
    }
};
</script>
<style lang="scss" scoped>
.module {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .goods {
        width: 32.5%;
        border-radius: 4px;
        margin-bottom: 4px;
        &_img {
            width: 100%;
            height: 129px;
            object-fit:cover;
            border-radius: 4px 4px 0px 0px;
            background-color: #ffffff;
        }

        &_bom {
            width: 100%;
            height: 34px;
            margin-top: -4%;
            position: relative;

            img {
                width: 102%;
                height: 100%;
                // border: 1px solid #000;
            }

            .goods_info {
                display: flex;
                position: absolute;
                top: 5%;
                left: 5%;
                z-index: 1;
                // border: 1px solid #000;
                height: 28px;
                font-size: 20px;
                display: flex;
                align-items: flex-end;
                color: #fff;
                p{
                   font-size: 14px; 
                   margin-bottom: 5%;
                }
            }
        }


    }
}</style>