import axios from "axios";
console.log(window.localStorage.getItem("Usertoken"));
const Server = axios.create({
  baseURL: "",
  timeout: 8000,
});
Server.interceptors.request.use(
  (config) => {
    config.headers["X-Access-Token"] = localStorage.getItem("Usertoken");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
Server.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default Server;
