<template>
  <div class="box">
    <div class="pages">
      <img class="pages_bgc" :src="pagebgc" />
      <div class="pages_content">
        <!-- 优惠券 -->
        <ul class="couponbox">
          <li class="coupon" v-for="(todo, index) in couponList" :key="todo.id">
            <img class="coupon_img" src="@/assets/image/fatherDay/coupon_fabg.png" />
            <div class="coupon_info">
              <div class="coupon_top">
                <ul class="coupon_topcarth">
                  <p :class="todo.useCount == 0 ? 'useend' : ''">￥</p>
                  <p :class="todo.useCount == 0 ? 'useend' : ''">{{ todo.discountPrice }}</p>
                </ul>
                <ul class="coupon_toplimit">
                  <li :class="todo.useCount == 0 ? 'useend' : ''">满{{ todo.fullPrice }}可用</li>
                  <li :class="todo.useCount == 0 ? 'useend' : ''">父亲节专属</li>
                </ul>
              </div>
              <div class="coupon_botom">
                <ul @click="clickCheckToUsePageHandle(todo, index)" v-if="parseInt(todo.couponLimit) != todo.useCount">
                  立即领取
                </ul>
                <ul @click="useCoupon(index)" v-else>立即使用</ul>
                <img :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" />
              </div>
            </div>

          </li>
        </ul>
        <!-- 父爱如衫 -->
        <ul class="boomshop">
          <img class="boomshop_bgc" :src="this.one.moduleImg" @click="jump(one.id, one.activiId)" />
          <div class="boomshopbox_out">
            <div class="boomshop_listbox" v-for="(todo, index) in one.listUnder" :key="todo.id"
              @click="jump(one.id, one.activiId, todo.id)">
              <li class="boomshop_list boomshop_under" :style="boxunder">
                <img class="list_img" :src="todo.thumbnail" alt="">
                <div class="boomshop_listcarth">
                  <ul class="boomshop_carthbox">
                    <p>￥</p>
                    <p>{{ todo.marketPrice }}</p>
                  </ul>
                </div>
              </li>
              <li class="boomshop_list boomshop_up" :style="boxup" @click="jump(one.id, one.activiId, todo.id)">
                <img class="list_img" :src="one.all[index].thumbnail" alt="">
                <div class="boomshop_listcarth">
                  <ul class="boomshop_carthbox">
                    <p>￥</p>
                    <p>{{ one.all[index].marketPrice }}</p>
                  </ul>
                </div>
              </li>
            </div>
            <div class="boomshop_listbox" v-for="(todo, index) in one.listUp" :key="todo.id"
              @click="jump(one.id, one.activiId, todo.id)">
              <li class="boomshop_list boomshop_under" :style="boxunderCopy">
                <img class="list_img" :src="todo.thumbnail" alt="">
                <div class="boomshop_listcarth">
                  <ul class="boomshop_carthbox">
                    <p>￥</p>
                    <p>{{ todo.marketPrice }}</p>
                  </ul>
                </div>
              </li>
              <li class="boomshop_list boomshop_up" :style="boxupCopy" @click="jump(one.id, one.activiId, todo.id)">
                <img class="list_img" :src="one.all[index + 7].thumbnail" alt="">
                <div class="boomshop_listcarth">
                  <ul class="boomshop_carthbox">
                    <p>￥</p>
                    <p>{{ one.all[index + 7].marketPrice }}</p>
                  </ul>
                </div>
              </li>
            </div>
          </div>
        </ul>

        <!-- 品质男装 -->
        <ul class="hotshop">
          <img class="hotshop_bgc" :src="this.two.moduleImg" @click="jump(two.id, two.activiId)" />
          <div class="hotshop_listbox">
            <li class="hotshop_list" v-for="todo in this.two.list" :key="todo.id"
              @click="jump(two.id, two.activiId, todo.id)">
              <img class="list_img" :src="todo.thumbnail" alt="">
              <div class="hotshop_listcarth">
                <img :src="globalData.staticUrl + '/images/childDay/limitcarth.png'" alt="">
                <ul class="hotshop_carthbox">
                  <p>￥</p>
                  <p>{{ todo.marketPrice }}</p>
                </ul>
              </div>
            </li>
          </div>
        </ul>
        <!-- 清凉一夏 -->
        <ul class="hotshop">
          <img class="hotshop_bgc" :src="this.three.moduleImg" @click="jump(three.id, three.activiId)" />
          <div class="hotshop_listbox">
            <li class="hotshop_list" v-for="todo in this.three.list" :key="todo.id"
              @click="jump(three.id, three.activiId, todo.id)">
              <img class="list_img" :src="todo.thumbnail" alt="">
              <div class="hotshop_listcarth">
                <img :src="globalData.staticUrl + '/images/childDay/limitcarth.png'" alt="">
                <ul class="hotshop_carthbox">
                  <p>￥</p>
                  <p>{{ todo.marketPrice }}</p>
                </ul>
              </div>
            </li>
          </div>
        </ul>
        <!-- 精选品类 会场 -->
        <ul class="boutiqueshop">
          <img class="boutiqueshop_bgc" :src="childLimit" alt="">
          <div class="boutiqueshop_contentbox">
            <li v-for="todo in boomshop" :key="todo.id" class="boutiqueshop_content">
              <img :src="todo.cardImg" alt="" @click="toJingangUrlPage(todo.wxUrl, todo.andUrl, todo.iosUrl)">
            </li>
          </div>
        </ul>
        <!-- 以爱之名 -->
        <ul class="dressstyle">
          <img class="dressstyle_bgc" :src="this.four.moduleImg" @click="jump(four.id, four.activiId)">
          <li class="dressstyle_top">
            <div class="dressstyle_topcont" v-for="todo in this.four.list  " :key="todo.id"
              @click="jump(four.id, four.activiId, todo.id)">
              <img class="dressstyle_topimg" :src="todo.thumbnail" alt="">
              <p class="dressstyle_toptext">{{ todo.prodName }}</p>
              <ul class="dressstyle_botbox">
                <img v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="">
                <img v-else class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/secondary_qianggou.png'" alt="">
                <p v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botupcar ">直降{{
                  todo.marketPriceOffline - todo.marketPrice
                }}元</p>
                <li v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botcar">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                  <p class="dressstyle_go">去抢购</p>
                  <img :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" />
                </li>
                <li v-else class="dressstyle_botcar dressstyle_botupcarano">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                </li>
              </ul>
              <img class="dressstyle_gosign" :src="globalData.staticUrl + '/images/MotherDay/good_text.png'" alt="">
              <p class="dressstyle_gotext">爆</p>
            </div>
          </li>
          <li class="dressstyle_btn" @click="anotherPage(1)">
            <img class="dressstyle_btnimg" src="../../assets/image/fatherDay/fastyle_but.png" />
            <p>换一批</p>
          </li>
        </ul>
        <!-- 元气清爽 -->
        <ul class="dressstyle">
          <img class="dressstyle_bgc" :src="this.five.moduleImg" @click="jump(five.id, five.activiId)">
          <li class="dressstyle_top">
            <div class="dressstyle_topcont" v-for="todo in this.five.list  " :key="todo.id"
              @click="jump(five.id, five.activiId, todo.id)">
              <img class="dressstyle_topimg" :src="todo.thumbnail" alt="">
              <p class="dressstyle_toptext">{{ todo.prodName }}</p>
              <ul class="dressstyle_botbox">
                <img v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="">
                <img v-else class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/secondary_qianggou.png'" alt="">
                <p v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botupcar ">直降{{
                  todo.marketPriceOffline - todo.marketPrice
                }}元</p>
                <li v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botcar">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                  <p class="dressstyle_go">去抢购</p>
                  <img :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" />
                </li>
                <li v-else class="dressstyle_botcar dressstyle_botupcarano">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                </li>
              </ul>
              <img class="dressstyle_gosign" :src="globalData.staticUrl + '/images/MotherDay/good_text.png'" alt="">
              <p class="dressstyle_gotext">爆</p>
            </div>
          </li>
          <li class="dressstyle_btn" @click="anotherPage(2)">
            <img class="dressstyle_btnimg" src="../../assets/image/fatherDay/fastyle_but.png" />
            <p>换一批</p>

          </li>
        </ul>
        <!-- 合脚合意 -->
        <ul class="dressstyle">
          <img class="dressstyle_bgc" :src="this.six.moduleImg" @click="jump(six.id, six.activiId)">
          <li class="dressstyle_top">
            <div class="dressstyle_topcont" v-for="todo in this.six.list  " :key="todo.id"
              @click="jump(six.id, six.activiId, todo.id)">
              <img class="dressstyle_topimg" :src="todo.thumbnail" alt="">
              <p class="dressstyle_toptext">{{ todo.prodName }}</p>
              <ul class="dressstyle_botbox">
                <img v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="">
                <img v-else class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/secondary_qianggou.png'" alt="">
                <p v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botupcar ">直降{{
                  todo.marketPriceOffline - todo.marketPrice
                }}元</p>
                <li v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botcar">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                  <p class="dressstyle_go">去抢购</p>
                  <img :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" />
                </li>
                <li v-else class="dressstyle_botcar dressstyle_botupcarano">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                </li>
              </ul>
              <img class="dressstyle_gosign" :src="globalData.staticUrl + '/images/MotherDay/good_text.png'" alt="">
              <p class="dressstyle_gotext">爆</p>
            </div>
          </li>
          <li class="dressstyle_btn" @click="anotherPage(3)">
            <img class="dressstyle_btnimg" src="../../assets/image/fatherDay/fastyle_but.png" />
            <p>换一批</p>
          </li>
        </ul>
        <!-- 张弛有度 -->
        <ul class="dressstyle">
          <img class="dressstyle_bgc" :src="this.seven.moduleImg" @click="jump(seven.id, seven.activiId)">
          <li class="dressstyle_top">
            <div class="dressstyle_topcont" v-for="todo in this.seven.list  " :key="todo.id"
              @click="jump(seven.id, seven.activiId, todo.id)">
              <img class="dressstyle_topimg" :src="todo.thumbnail" alt="">
              <p class="dressstyle_toptext">{{ todo.prodName }}</p>
              <ul class="dressstyle_botbox">
                <img v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="">
                <img v-else class="dressstyle_botimg"
                  :src="globalData.staticUrl + '/images/MotherDay/secondary_qianggou.png'" alt="">
                <p v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botupcar ">直降{{
                  todo.marketPriceOffline - todo.marketPrice
                }}元</p>
                <li v-if="todo.marketPriceOffline - todo.marketPrice > 0" class="dressstyle_botcar">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                  <p class="dressstyle_go">去抢购</p>
                  <img :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" />
                </li>
                <li v-else class="dressstyle_botcar dressstyle_botupcarano">
                  <p class="dressstyle_sign">￥</p>
                  <p class="dressstyle_carth">{{ todo.marketPrice }}</p>
                </li>
              </ul>
              <img class="dressstyle_gosign" :src="globalData.staticUrl + '/images/MotherDay/good_text.png'" alt="">
              <p class="dressstyle_gotext">爆</p>
            </div>
          </li>
          <li class="dressstyle_btn" @click="anotherPage(4)">
            <img class="dressstyle_btnimg" src="../../assets/image/fatherDay/fastyle_but.png" />
            <p>换一批</p>
          </li>
        </ul>
        <!-- 更多商品 -->
        <ul class="moregoods">
          <li class="more_listtitle">
            <img class="more_titleimg" :src="moreSign" alt="">
          </li>
          <li class="more_goodslist">
            <div class="more_goodstitle" v-for="(todo, index) in  eight.list " :key="todo.id" @click="change(index)"
              ref="more_goodstitle">
              <p class="more_goodstitletext" :class="{ more_goodstitletext_active: active == index }">
                {{ todo.groupName }}</p>
              <img class="more_goodstitleimg" v-if="active == index" alt=""
                :src="globalData.staticUrl + '/images/childDay/moreselect_active.png'">
              <img class="more_goodstitleimg" v-else alt=""
                :src="globalData.staticUrl + '/images/childDay/moreselect.png'">
            </div>
          </li>
          <li class="more_goods">
            <div class="more_goodsl">
              <good_list :list="morelist" :state="'main'" @good-click1="toGoodInfo"></good_list>
            </div>
          </li>
          <li v-if="pageNo <= eight.list[this.active].prodClsList.pages" class="morelist_text" @click="getMoreList">
            加载更多
          </li>
          <li v-else class="morelist_img">
            <img :src="globalData.staticUrl + '/images/nomore_bgc.png'" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div class="skeletonbox" v-if="activefa != false && couponfa != false && rotatefa != false">
      <Skt :activeloading="activefa" :couponloading="couponfa" :rotateloading="rotatefa"></Skt>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import { globalData } from "@/utils/config";
import { fatherList, fatherMoreList, fatherCoupon, receiveMemberCouponV3 } from '@/utils/api/fatherDayapi'
import { findCouponByActiv, receiveMemberCouponV4 } from '@/utils/api/api'
import good_list from "@/components/good_list.vue";
import Skt from "@/components/skeleton_screen/fatherDay/fatherDay.vue"
export default {
  data() {
    return {
      loadingState: [false, false, false, false],
      boxunder: "",
      boxunderCopy: "",
      boxup: "",
      boxupCopy: "",
      active: 0,
      loopcontrol: 0,
      loopcontrol_reach: 0,
      // 图片
      pagebgc: "",
      moreSign: "",
      childLimit: "",
      hotRecommed: "",
      middleClose: "",
      bigClose: "",
      smallClose: "",
      // 状态
      popup: "opacity:0",
      activefa: true,
      couponfa: true,
      rotatefa: true,
      genderstate: true,
      pageNo: 2,
      anopageNo: 1,
      anopageNofive: 1,
      anopageNosix: 1,
      anopageNoseven: 1,
      // 数据
      couponRquest: {},
      activId: "",
      globalData: globalData,
      routerParams: "",
      comActivitiCards: "",
      couponList: [],
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      six: {},
      seven: {},
      eight: { list: [{ prodClsList: { pages: 4 } }] },
      morelist: [],
      boomshop: "",
      popuptext: "",

    };
  },
  components: { good_list, Skt },
  created() {

    let obj = {}
    let reg = /([^?=&]+)=([^?=&]+)/g
    let data = window.location.href.replace("#/", "");
    data.replace(reg, (...arg) => {
      obj[arg[1]] = arg[2]
    })
    console.log(window.location.href, data, obj);
    if (obj.hid) {
      localStorage.setItem("Userhid", obj.hid);
    } else {
      localStorage.setItem("Userhid", "")
    }
    if (obj.token) {
      localStorage.setItem("Usertoken", obj.token);
    } else {
      localStorage.setItem("Usertoken", "")
    }
    this.getBodyAllList()
    window.getUserInfoTokenIos = this.getUserInfoTokenIos
    setInterval(() => {
      this.rotate()
      setTimeout(() => {
        this.rotateback()
      }, 4000)
    }, 9000)
  },
  mounted() {
    window.addEventListener('scroll', this.onpageScroll);
  },
  methods: {
    // 更多商品标题选中
    change(index) {
      this.active = index
      this.morelist = this.eight.list[index].prodClsList.records
      this.loopcontrol += 1
      this.pageNo = 1
    },
    // 全局数据
    getBodyAllList() {
      fatherList({ activiNum: "111" }).then((res) => {
        if (res.code == 200) {
          console.log(res.result);
          this.pagebgc = res.result.activImage//主图
          this.activefa = false
          this.moreSign = res.result.modules[6].moduleImg//更多
          this.childLimit = res.result.modules[3].moduleImg//会场
          this.boomshop = res.result.comActivitiCards //会场数组
          this.activId = res.result.id
          console.log(111, JSON.parse(JSON.stringify(this.boomshop)));
          if (res.result.modules.length > 0) {
            //父爱如杉
            let one = res.result.modules
            this.one = one[0],
              this.one.all = res.result.modules[0].prodClsList.records,
              this.one.comActivitiCards = res.result.comActivitiCards
            let param = {
              moduleId: this.one.id,
              pageNo: 1,
              pageSize: 10,
            };
            fatherMoreList(param).then((res) => {
              console.log(res);
              if (res.code == 200) {
                for (let i = 0; i < res.result.records.length; i++) {
                  this.one.all.push(res.result.records[i])
                }
                this.one.list = this.one.all.splice(0, 6)
                this.one.listUnder = this.one.list.splice(0, 3)
                this.one.listUp = this.one.list.splice(0, 3)
                this.eight.list[0].prodClsList.records = res.result.records
                this.morelist = res.result.records
                let end = this.one.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
                console.log(end[0]);
                this.one.moduleImg = end[0];
                this.rotatefa = false
              }
            });

          }
        }

        console.log(res);
        if (res.code == 200) {
          if (res.result.modules.length > 1) {
            // 品质男装
            let two = res.result.modules
            this.two = two[1]
            this.two.list = two[1].prodClsList.records.splice(0, 6)
            let end = this.two.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            console.log(end[0]);
            this.two.moduleImg = end[0];
          }
        }

        if (res.code == 200) {
          if (res.result.modules.length > 2) {
            // 清凉一夏
            let three = res.result.modules
            this.three = three[2]
            this.three.list = three[2].prodClsList.records.splice(0, 6)
            let end = this.three.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            console.log(3, this.three.moduleImg);
            this.three.moduleImg = end[0];
          }
        }
        fatherList({ activiNum: "111" }).then((res) => {
          if (res.code == 200) {
            if (res.result.modules.length > 3) {
              // 以爱之名
              let four = res.result.modules
              this.four = four[4]
              this.four.list = res.result.modules[4].prodClsList.records.slice(0, 6)
              let end = this.four.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
              this.four.moduleImg = end[0];
              console.log(4, this.four.moduleImg);
            }
          }
          fatherList({ activiNum: "111" }).then((res) => {
            if (res.code == 200) {
              if (res.result.modules.length > 4) {
                // 元气清爽
                let five = res.result.modules
                this.five = five[5]
                this.five.list = res.result.modules[5].prodClsList.records.slice(0, 6)
                let end = this.five.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
                this.five.moduleImg = end[0];
                console.log(5, this.five.moduleImg);
              }
            }

            if (res.code == 200) {
              if (res.result.modules.length > 5) {
                // 合脚合意
                let six = res.result.modules
                this.six = six[7]
                this.six.list = res.result.modules[7].prodClsList.records.slice(0, 6)
                let end = this.six.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
                this.six.moduleImg = end[0];
                console.log(7, this.six.moduleImg);
              }
            }
            fatherList({ activiNum: "111" }).then((res) => {
              if (res.code == 200) {
                if (res.result.modules.length > 6) {
                  // 张弛有度
                  let seven = res.result.modules
                  this.seven = seven[8]
                  this.seven.list = res.result.modules[8].prodClsList.records.slice(0, 6)
                  let end = this.seven.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
                  this.seven.moduleImg = end[0];
                  console.log(8, this.seven.moduleImg);
                }
              }

              if (res.code == 200) {
                if (res.result.modules.length > 7) {
                  // 更多会场
                  let eight = res.result.modules
                  this.eight.list = eight[8].groups
                  // 为除推荐外其他分类获取第一页数据
                  let param = {
                    activId: "1662003169351176193",
                    moduleId: "1663068601709498370",
                    pageNo: 1,
                    pageSize: 10,
                  };
                  for (let i = 0; i < this.eight.list.length; i++) {
                    this.eight.list[i].prodClsList.records = []
                  }
                  for (let i = 0; i < this.eight.list.length; i++) {
                    param.groupId = this.eight.list[i].id
                    fatherMoreList(param).then((res) => {
                      if (res.code == 200) {
                        this.eight.list[i].prodClsList.records = [].concat(res.result.records)
                      }
                    });
                  }
                  // 插入推荐类
                  this.eight.list.unshift({
                    groupName: "精选",
                    id: "",
                    prodClsList: {
                      pages: 4,
                      records: []
                    }
                  })
                  this.eight.list = this.eight.list
                }
              }
            })

          })
        })
        // 优惠券
        this.getCouponList()
      })
    },
    // 更多数据
    getMoreList() {
      if (this.eight.list) {
        console.log(this.eight.list);
        let item = this.eight.list[this.active]
        let param = {
          activId: item.activiId ? item.activiId : "1662003169351176193",
          moduleId: item.moduleId ? item.moduleId : "1663068601709498370",
          groupId: item.id ? item.id : "",
          pageNo: this.pageNo,
          pageSize: 10,
        };
        fatherMoreList(param).then((res) => {
          if (res.code == 200) {
            if (this.morelist == undefined) {
              return
            }
            this.morelist = this.morelist.concat(res.result.records)
            item.prodClsList.pages = res.result.pages
            this.pageNo += 1
          }
          this.loopcontrol_reach = 0
        });
      }
    },
    // 获取优惠券
    getCouponList() {
      let params = {
        activiId: ""
      }
      findCouponByActiv(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log('优惠券', res);
          this.couponList = res.result.coupons.slice(0, 3)
          this.couponfa = false
        }
      });
    },
    // 换一批数据
    anotherPage(e) {
      switch (e) {
        case 1:
          this.fourModuleProList(false)
          break;
        case 2:
          this.fiveModuleProList(false)
          break;
        case 3:
          this.sixModuleProList(false)
          break;
        case 4:
          this.sevenModuleProList(false)
          break;
        default:
          console.log("antoher");
      }

    },
    fourModuleProList(reset = true) {
      let item = this.four
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNo,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.result.records && res.result.records.length > 0) {
          this.four.list = [].concat(res.result.records)
          this.four.pages = res.result.pages
          this.four.pageNo = res.result.current
          console.log(1, this.four.list);
          if (res.result.records.length < 6) {
            this.anopageNo = 2
            param.pageNo = 1
            fatherMoreList(param).then((res) => {
              this.four.list = [].concat(res.result.records)
              console.log(2, this.four.list);
              this.$forceUpdate();
            })
            return
          }
          this.anopageNo += 1
          this.$forceUpdate();
        } else if (reset) {
          this.four.list = []
          this.four.pages = 1
          this.four.pageNo = 1
        }
        this.loadingState[0] = true
      });
    },
    fiveModuleProList(reset = true) {
      let item = this.five
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNofive,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.result.records && res.result.records.length > 0) {
          this.five.list = [].concat(res.result.records)
          this.five.pages = res.result.pages
          this.five.pageNo = res.result.current
          console.log(1, this.five.list);
          if (res.result.records.length < 6) {
            this.anopageNofive = 2
            param.pageNo = 1
            fatherMoreList(param).then((res) => {
              this.five.list = [].concat(res.result.records)
              console.log(2, this.five.list);
              this.$forceUpdate();
            })
            return
          }
          this.anopageNofive += 1
          this.$forceUpdate();
        } else if (reset) {
          this.five.list = []
          this.five.pages = 1
          this.five.pageNo = 1
        }
        this.loadingState[1] = true
      });
    },
    sixModuleProList(reset = true) {
      let item = this.six
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNosix,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.result.records && res.result.records.length > 0) {
          this.six.list = [].concat(res.result.records)
          this.six.pages = res.result.pages
          this.six.pageNo = res.result.current
          console.log(1, this.six.list);
          if (res.result.records.length < 6) {
            this.anopageNosix = 2
            param.pageNo = 1
            fatherMoreList(param).then((res) => {
              this.six.list = [].concat(res.result.records)
              console.log(2, this.six.list);
              this.$forceUpdate();
            })
            return
          }
          this.anopageNosix += 1
          this.$forceUpdate();
        } else if (reset) {
          this.six.list = []
          this.six.pages = 1
          this.six.pageNo = 1
        }
        this.loadingState[2] = true
      });
    },
    sevenModuleProList(reset = true) {
      let item = this.seven
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNoseven,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.result.records && res.result.records.length > 0) {
          this.seven.list = [].concat(res.result.records)
          this.seven.pages = res.result.pages
          this.seven.pageNo = res.result.current
          console.log(1, this.seven.list);
          if (res.result.records.length < 6) {
            this.anopageNoseven = 2
            param.pageNo = 1
            fatherMoreList(param).then((res) => {
              this.seven.list = [].concat(res.result.records)
              console.log(2, this.seven.list);
              this.$forceUpdate();
            })
            return
          }
          this.anopageNoseven += 1
          this.$forceUpdate();
        } else if (reset) {
          this.seven.list = []
          this.seven.pages = 1
          this.seven.pageNo = 1
        }
        this.loadingState[3] = true
      });
    },
    // 监听页面滚动
    onpageScroll() {
      // 存储触底条件相关数据
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {

        // 后续调用
        if (this.loopcontrol_reach == 0) {
          this.getMoreList()
          this.loopcontrol_reach = 1
        }
      }
    },
    // 旋转
    rotate() {
      setTimeout(() => {
        this.boxunder = 'transform:rotateY(180deg)'
        this.boxup = 'transform:rotateY(0deg)'
      }, 2000)
      setTimeout(() => {
        this.boxunderCopy = 'transform:rotateY(180deg)'
        this.boxupCopy = 'transform:rotateY(0deg)'
      }, 4000)
    },
    // 反向旋转
    rotateback() {
      setTimeout(() => {
        this.boxunder = 'transform:rotateY(0deg)'
        this.boxup = 'transform:rotateY(-180deg)'
      }, 2000)
      setTimeout(() => {
        this.boxunderCopy = 'transform:rotateY(0deg)'
        this.boxupCopy = 'transform:rotateY(-180deg)'
      }, 4000)
    },
    // 跳转
    jump(id, activiId, sortGoodsId) {
      console.log(id, activiId, sortGoodsId);
      this.$router.push({
        name: 'Secondary',
        params: { id: id, activiId: activiId, sortGoodsId: sortGoodsId }
      });
    },
    // 点击领取优惠券
    clickCheckToUsePageHandle(item, index) {
      if (item) {
        let data = {
          activId: item.activId ? item.activId : null,
          activName: item.activName ? item.activName : null,
          fromTable: item.fromTable,
          couponId: item.id,
        }
        this.couponRquest = data
        let clientType = localStorage.getItem('Userhid')
        alert(clientType)
        if (!clientType) {
          alert("未找到设备参数")
        }
        let accessToken = ''
        if (clientType == '1') {
          accessToken = "1";
          if (!localStorage.getItem('Usertoken')) {
            wx.miniProgram.redirectTo({
              appId: 'wx5b714f2180ea9ea0',
              url: '/pages/login/index?fakey=1'
            });
          }
          // 安卓
        } else if (clientType == '2') {
          accessToken = "2";
          if (!localStorage.getItem('Usertoken')) {
            accessToken = this.webViewBridgeAccessToken()
          }
          // iOS
        } else if (clientType == '3') {
          accessToken = "3";
          if (localStorage.getItem('Usertoken') == "X1") {
            this.getUserInfoToken(index);
          }
        }
        data.token = localStorage.getItem('Usertoken')
        if (accessToken) {
          receiveMemberCouponV4(data).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.touchPopup(1)
              this.getCouponList()

            } else {
              this.touchPopup(2)
              this.getCouponList()
            }
          });
        }
      }
    },
    // 立即使用优惠券
    useCoupon(index) {
      this.getUserInfoToken(index);
      let item = this.couponList[index];
      if (item) {
        console.log(item);
        wx.miniProgram.navigateTo({
          appId: 'wx5b714f2180ea9ea0',
          url: '/pages2/childDay/coupon?id=' + item.id + '&name=' + item.name + '&discountPrice=' + item.discountPrice + '&startDate=' + item.startDate + '&endDate=' + item.endDate + '&days=' + item.days
        });
      }
    },
    // 跳转商品页
    toGoodInfo(id) {
      console.log(id);
      var hid = ""
      var andid = `com.mshop.shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
      var iosid = `ZYProductDetailsVC?${id}`
      console.log(andid);
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        if (hid == 2) {
          // 安卓
          window.MdhShop.openComActivityDetailPage(andid)
        } else if (hid == 3) {
          // ios
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: '/pages1/goodInfo/goodInfo?id=' + id,
          });
        }
      }
    },
    // 三端跳转商品详情
    toJingangUrlPage(wxurl, andUrl, iosUrl) {
      console.log(wxurl, andUrl, iosUrl);
      console.log(localStorage.getItem('Userhid'));
      var hid = ""
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        if (hid == 2) {
          // 安卓
          window.MdhShop.openComActivityDetailPage(andUrl)
        } else if (hid == 3) {
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosUrl);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: wxurl,
          });
        }
      }
    },
    // 弹出层
    touchPopup(e) {
      if (e == 1) {
        this.$toast.success('领取成功');
      } else {
        this.$toast.fail('领取失败,请重试');
      }
    },
    // 安卓端处理Token(获取存储)
    webViewBridgeAccessToken() {
      let accessToken = localStorage.getItem('Usertoken')
      alert(accessToken)
      if (!accessToken) {
        accessToken = window.MdhShop.webViewBridgeAccessToken(true)
        localStorage.setItem("Usertoken", accessToken)
        this.couponRquest.token = localStorage.getItem("Usertoken")
        receiveMemberCouponV4(this.couponRquest).then((res) => {
          alert(this.couponRquest)
          if (res.code == 200) {
            this.touchPopup(1)
            this.getCouponList()

          } else {
            this.touchPopup(2)
            this.getCouponList()
          }
        });
      }
      return accessToken
    },
    // iOS端处理Token(获取存储)
    getUserInfoToken(index) {
      alert("five")
      accessToken = window.webkit.messageHandlers.getUserInfoToken.postMessage(this.couponList[index]);
      return accessToken
    },
    getUserInfoTokenIos(data) {
      localStorage.setItem("Usertoken", data)
      receiveMemberCouponV4(this.couponRquest).then((res) => {
        alert(this.couponRquest)
        if (res.code == 200) {
          this.touchPopup(1)
          this.getCouponList()

        } else {
          this.touchPopup(2)
          this.getCouponList()
        }
      });
    }
  }
};
</script>

<style scoped>
/* image边框 */

.coupon_topcarth,
.coupon_toplimit li,
.coupon_botom ul:nth-child(1),
.boomshop_carthbox,
.hotshop_carthbox,
.more_goodstitle,
.dressstyle_gotext,
.upcarth ul,
.morecontentl_sign,
.morecontentl_carth,
.goodssign_box p {
  font-family: PingFangSC-Medium, PingFang SC;
}

/* --------------------------------- */
.box {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.pages {
  padding: 0;
  margin: 0;
  width: 100%;
}

.pages_bgc {
  width: 100%;
  height: 314px;
  display: block;
}

.pages_content {
  margin-top: -1%;
  padding: 0 12px 0 12px;
  background: #6498FF;
}

.couponbox {
  width: 100%;
  height: 71px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 13px;
}

.coupon {
  height: 100%;
  width: 32%;
  position: relative;
}


.coupon img {
  height: 100%;
  width: 100%;
}

.coupon_info {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  bottom: 6%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.coupon_top {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
}

.coupon_topcarth,
.coupon_toplimit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  color: #8D0100;
  line-height: 17px;
}

.coupon_topcarth p {
  margin-top: 10px;
}

.coupon_topcarth p:nth-child(1) {
  margin-top: 10px;
}

.coupon_topcarth p:nth-child(2) {
  font-size: 30px;
  margin-top: -2px;
  font-weight: 500;
}

.coupon_toplimit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 2px;
}

.coupon_toplimit li {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.coupon_toplimit li:nth-child(1) {
  height: 14px;
  font-size: 10px;
  font-weight: 500;
  color: #911711;
  line-height: 14px;
}

.coupon_toplimit li:nth-child(2) {
  height: 11px;
  font-size: 8px !important;
  font-weight: 400;
  color: rgba(255, 0, 0, 0.5);
  line-height: 11px;
  margin-top: 3px;
}

.coupon_botom {
  width: 78%;
  height: 21px;
  background: linear-gradient(270deg, #FF182E 0%, #FF5C35 100%);
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coupon_botom ul:nth-child(1) {
  width: 40px;
  height: 14px;
  font-size: 13px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
  white-space: nowrap;
  margin-left: 4%;
}

.coupon_botom img {
  width: 10px;
  height: 12px;
  margin-left: 15%;
  margin-top: 1%;
}

.boomshop {
  width: 100%;
  height: 396px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

}

.hotshop {
  width: 100%;
  height: 385px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
}

.boomshop_bgc,
.hotshop_bgc {
  height: 100%;
  width: 100%;
}

.boomshopbox_out {
  position: absolute;
  top: 16%;
  bottom: 0.5%;
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
}

.boomshop_listbox {
  margin-bottom: 1%;
  height: 159px;
  width: 32%;
  perspective: 1500;
  -webkit-perspective: 1500;
  -moz-perspective: 1500;
}

.boomshop_list {
  width: 100%;
  height: 159px;
  transition: all 2s;
  backface-visibility: hidden;
  border-radius: 8px;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 800px;
}

.boomshop_under {
  background-color: white;
}

.boomshop_up {
  margin-top: -159px;
  background-color: white;
  position: relative;
  z-index: -1;
  transform: rotateY(180deg) translateZ(1px);
}

.boomshop_list .list_img,
.hotshop_list .list_img {
  object-fit: contain;
  width: 100%;
  height: 129px;
  background-color: #FFFFFF;
  border-radius: 4px 4px 0 0;
}

.boomshop_listcarth img,
.hotshop_listcarth img {
  width: 100%;
  height: 30px;
  margin: -4px auto;
}

.boomshop_carthbox,
.hotshop_carthbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -34px;
  width: 59px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 20px;
  margin: 0 auto;
}

.boomshop_carthbox {
  width: 100%;
  top: -4px;
  box-shadow: inset 0 -6px 12px 0 #746Eff;
}

.boomshop_carthbox p:nth-child(1),
.hotshop_carthbox p:nth-child(1) {
  margin-top: 3px;
}

.boomshop_carthbox p:nth-child(2),
.hotshop_carthbox p:nth-child(2) {
  font-size: 22px;
}

.boutiqueshop {
  width: 100%;
  height: 300px;
  margin: 8px 0 8px 0;
  display: flex;
  justify-content: center;
}

.boutiqueshop_bgc {
  position: absolute;
  width: 164px;
  height: 36px;
  margin-top: 0px;
}

.boutiqueshop_contentbox {
  width: 100%;
}


.boutiqueshop_content {
  width: 49%;
  height: 172px;
  border-radius: 4px;
  float: left;
}

.boutiqueshop_content img {
  width: 100%;
  height: 100%;
}

.boutiqueshop_content:nth-child(2) {
  float: right;
}

.boutiqueshop_content:nth-child(-n+2) {
  margin-top: 38px;
  margin-bottom: 1.3%;
}

.boutiqueshop_content:nth-child(3) {
  margin-right: 1.2%;
}

.boutiqueshop_content:nth-child(3),
.boutiqueshop_content:nth-child(4) {
  width: 24%;
  height: 84px;
  float: left;
}

.boutiqueshop_content:nth-child(5) {
  float: right;
  height: 84px;
}

.hotshop {
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}


.hotshop_listbox {
  width: 95%;
  position: absolute;
  bottom: 0.5%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.hotshop_list {
  border-radius: 4px;
  width: 32%;
  margin-bottom: 1%;
  height: 159px;
}

.hotshop_carthbox {
  margin-left: 0;
  margin-top: 4px;
  color: #FFFFFF;
}


.dressstyle {
  margin-top: 8px;
  width: 100%;
  height: 405px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dressstyle_bgc {
  width: 100%;
  height: 100%;
}

.dressstyle_top {
  width: 95%;
  margin: 0 auto;

  position: absolute;
  top: 11.5%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.dressstyle_topcont {
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: relative;
  border-radius: 4px;
  margin-top: 4px !important;
}


.dressstyle_topcont:nth-child(3n+2) {
  margin: 0 2px 0 2px;
}

.dressstyle_topcont:nth-child(-n+3) {
  margin-top: 0 !important;
}


.dressstyle_topimg {
  width: 100%;
  height: 120px;
  object-fit: contain;
}

.dressstyle_toptext {
  margin: 0 0 2px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  height: 17px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 17px;
}

.dressstyle_botbox {
  height: 35px;
  width: 100%;
  position: relative;
}

.dressstyle_botimg {
  height: 100%;
  width: 100%;
}

.dressstyle_botupcar,
.dressstyle_botcar {
  position: absolute;
  top: 0;
  left: 24%;
  margin: 0;
}

.dressstyle_botupcar {
  height: 14px;
  font-size: 10px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 14px;
  white-space: nowrap;
}

.dressstyle_botupcarano {
  top: 14px !important;
  left: 6px !important;
}

.dressstyle_botcar {
  display: flex;
  top: 18px;
  height: 22px;
}

.dressstyle_sign,
.dressstyle_carth,
.dressstyle_go {
  margin: 0;
  font-size: 10px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 14px;
}

.dressstyle_sign {
  margin-top: 2px;
}

.dressstyle_carth {
  font-size: 15px;
}

.dressstyle_go {
  white-space: nowrap;
  margin-left: 4px;
}

.dressstyle_gosign,
.dressstyle_gotext {
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 30px;
  margin: 0;
}

.dressstyle_gotext {
  position: absolute;
  top: 10px;
  left: 4px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 20px;
}

.dressstyle_botcar img {
  margin-top: 4px;
  width: 8px;
  height: 8px;
}

.bottom_content:nth-child(5) .bottom_contimg {
  width: 84px;
}

.dressstyle_btnimg {
  width: 20px;
  height: 18px;
}

.dressstyle_btn {
  position: absolute;
  top: 2%;
  right: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98px;
  height: 26px;
  border-radius: 13px;
  border: 1px solid #FFFFFF;
  cursor: pointer;
  color: white;
}

.dressstyle_btn p {
  margin-left: 5px;
  margin-top: -2px;
}

/* 更多商品 */
.moregoods {
  margin-top: 3%;
}

.more_listtitle {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.more_titletext {
  margin: 0;
  width: 108px;
  height: 23px;
  font-size: 18px;
  font-family: HelloFont-WenYiHei, HelloFont;
  font-weight: normal;
  color: #333333;
  line-height: 23px;
  margin: 0 6px 0 6px;
}


.more_goodslist {
  padding: 4% 0;
  height: 38px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #6498FF;
  width: 375px;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
  height: 10px;
}

.more_goodstitle {
  margin-left: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}

.more_goodstitleimg {
  width: 98px;
  height: 38px;
}

.more_goodstitle:nth-child(1) {
  margin-left: 0px;
}

.more_goodstitletext {
  position: absolute;
  top: 9px;
  color: #333333;
}

.more_goodstitletext_active {
  color: white;
}

.more_goods {
  width: 100%;
}

.more_goodsl {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}


.more_contentl,
.more_contentr {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 49%;
  margin-bottom: 6px;
  border-radius: 6px;
}

.more_contentl:nth-child(2n) {
  margin-left: 5px;
}

.morecontentl_img,
.morecontentr_img {
  width: 100%;
  height: 180px;
  object-fit: contain;
}

.morecontentl_text,
.morecontentr_text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
  margin-top: 2px;
  width: 95%;
  height: 20px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.morecontentl_carthx,
.morecontentl_cartzj {
  width: 95%;
  height: 32px;
  background: #FFD8D6;
  border-radius: 4px;
  margin-bottom: 3%;
  position: relative;
}

.morecontentr_carthx,
.morecontentl_cartzj {
  width: 95%;
  height: 25px;
}

.morecontentl_cartzj {
  background: white;
}

.morecontentl_sign,
.morecontentl_carth {
  float: left;
  margin: 0;
  margin: 7px 0 0 2px;
  font-size: 12px;
  font-weight: 500;
  color: #FF3F2F;
  line-height: 17px;
}

.morecontentr_sign {
  margin-top: 4px;
}

.morecontentr_carth {
  font-size: 20px;
}


.morecontentl_sign {
  margin-top: 10px;
}

.morecontentl_carth {
  font-size: 24px;
  font-weight: 600;
  margin-left: 0;
}

.morecontentl_carthx img {
  width: 42px;
  height: 32px;
  float: right;
}

.upcarth {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 2px;
  right: 8px;
}

.upcarth ul {
  font-size: 10px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 12px;
}


.goodssign_box {
  width: 168px;
  display: flex;
  justify-content: flex-start;
  margin: 2px 0 4px 0;
}

.goodssign_box p {
  width: auto;
  height: 16px;
  border-radius: 4px;
  border: 1px solid #FC5044;
  margin: 0;

  font-size: 11px;
  font-weight: 500;
  color: #FF3F2F;
  line-height: 16px;
  margin-right: 4px;
}


.morelist_text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: rgb(58, 57, 57);
  padding: 5px 0;
  cursor: pointer;
}

.morelist_img {
  text-align: center;
  padding: 10px 0 10px 0px;
}

.more_titleimg {
  width: 164px;
  height: 36px;
}

.messagePopup {
  width: 48%;
  height: 25%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 20%;
  z-index: 999999;
  transition: all .4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.messagePopup img {
  width: 20%;
  height: 20%;
}

.messagePopup ul {
  color: white;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.messagePopupbtn {
  width: 15%;
  height: 15%;
  position: fixed;
  top: 20%;
}

.skeletonbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500vh;
  background-color: white;
}
</style>
