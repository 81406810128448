import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import wx from "weixin-js-sdk";
Vue.config.ignoredElements = [
  'wx-open-launch-weapp',
]
Vue.prototype.$wx = wx;
Vue.config.productionTip = false;
// Vant组件
import 'vant/lib/index.css';
import { Button, Toast, Skeleton, Swipe, SwipeItem, Tab, Tabs, Icon,CountDown, Popup} from 'vant';
Vue.use(Skeleton);
Vue.use(Button);
Vue.use(Toast);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Icon);
Vue.use(CountDown);
Vue.use(Popup);
// 全局方法
import { CouponreceiveMsg } from "../src/utils/utils";
Vue.prototype.CouponreceiveMsg = CouponreceiveMsg;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
