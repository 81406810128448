<template>
  <div class="bg" :style="{ background: moduleColor,minHeight:'100vh'}">
    <!-- 直降 -->
    <div class="allcss" :style="{}" v-if="id == '1701516138552233986'">
      <img class="moduleImg1" :src="moduleImg" alt="" />
      <div class="con con1">
        <div class="fall" v-for="item in goodsList" @click="toGoodInfo(item.id)">
          <img class="good_img" :src="item.thumbnail" alt="">
          <div class="fall_right">
            <div class="good_name">
              <div class="name_text">{{ item.prodName }}</div>
              <div class="good_label">
                <div class="new" v-if="item.isNew == 1">新品</div>
                <template v-if="item.goodLabels.length > 0">
                  <img class="activImage" v-if="item.goodLabels[0].activImage" :src="item.goodLabels[0].activImage"
                    alt="">
                </template>
              </div>
            </div>
            <div class="fall_right_bom">
              <div class="fall_right_bom_left">
                <img src="@/assets/image/nationary/nation_fall.png" alt="">
                <div class="fall_pirce">
                  <p class="scribe p1" v-if="item.marketPriceOffline - item.marketPrice > 0">￥{{ item.marketPriceOffline
                  }}</p>
                  <p class="scribe p1" v-else></p>
                  <div class="price p2">
                    <p>￥</p>{{ item.marketPrice }}
                  </div>
                </div>
                <div class="fall_text" v-if="item.marketPriceOffline - item.marketPrice > 0">
                  直降￥{{ item.marketPriceOffline - item.marketPrice }}
                </div>
              </div>
              <div class="fall_btn">立即抢购</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 满减 -->
    <div class="allcss" v-if="id == '1701518729134739458'">
      <img class="moduleImg2" :src="moduleImg" alt="" />
      <div class="con con2">
        <div class="reduce" v-for="item in goodsList" @click="toGoodInfo(item.id)">
          <img class="good_img" :src="item.thumbnail" alt="">
          <div class="reduce_right">
            <div class="good_name">
              <div class="name_text">{{ item.prodName }}</div>
              <div class="good_label">
                <div class="new" v-if="item.isNew == 1">新品</div>
                <template v-if="item.goodLabels.length > 0">
                  <img class="activImage" v-if="item.goodLabels[0].activImage" :src="item.goodLabels[0].activImage"
                    alt="">
                </template>
                <div class="coupon_name" v-if="item.coupons.length > 0&&item.coupons[0].name">
                  {{ item.coupons[0].name }}</div>
              </div>
            </div>
            <div class="reduce_right_bom">
              <img src="@/assets/image/nationary/nation_reduce.png" alt="">
              <div class="reduce_price">
                <p>专场活动价：</p>
                <div class="price p3">
                  <p>￥</p>{{ item.marketPrice }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 策略 -->
    <div class="allcss" v-if="id == '1701518944361254914'">
      <img class="moduleImg3" :src="moduleImg" alt="" />
      <div class="con con3">
        <div class="policy" v-for="item in goodsList" @click="toGoodInfo(item.id)">
          <img class="good_img" :src="item.thumbnail" alt="">
          <div class="policy_right">
            <div class="good_name">
              <div class="name_text">{{ item.prodName }}</div>
              <div class="good_label">
                <div class="new" v-if="item.isNew == 1">新品</div>
                <template v-if="item.goodLabels.length > 0">
                  <img class="activImage" v-if="item.goodLabels[0].activImage" :src="item.goodLabels[0].activImage"
                    alt="">
                </template>
              </div>
            </div>
            <div class="policy_right_bom">
              <img src="@/assets/image/nationary/nation_policy2.png" alt="">
              <div class="policy_price">
                <div class="price p4">
                  <p>￥</p>{{ item.marketPrice }}
                </div>
                <p class="scribe p5" v-if="item.marketPriceOffline - item.marketPrice > 0">￥{{ item.marketPriceOffline }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="loading">{{ loading }}</div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk"
import { globalData } from "../../utils/config";
import { fatherMoreList, findShopComActivitiModuleById } from '../../utils/api/fatherDayapi'
import good_list from "@/components/good_list.vue";
import { findCouponByActiv, receiveMemberCouponV4 } from '@/utils/api/api'
export default {
  name: 'sendnational',
  components: { good_list },
  data() {
    return {
      loopcontrol: 0,
      loopcontrol_reach: 0,
      globalData: globalData,
      moduleImg: '',//标题
      moduleColor: '',//背景颜色
      goodsList: [],//数组
      pageNo: 1,//请求页数
      loading: '加载更多',//加载更多名字
      open: false,
      id: '',
      couponList: [],
      total:0
    };
  },
  created() {

    this.getStyle()
    this.clientHeight = document.documentElement.clientHeight
    console.log(this.clientHeight);
  },
  mounted() {
    this.getModuleProListByModule()

    window.addEventListener('scroll', this.handleScroll);//监听滚动条


  },
  methods: {
    // 滚动到底部时加载更多数据
    handleScroll() {
      if (this.open) {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          if (this.loopcontrol_reach == 0) {
            this.getModuleProListByModule(false)
            this.loopcontrol_reach = 1
          }
        }
      }
    },

    // 获取模块下的商品
    getModuleProListByModule(res = true) {
      var route = this.$route.params
      console.log(route);
      var list = this.goodsList
      var param = {
        activId: route.activiId,
        moduleId: route.id,
        sortGoodsId: route.sortGoodsId,
        pageNo: res ? 1 : this.pageNo,
        pageSize: 10,
      };
      fatherMoreList(param).then(res => {
        console.log(param);
        console.log(res.result, this.goodsList.length);
        if (res.code == 200) {
          this.goodsList = this.goodsList.concat(res.result.records)
          this.pageNo += 1
          this.open = true
          this.total=res.result.total
          if (res.result.records && res.result.records.length > 0) {
            if (this.total==this.goodsList.length) {
              this.loading = '没有更多了'
            } else {
              this.loading = '加载更多';
              this.loopcontrol_reach = 0
            }
          }
        }
      });

    },
    // 获取背景颜色和标题
    getStyle() {
      var route = this.$route.params
      console.log(route.id);
      this.id = route.id
      this.time = route.time
      this.timestate = route.timestate
      var param = {
        id: route.id
      }
      console.log(param);
      findShopComActivitiModuleById(param).then(res => {
        console.log(res);
        if (res.result && res.result.records && res.result.records.length > 0) {
          let item = res.result.records[0];
          if (item.moduleImg) {
            let end = item.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            console.log(end[1]);
            if (end && end.length > 1) {
              this.moduleImg = end[1]; //
            }
          }
          console.log(this.moduleImg);
          this.moduleColor = item.moduleColor
        }
      })
    },
    // 调转到小程序详情页
    toGoodInfo(id) {
      console.log(id);
      var andid = `com.mshop.shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
      var iosid = `ZYProductDetailsVC?Goodsid=${id}`
      var hid = ""
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        console.log(hid);
        if (hid == 2) {
          window.MdhShop.openComActivityDetailPage(andid)
        } else if (hid == 3) {
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: '/pages1/goodInfo/goodInfo?id=' + id,
          });
        }
      }


    },
    back() {
      this.$router.replace("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;

  .allcss {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }

  .moduleImg1 {
    width: 100%;
  }

  .moduleImg2 {
    width: 100%;
  }

  .moduleImg3 {
    width: 100%;
  }

  .new {
    width: 30px;
    height: 16px;
    margin-right: 3px;
    display: flex;
    align-content: center;
    justify-content: center;
    background: linear-gradient(90deg, #CFB98E 0%, #A27842 100%);
    border-radius: 2px;
    font-size: 10px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }

  .con {
    width: 93.6%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .good_img {
    width: 142px;
    height: 147px;
    object-fit: cover;
    border-radius: 4px;
  }

  .good_name {

    .name_text {
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      margin-bottom: 4px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden; //溢出内容隐藏
      text-overflow: ellipsis; //文本溢出部分用省略号表示
      display: -webkit-box; //特别显示模式
      -webkit-line-clamp: 2; //行数
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .good_label {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  // 价格
  .price {
    display: flex;
    align-items: flex-end;
    font-size: 20px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;

    p {
      font-size: 12px;
      margin-bottom: 3%;
    }
  }

  // 划线价
  .scribe {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #AAAAAA;
    text-decoration: line-through;
  }

  // 标签
  .activImage {
    position: relative;
    width: 48px;
    height: 14px;
    margin-right: 2px;
  }

  .con1 {
    margin-top: -3%;

    .fall {
      width: 100%;
      height: 147px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 4px;
      margin-bottom: 8px;

      &_right {
        padding: 8px;
        height: 100%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        &_bom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &_left {
            position: relative;
            width: 55%;

            img {
              width: 100%;
              height: 100%;
            }

            .fall_pirce {
              width: 100%;
              position: absolute;
              top: 5%;
              display: flex;
              align-items: center;
              color: #FFFFFF;

              .p1 {
                width: 35%;
                margin-right: 5%;
              }

              .p2 {
                width: 60%;
                margin-top: -3%;
                justify-content: center;
              }
            }

            .fall_text {
              position: absolute;
              bottom: 16%;
              font-size: 10px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #FF0022;
            }
          }

          .fall_btn {
            width: 35%;
            height: 22px;
            background: linear-gradient(180deg, #FF604A 0%, #FF0022 100%);
            border-radius: 11px;
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FFFFFF;
            text-align: center;
            line-height: 22px;
          }
        }
      }
    }
  }

  .con2 {
    .coupon_name {
      border-radius: 2px;
      border: 1px solid #FF0022;
      font-size: 8px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FF0022;
      margin-right: 4px;
      padding: 0 4px;
      box-sizing: border-box;
    }

    .reduce {
      height: 147px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: #FFFFFF;
      margin-bottom: 8px;

      &_right {
        padding: 8px;
        height: 100%;
        width: 60%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        &_bom {
          width: 100%;
          position: relative;
          font-size: 12px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;

          img {
            width: 100%;
          }

          .reduce_price {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            top: 0;
            right: 15%;
            left: 5%;

            .p3 {
              width: 45%;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .con3 {
    margin-top: -7%;

    .policy {
      height: 147px;
      display: flex;
      align-items: center;
      background: #FFFFFF;
      border-radius: 4px;
      margin-bottom: 8px;

      &_right {
        padding: 8px 8px 0 8px;
        width: 60%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        box-sizing: border-box;

        &_bom {
          width: 100%;
          position: relative;

          img {
            width: 100%;
          }

          .policy_price {
            position: absolute;
            display: flex;
            align-items: flex-end;
            bottom: 18%;
            width: 100%;
            padding-left: 3px;
            color: #FF0022;
            font-weight: 500;

            .p4 {
              margin-right: 5%;

              p {
                margin-bottom: 5%;
              }
            }
          }
        }
      }
    }
  }

  .loading {
    margin: 0 auto;
    color: #aaa;
  }
}</style>
  