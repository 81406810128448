import Service from "../Request";
import { globalapiHeader } from "../../utils/config";
let apiUrl = globalapiHeader.staticUrl

// 父亲节数据 
export function fatherList(query) {
  console.log(query);
  return Service({
    url: apiUrl + "shopmall/shopComActiviti/findActiviModuleTopDataByNum",
    method: "get",
    params: query,
  });
}
// 获取背景颜色和标题
export function findShopComActivitiModuleById(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/shopmall/shopComActivitiModule/list",
    method: "get",
    params: query,
  });
}
// 父亲节更多商品数据
export function fatherMoreList(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/shopmall/shopComActivitiGoods/findGoodsData",
    method: "get",
    params: query,
  });
}
// 获取优惠券
export function fatherCoupon(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/getPlateCoupon",
    method: "get",
    params: query,
  });
}
// 领取优惠券
export function receiveMemberCouponV3(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/receiveMemberCouponV3",
    method: "post",
    data: query
  });
}
// 2023.6.7,目测需要通用的接口---------------------------
// 获取活动对应优惠券
export function findCouponByActiv(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/shopmall/shopComCoupon/findCouponByActiv",
    method: "get",
    params: query
  });
}
// 领取活动优惠券
export function receiveMemberCouponV4(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/receiveMemberCouponV4",
    method: "post",
    data: query
  });
}
// 获取会场数据 
export function getIndexActiveList(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/getIndexActiveListV3",
    method: "get",
    params: query
  });
}
 
