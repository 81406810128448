<template>
    <div class="box">
        <vanpopup :textpopup="textpopup" :showpopup="showpopup" @home-click="goHome"></vanpopup>
        <div class="pages" v-if="!showpopup">
            <img class="pages_bgc" :src="pagebgc" />
            <div class="pages_content">
                <!-- 优惠券 -->
                <ul class="couponbox">
                    <img class="couponboxbg" alt="">
                    <!-- <div class="coupon_info">
                        <div class="couinfo_left">
                            <span>￥</span>
                            {{ todo.discountPrice }}
                            <span>元</span>
                        </div>
                        <div class="couinfo_right">
                            <div class="right_info">
                                <p>{{ todo.name }}</p>
                                <p>限万圣节活动商品使用</p>
                            </div>
                            <div class="right_but" @click="clickCheckToUsePageHandle(todo, index)"
                                v-if="parseInt(todo.couponLimit) != todo.useCount">立即领取</div>
                            <div class="right_but" @click="useCoupon(index)"
                                v-if="parseInt(todo.couponLimit) == todo.useCount && todo.useCount != todo.usedCount">立即使用
                            </div>
                            <div class="right_but" @click="useCoupon(index)"
                                v-if="parseInt(todo.couponLimit) == todo.useCount && todo.useCount == todo.usedCount">已领完
                            </div>
                        </div>
                    </div> -->
                </ul>
                <!-- 更多商品 -->
                <!-- <ul>
                    <good_list :list="goodsList" :state="true" @good-click1="toGoodInfo"></good_list>
                </ul> -->
                <div class="loading">{{ loading }}</div>
            </div>
        </div>
        <div class="skeletonbox" v-if="activefa != false && couponfa != false && rotatefa != false">
            <Skt :activeloading="activefa" :couponloading="couponfa" :rotateloading="rotatefa"></Skt>
        </div>
    </div>
</template>
  
<script>
import wx from "weixin-js-sdk"
import { globalData } from "@/utils/config";
import { fatherList, fatherMoreList } from '@/utils/api/fatherDayapi'
import { findCouponByActiv, receiveMemberCouponV4 } from '@/utils/api/api'
import good_list from "@/components/good_list.vue";
import vanpopup from "@/components/vanpopup.vue";
import Skt from "@/components/skeleton_screen/fatherDay/fatherDay.vue"
export default {
    data() {
        return {
            showpopup: false,//没有活动弹出层
            textpopup: '',//弹出层提示
            active: 0,
            // 图片
            pagebgc: "",
            // 状态
            activefa: false,
            couponfa: false,
            rotatefa: false,
            // 数据
            couponRquest: {},
            activId: "",
            moduleId: '',
            globalData: globalData,
            couponList: [],
            goodsList: [],
            aaa: ['1'],
            pageNo: 1,
            loading: ''
        };
    },
    components: { good_list, Skt, vanpopup },
    created() {
        let obj = {}
        let reg = /([^?=&]+)=([^?=&]+)/g
        let data = window.location.href.replace("#/", "");
        data.replace(reg, (...arg) => {
            obj[arg[1]] = arg[2]
        })
        console.log(window.location.href, data, obj);
        if (obj.hid) {
            localStorage.setItem("Userhid", obj.hid);
        } else {
            localStorage.setItem("Userhid", "")
        }
        if (obj.token) {
            localStorage.setItem("Usertoken", obj.token);
        } else {
            localStorage.setItem("Usertoken", "")
        }
        this.getBodyAllList()
        window.getUserInfoTokenIos = this.getUserInfoTokenIos
    },
    mounted() {
        window.addEventListener('scroll', this.onpageScroll);
    },
    methods: {
        carouselList() {
        },
        // 全局数据
        getBodyAllList() {
            // fatherList({ activiNum: '115' }).then((res) => {
            //     if (res.code == 500) {
            //         this.showpopup = true
            //         this.textpopup = res.message
            //     }
            //     if (res.code == 200) {
            //         this.showpopup = false
            //         console.log('总数据', JSON.parse(JSON.stringify(res)));
            //         this.pagebgc = res.result.activImage//主图
            //         this.activefa = false
            //         this.activId = res.result.id//活动id
            //         this.moduleId = res.result.modules[0].id//模块id	
            //         // this.goodsList = res.result.modules[0].prodClsList.records
            //         this.getModuleProListByModule()
            //     }
            //     // 优惠券
            //     this.getCouponList()
            // })
        },

        // 获取优惠券
        getCouponList() {
            let params = {
                activiId: this.activId
            }
            findCouponByActiv(params).then((res) => {
                if (res.code == 200) {
                    console.log('优惠券', JSON.parse(JSON.stringify(res)));
                    this.couponList = res.result.coupons.slice(0, 3)
                    this.couponfa = false
                }
            });
        },
        // 监听页面滚动
        onpageScroll() {
            // 存储触底条件相关数据
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
            let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
            if (scrollTop + clientHeight + 1 >= scrollHeight) {

                // 后续调用
                if (this.loopcontrol_reach == 0) {
                    this.getModuleProListByModule(false)
                    this.loopcontrol_reach = 1
                }
            }
        },

        // 获取模块下的商品
        getModuleProListByModule(res = true) {
            var param = {
                activId: this.activId,
                moduleId: this.moduleId,
                pageNo: res ? 1 : this.pageNo,
                pageSize: 10,
            };
            fatherMoreList(param).then(res => {
                console.log(res.result, this.goodsList.length);
                if (res.code == 200) {
                    this.goodsList = this.goodsList.concat(res.result.records)
                    this.pageNo += 1
                    if (res.result.records && res.result.records.length > 0) {
                        if (this.pageNo > res.result.pages) {
                            this.loading = '没有更多了'
                        } else {
                            this.loading = '加载更多';
                            this.loopcontrol_reach = 0
                        }
                    }
                }
            });

        },
        // 点击领取优惠券
        clickCheckToUsePageHandle(item, index) {
            if (item) {
                let data = {
                    activId: item.activId ? item.activId : null,
                    activName: item.activName ? item.activName : null,
                    fromTable: item.fromTable,
                    couponId: item.id,
                }
                this.couponRquest = data
                let clientType = localStorage.getItem('Userhid')
                let accessToken = ''
                if (clientType == '1') {
                    accessToken = "1";
                    if (!localStorage.getItem('Usertoken')) {
                        wx.miniProgram.redirectTo({
                            appId: 'wx5b714f2180ea9ea0',
                            url: '/pages/login/index?fakey=1'
                        });
                    }
                    // 安卓
                } else if (clientType == '2') {
                    accessToken = "2";
                    if (!localStorage.getItem('Usertoken')) {
                        accessToken = this.webViewBridgeAccessToken()
                    }
                    // iOS
                } else if (clientType == '3') {
                    accessToken = "3";
                    if (localStorage.getItem('Usertoken') == "X1") {
                        this.getUserInfoToken(index);
                    }
                }
                data.token = localStorage.getItem('Usertoken')
                if (accessToken && parseInt(item.couponLimit) != item.useCount) {
                    receiveMemberCouponV4(data).then((res) => {
                        console.log(res);
                        if (res.code == 200) {
                            this.touchPopup(1)
                            this.getCouponList()
                        } else {
                            this.touchPopup(2)
                            this.getCouponList()
                        }
                    });
                }
            }
        },
        // 立即使用优惠券
        useCoupon(index) {
            // 小程序/苹果端跳转使用券
            if (localStorage.getItem('Userhid')) {
                if (localStorage.getItem('Userhid') == 1 || localStorage.getItem('Userhid') == 3) {
                    let item = this.couponList[index];
                    if (item) {
                        console.log(item);
                        wx.miniProgram.navigateTo({
                            appId: 'wx5b714f2180ea9ea0',
                            url: '/pages2/childDay/coupon?id=' + item.activCouponId
                        });
                    }
                    this.getUserInfoToken(index);
                }
            }
            // 安卓跳转使用券
            var andid = `shopcart.ui.fragment.ShopLargeFragment?id=${this.couponList[index].id}`
            window.MdhShop.openComActivityDetailPage(andid)
        },
        // 跳转商品详情页
        toGoodInfo(id) {
            console.log(id);
            var hid = ""
            var andid = `shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
            var iosid = `ZYProductDetailsVC?${id}`
            console.log(andid);
            if (localStorage.getItem('Userhid')) {
                hid = localStorage.getItem('Userhid');
                if (hid == 2) {
                    // 安卓
                    window.MdhShop.openComActivityDetailPage(andid)
                } else if (hid == 3) {
                    // ios
                    window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
                } else {
                    wx.miniProgram.navigateTo({
                        appId: 'wx5b714f2180ea9ea0',
                        url: '/pages1/goodInfo/goodInfo?id=' + id,
                    });
                }
            }
        },
        // 跳转首页
        goHome() {
            console.log(1);
            wx.miniProgram.switchTab({
                appId: 'wx5b714f2180ea9ea0',
                url: '/pages/index/index',
            });
        },
        // 弹出层
        touchPopup(e) {
            if (e == 1) {
                this.$toast.success('领取成功');
            } else {
                this.$toast.fail('领取失败,请重试');
            }
        },
        // 安卓端处理Token(获取存储)
        webViewBridgeAccessToken() {
            let accessToken = localStorage.getItem('Usertoken')
            if (!accessToken) {
                accessToken = window.MdhShop.webViewBridgeAccessToken(true)
                localStorage.setItem("Usertoken", accessToken)
                this.couponRquest.token = localStorage.getItem("Usertoken")
                receiveMemberCouponV4(this.couponRquest).then((res) => {
                    if (res.code == 200) {
                        this.touchPopup(1)
                        this.getCouponList()

                    } else {
                        this.getCouponList()
                    }
                });
            }
            return accessToken
        },
        // iOS端处理Token(获取存储)
        getUserInfoToken(index) {
            accessToken = window.webkit.messageHandlers.getUserInfoToken.postMessage(this.couponList[index]);
            return accessToken
        },
        getUserInfoTokenIos(data) {
            localStorage.setItem("Usertoken", data)
            receiveMemberCouponV4(this.couponRquest).then((res) => {
                if (res.code == 200) {
                    this.touchPopup(1)
                    this.getCouponList()
                } else {
                    this.getCouponList()
                }
            });
        }
    }

};
</script>
  
<style scoped>
.box {
    padding: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    font-family: PingFangSC-Medium, PingFang SC;

}


.pages {
    padding: 0;
    margin: 0;
    width: 100%;
    height: auto;
    font-family: PingFangSC-Medium, PingFang SC;
}

.pages_bgc {
    width: 100%;
    height: 342px;
    border: 1px solid;
    display: block;
}

.pages_content {
    margin-top: -12px;
    padding: 0 3.2%;
    background: #FF9A3A;
    display: flex;
    flex-direction: column;
}

/* 优惠券开始 */
.couponbox {
    width: 100%;
    height: 274px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 3px;
    position: relative;
}

.couponboxbg {
    width: 100%;
    height: 100%;
}

.coupon_info {
    position: absolute;
    top: -2%;
    width: 100%;
    height: 96px;
    display: flex;
    align-content: center;
}

.couinfo_left {
    width: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF661F;
}

.couinfo_left span:nth-child(1) {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF661F;
    line-height: 17px;
    margin-top: 22%;
}

.couinfo_left span:nth-child(2) {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    font-size: 10px;
    background: #FF661F;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16%;
}

.couinfo_right {
    width: 59%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3%;
}

.right_info p:nth-child(1) {
    height: 22px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 22px;
    margin-bottom: 8px;
}

.right_info p:nth-child(2) {
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 17px;
}

.right_but {
    width: 72px;
    height: 25px;
    background: #FFFFFF;
    box-shadow: 0rpx 4rpx 8rpx 0rpx rgba(255, 102, 31, 0.5);
    border-radius: 13px;
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #FF661F;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* 优惠券结束 */

.loading {
    margin: 0 auto;
    color: #000;
}
</style>
  