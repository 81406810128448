import Service from "../Request";
import { globalapiHeader } from "../../utils/config";
var apiUrl = globalapiHeader.staticUrl

// 父亲节数据
export function fatherList(query) {
  console.log(query);
  return Service({
    url: apiUrl + "shopmall/shopComActiviti/findActiviModuleTopDataByNum",
    method: "get",
    params: query,
  });
}
// 获取背景颜色和标题
export function findShopComActivitiModuleById(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/shopmall/shopComActivitiModule/list",
    method: "get",
    params: query,
  });
}
// 父亲节更多商品数据
export function fatherMoreList(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/shopmall/shopComActivitiGoods/findGoodsData",
    method: "get",
    params: query,
  });
}
// 父亲节优惠券
export function fatherCoupon(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/getPlateCoupon",
    method: "get",
    params: query,
  });
}
// 领取优惠券
export function receiveMemberCouponV3(query) {
  console.log(query);
  return Service({
    url: apiUrl + "/douMeiHui/shop/api/receiveMemberCouponV3",
    method: "post",
    data: query
  });
}
