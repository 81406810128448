var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"outSideCase"},[_c('div',{staticClass:"header"},[_c('ul',{staticClass:"header_content"},[_c('li',{staticClass:"pageSign"},[_c('img',{attrs:{"src":require("../../assets/image/homePage/sign_text.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../assets/image/homePage/sign.png"),"alt":""},on:{"click":function($event){return _vm.ababab(1, 0)}}})]),_vm._m(0),_c('li',{staticClass:"download"},[_vm._m(1),_c('van-icon',{staticClass:"download_sign",attrs:{"name":"back-top"}})],1),_c('li',{staticClass:"aboutUas",style:(_vm.aboutUasUnfold),on:{"click":_vm.changeState}},[_c('div',[_c('p',[_vm._v("关于我们")]),(_vm.aboutState == false)?_c('van-icon',{staticClass:"aboutUas_sign",attrs:{"name":"arrow-down"}}):_c('van-icon',{staticClass:"aboutUas_sign",attrs:{"name":"arrow-up"}}),_c('p',{staticClass:"introduction",on:{"click":function($event){return _vm.ababab(2, 1)}}},[_c('a',{attrs:{"href":"#/companyInfo"}},[_vm._v("公司简介")])]),_vm._m(2)],1)])])]),_c('div',{staticClass:"bodyContent"},[_c('ul',{staticClass:"bodyContent_title"},_vm._l((_vm.list),function(item,index){return _c('li',{class:{ actived: _vm.active == index },on:{"click":function($event){return _vm.change(index)}}},[_c('p',{on:{"click":_vm.test}},[_vm._v(_vm._s(item))])])}),0)]),_vm._m(3),_c('div',{staticClass:"bottomInfo"},[_vm._m(4),_c('ul',{staticClass:"bottomInfo_text1"},[_vm._m(5),_c('li',[_c('router-link',{attrs:{"to":"/companyInfo"}},[_vm._v("公司简介")])],1),_c('li',[_c('router-link',{attrs:{"to":"/privacypolicy"}},[_vm._v("隐私协议")])],1),_c('li',[_vm._v("联系我们")])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"homePage"},[_c('a',{attrs:{"href":"#/"}},[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{attrs:{"href":"https://jw.modewe.com/美都汇商城.apk"}},[_vm._v("下载美都汇")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"introduction"},[_c('a',{attrs:{"href":"#/privacypolicy"}},[_vm._v("隐私协议")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ul',{staticClass:"bodyContent_text1"},[_vm._v(" 公司简介 ")]),_c('ul',{staticClass:"bodyContent_text2"},[_c('li',[_vm._v("美都汇是以经营服装、鞋类、饰品、百货、家居为主的专业性时尚平价商城，总部设立在山东省会济南市。")]),_c('li',[_vm._v("美都汇从2002年第一家门店开业至今，已经发展成立了21家连锁门店，遍布12座地级市，门店单店经营面积从5000平方至20000平方不等，")]),_c('li',[_vm._v("2022年美都汇线上商城同步上线，让顾客购物更便捷，公司从商品采购，物流配送，再到门店运营，全程一条龙管理，")]),_c('li',[_vm._v("同时还培养了数千人的门店运营团队和专业采购团队人才，")]),_c('li',[_vm._v("与全国厂商直接合作，千家厂商直接供货，省去中间差价，对接前沿最新流行趋势。")]),_c('li',[_vm._v("商品品类涵盖男、女、童鞋服、百货、饰品、家居在内的万款商品。")])]),_c('ul',{staticClass:"connection"},[_c('li',{staticClass:"connection_img"},[_c('div',[_c('img',{attrs:{"src":require("../../assets/image/homePage/mdhshop.apk.png"),"alt":""}})])]),_c('li',{staticClass:"connection_number"},[_c('div',{staticClass:"numtitle"},[_vm._v("联系方式")]),_c('div',{staticClass:"num"},[_vm._v("联系电话：18064626163")]),_c('div',{staticClass:"mail"},[_vm._v("邮箱：mdh_appshangjia@126.com")]),_c('div',{staticClass:"location"},[_vm._v("公司总部地址")]),_c('div',{staticClass:"location_contene"},[_c('ul',[_vm._v("济南美都汇电子商务有限公司")]),_c('ul',[_vm._v("山东省济南市天桥区济洛路82号齐鲁鞋城二期商务楼912")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"bottomInfo_single"},[_c('img',{attrs:{"src":require("../../assets/image/homePage/sign_text.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://jw.modewe.com/美都汇商城.apk"}},[_vm._v("下载美都汇")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"bottomInfo_text2"},[_c('li',[_vm._v("www.jw.modewe.com 2021-2023 All Rights Reserved. ")]),_c('li',[_vm._v("营业执照：91370105MA7G4ED826")]),_c('li',[_c('img',{attrs:{"src":require("../../assets/image/homePage/emblem.png"),"alt":""}}),_c('p',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" © 2023-2024 modewe.com 版权所有")]),_vm._v("  "),_c('a',{staticClass:"bottomInfo_text2a",attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index"}},[_vm._v(" 鲁ICP备2022009892号-2 ")])])])
}]

export { render, staticRenderFns }