<template>
  <div class="bg" :style="{ background: moduleColor }">
    <img v-if="this.id == '1691721280803311617'" class="moduleImg2" :src="moduleImg" alt="" />
    <img v-else class="moduleImg1" :src="moduleImg" alt="" />
    <div class="con" v-if="this.id == '1691721280803311617'">
      <div class="countdown" v-if="timestate == 1 && this.id == '1691721280803311617'">
        <van-count-down :time="this.time" format="DD:HH:mm:ss">
          <template #default="timeData">
            <span class="colon">距离结束还有</span>
            <span class="block"><span v-if="timeData.days < 10">0</span>{{ timeData.days }}</span>
            <span class="colon">天</span>
            <span class="block"><span v-if="timeData.hours < 10">0</span> {{ timeData.hours }}</span>
            <span class="colon">:</span>
            <span class="block"><span v-if="timeData.minutes < 10">0</span>{{ timeData.minutes }}</span>
            <span class="colon">:</span>
            <span class="block"><span v-if="timeData.seconds < 10">0</span>{{ timeData.seconds }}</span>
          </template>
        </van-count-down>
      </div>
      <div class="good" v-for="item in goodsList" @click="toGoodInfo(item.id)">
        <img class="good-img" :src="item.thumbnail" alt="">
        <div class="good-info">
          <div class="good-info-top">
            <div class="name">{{ item.prodName }}</div>
            <div class="stock">还剩{{ item.storeCount }}件</div>
          </div>
          <div class="good-info-bom">
            <div class="price"><span class="span1">秒杀价￥</span><span class="span2">{{ item.marketPrice }}</span>
              <p v-if="item.marketPriceOffline - item.marketPrice > 0">￥{{ item.marketPriceOffline }}</p>
            </div>
            <div class="but">立即秒杀</div>
          </div>

        </div>
      </div>
    </div>
    <div class="con" v-else>
      <good_list :list="goodsList" :state="true" @good-click1="toGoodInfo"></good_list>
    </div>
    <div class="loading" @click="getModuleProListByModule">{{ loading }}</div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk"
import { globalData } from "../../utils/config";
import { fatherMoreList, findShopComActivitiModuleById } from '../../utils/api/fatherDayapi'
import good_list from "@/components/good_list.vue";

export default {
  name: 'Secondary',
  components: { good_list },
  data() {
    return {
      loopcontrol: 0,
      loopcontrol_reach: 0,
      globalData: globalData,
      moduleImg: '',//标题
      moduleColor: '',//背景颜色
      goodsList: [],//数组
      pageNo: 1,//请求页数
      loading: '加载更多',//加载更多名字
      open: false,
      id: ''
    };
  },
  created() {

    this.getStyle()
  },
  mounted() {
    this.getModuleProListByModule()

    window.addEventListener('scroll', this.handleScroll);//监听滚动条


  },
  methods: {
    // 滚动到底部时加载更多数据
    handleScroll() {
      if (this.open) {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          console.log(this.loopcontrol_reach);
          if (this.loopcontrol_reach == 0) {
            this.getModuleProListByModule(false)
            this.loopcontrol_reach = 1
          }
        }
      }
    },

    // 获取模块下的商品
    getModuleProListByModule(res = true) {
      var route = this.$route.params
      console.log(route);
      var list = this.goodsList
      var param = {
        activId: route.activiId,
        moduleId: route.id,
        sortGoodsId: route.sortGoodsId,
        pageNo: res ? 1 : this.pageNo,
        pageSize: 10,
      };
      fatherMoreList(param).then(res => {
        console.log(param);
        console.log(res.result, this.goodsList.length);
        if (res.code == 200) {
          this.goodsList = this.goodsList.concat(res.result.records)
          this.pageNo += 1
          this.open = true
          if (res.result.records && res.result.records.length > 0) {
            if (this.pageNo > res.result.pages) {
              this.loading = '没有更多了'
            } else {
              this.loading = '加载更多';
              this.loopcontrol_reach = 0
            }
          }
        }
      });

    },
    // 获取背景颜色和标题
    getStyle() {
      var route = this.$route.params
      console.log(route.id);
      this.id = route.id
      this.time = route.time
      this.timestate = route.timestate
      var param = {
        id: route.id
      }
      console.log(param);
      findShopComActivitiModuleById(param).then(res => {
        console.log(res);
        if (res.result && res.result.records && res.result.records.length > 0) {
          let item = res.result.records[0];
          if (item.moduleImg) {
            let end = item.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            console.log(end[1]);
            if (end && end.length > 1) {
              this.moduleImg = end[1]; //
            }
          }

          console.log(this.moduleImg);
          this.moduleColor = item.moduleColor
        }

      })
    },
    // 调转到小程序详情页
    toGoodInfo(id) {
      var andid = `com.mshop.shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
      var iosid = `ZYProductDetailsVC?Goodsid=${id}`
      var hid = ""
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        console.log(hid);
        if (hid == 2) {
          window.MdhShop.openComActivityDetailPage(andid)
        } else if (hid == 3) {
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: '/pages1/goodInfo/goodInfo?id=' + id,
          });
        }
      }


    },
    back() {
      this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;

  .moduleImg1 {
    width: 100%;
    height: 60px;
    margin-top: 2%;
  }

  .moduleImg2 {
    width: 100%;
    height: 97px;
  }

  .con {
    width: 93.6%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: -20px;

    .good {
      width: 100%;
      height: 118px;
      background: #FFFFFF;
      border-radius: 4px;
      display: flex;
      align-items: center;
      margin-bottom: 4px;
      padding: 0 8px 0 4px;

      &-img {
        width: 110px;
        height: 110px;
        object-fit: cover;
        border-radius: 2px;
        margin-right: 4px;
      }

      &-info {
        width: 69%;
        height: 110px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .name {
          font-size: 13px;
          font-family: PingFangSC-Regular, PingFang SC;
          margin-bottom: 4px;
        }

        .stock {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #FF0022;
        }

        &-bom {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .price {
            display: flex;
            align-items: center;

            .span1 {
              font-size: 12px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FF0022;
              margin-top: 5%;
            }

            .span2 {
              font-size: 20px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #FF0022;
            }

            p {
              margin-top: 4%;
              margin-left: 5px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #AAAAAA;
              text-decoration: line-through
            }
          }

          .but {
            width: 70px;
            height: 25px;
            border-radius: 13px;
            border: 1px solid #FF0022;
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FF0022;
            text-align: center;
            line-height: 25px;
          }
        }
      }

    }

  }

  .loading {
    margin: 0 auto;
    color: #000;
  }

  .countdown {
    width: 100%;
    text-align: center;
    margin-top: -10%;
  }

  .colon {
    display: inline-block;
    margin: 0 3px;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
  }

  .block {
    display: inline-block;
    height: 21px;
    width: 30px;
    color: #fff;
    font-size: 14px;
    text-align: center;
    background-color: #000000;
    border-radius: 2px;
    padding: 0 2px;
    box-sizing: border-box;
  }
}
</style>
  