<template>
    <div>
        <div class="mduloe">
            <div class="goods" v-for="item in list" @click="jump(id, activiId, item.id)">
                <img class="goods_img" :src="item.thumbnail" alt="" />
                <div class="goods_name">{{ item.prodName }}</div>
                <div class="goods_price">
                    <img class="good_bg" v-if="(item.marketPriceOffline - item.marketPrice) > 0"
                        :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="" />
                    <img class="secondary_qianggou" v-if="(item.marketPriceOffline - item.marketPrice) <= 0"
                        src="@/assets/image/fatherDay/secondary_qianggou.png" alt="" />
                    <div class="price_info" v-if="(item.marketPriceOffline - item.marketPrice) > 0">
                        <div class="price_info_top">直降{{ item.marketPriceOffline - item.marketPrice }}元</div>
                        <div class="price_info_bom">
                            <div class="bom_left">
                                <p>￥</p>
                                {{ item.marketPrice }}
                            </div>
                            <div class="bom_right">
                                去抢购<img class="good_jiantou"
                                    :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="price_info" v-if="(item.marketPriceOffline - item.marketPrice) <= 0">
                        <div class="price_rob">
                            <div class="bom_left">
                                <p>￥</p>{{ item.marketPrice }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { globalData } from "@/utils/config";
export default {
    props: ['list', 'roblist', 'id', 'activiId', 'state'],
    data() {
        return {
            globalData: globalData,
        }
    },

    mounted() {
    },
    watch: {
        list: {
            handler(newArray) {
                // 当A页面的array发生变化时，B组件的array也会相应地变化
                this.list = newArray;
                // console.log(this.list);
            },
            immediate: true // 立即执行watch回调
        }
    },
    methods: {
        jump(id, activiId, sortGoodsId) {
            this.$emit('jump-click', id, activiId, sortGoodsId);
        }
    }
};
</script>
<style lang="scss" scoped>
.mduloe {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    // border: 1px solid #fa0606;
}

.goods {
    width: 32.5%;
    position: relative;
    background-color: #ffffff;
    color: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 4px;
    // box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.1);

    // 直降开始
    .goods_img {
        width: 100%;
        height: 120px;
        object-fit:cover;
        border-radius: 4px 4px 0px 0px;
        margin-bottom: 2px;
    }

    &_text {
        width: 22px;
        height: 30px;
        position: absolute;
        top: 2%;
        left: 2%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            position: relative;
            // border: 1px solid #000;
        }

        .burst {
            position: absolute;
            top: 38%;
            font-size: 12px;
            z-index: 1;
        }
    }

    &_name {
        width: 96%;
        font-size: 12px;
        margin-bottom: 2%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333333;
    }

    &_price {
        width: 100%;
        height: 35px;
        // border: 1px solid #000;
        margin-bottom: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .good_bg {
            width: 100%;
            height: 100%;
        }

        .secondary_qianggou {
            width: 98%;
            height: 100%;
        }

        .price_info {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            font-size: 10px;

            &_top {
                margin-left: 22%;
            }

            &_bom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 22%;
                height: 28px;
                padding-right: 5%;
                margin-top: -4%;
                box-sizing: border-box;

                .good_jiantou {
                    width: 8px;
                    height: 8px;
                }
            }

            .bom_left {
                font-size: 16px;

                // border: 1px solid #000;
                p {
                    font-size: 10px;
                    margin-bottom: 3%;
                }

                display: flex;
                align-items: self-end;
            }

            .price_rob {
                font-size: 16px;
                position: absolute;
                bottom: 20%;
                left: 5%;
                display: flex;
                align-items: self-end;
                // border: 1px solid #000;

                p {
                    font-size: 10px;
                    margin-bottom: 3%;
                }

            }
        }
    }

    // 直降结束
}</style>