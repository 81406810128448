<template>
  <div class="box">
    <div class="pages">
      <img class="pages_bgc" :src="pagebgc" />
      <div class="pages_content">
        <!-- 优惠券 -->
        <ul class="couponbox">
          <li class="coupon" v-for="(todo, index) in couponList" :key="todo.id">
            <!-- 立即领取 -->
            <img @click="clickCheckToUsePageHandle(todo, index)" v-if="parseInt(todo.couponLimit) != todo.useCount"
              class="coupon_img" :src="globalData.staticUrl + '/images/zongziDay/coupon_receive.png'" alt="">
            <!-- 立即使用 -->
            <img @click="useCoupon(index)" v-else class="coupon_img"
              :src="globalData.staticUrl + '/images/zongziDay/coupon_use.png'" alt="">
            <div class="coupon_info">
              <div class="info_price">
                <p>￥</p>{{ todo.discountPrice }}
              </div>
              <div class="info_text">满{{ todo.fullPrice }}减{{ todo.discountPrice }}</div>
            </div>
          </li>
        </ul>
        <!-- 旋转模块 端午限定-->
        <img class="title_nav1" :src="this.one.moduleImgnav" alt="" />
        <ul class="boomshop" @click="jump(one.id, one.activiId)">
          <img class="zongzi1" :src="globalData.staticUrl + '/images/zongziDay/zongzi-left.png'" alt="">
          <img class="boomshop_bgc" :src="this.one.moduleImg" />
          <div class="boomshopbox_out">
            <div class="boomshop_listbox" v-for="(todo, index) in one.list" :key="todo.id"
              @click="jump(one.id, one.activiId, todo.id)">
              <li class="boomshop_list boomshop_under" :style="boxunder">
                <img class="list_img" :src="todo.thumbnail" alt="">
                <ul class="boomshop_carthbox">
                  <p>￥</p>
                  <p>{{ todo.marketPrice }}</p>
                </ul>
              </li>
              <li class="boomshop_list boomshop_up" :style="boxup"
                @click="jump(one.id, one.activiId, one.all[index + 7].id)">
                <img class="list_img" :src="one.all[index + 7].thumbnail" alt="">
                <ul class="boomshop_carthbox">
                  <p>￥</p>
                  <p>{{ one.all[index + 7].marketPrice }}</p>
                </ul>
              </li>
            </div>
          </div>
        </ul>
        <!-- 直降模块 -->
        <ul class="dressstyle">
          <img class="dressstyle_bgc" :src="this.two.moduleImg" @click="jump(two.id, two.activiId)">
          <fall_list class="fall_list" :list="two.list" :id="two.id" :activiId="two.activiId" @jump-click="jump">
          </fall_list>
          <li class="dressstyle_btn" @click="anotherPage(1)">
            <img class="dressstyle_btnimg" src="../../assets/image/fatherDay/fastyle_but.png" />
            <p>点击换一批</p>
          </li>
        </ul>
        <!-- 精选品类 会场 -->
        <ul class="boutiqueshop">
          <img class="title_nav2" :src="childLimit" alt="" />
          <img class="zongzi2" :src="globalData.staticUrl + '/images/zongziDay/zongzi_right.png'" alt="">
          <div class="famous_school_item_swiper">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(item, index) in Carousel" :key="index"
                  @click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
                  <img :src="item.cardImg" alt="">
                </div>
              </div>
            </div>
          </div>
        </ul>
        <!-- 抢模块1 -->
        <ul class="hotshop">
          <img class="zongzi3" :src="globalData.staticUrl + '/images/zongziDay/zongzi-left.png'" alt="">
          <img class="hotshop_bgc" :src="this.three.moduleImg" @click="jump(three.id, three.activiId)" />
          <rob_list class="rob_list" :hall="'zongzi'" :list="three.list" :id="three.id" :activiId="three.activiId"
            @jump-click="jump">
          </rob_list>
          <li class="hotshop_btn" @click="anotherPage(2)">
            <p>点击换一批</p>
            <img class="hotshop_btnimg" :src="globalData.staticUrl + '/images/MotherDay/style_but.png'" />
          </li>
        </ul>
        <!-- 二级会场模块1 -->
        <ul class="twohot1" :style="{ height: twohot1H + 'px' }">
          <div v-for="(item, index) in three.comActivitiCards" :key="index" class="twohot_img">
            <img :src="item.cardImg" alt="" @click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
          </div>
        </ul>
        <!-- 抢模块2 -->
        <ul class="hotshop">
          <img class="zongzi4" :src="globalData.staticUrl + '/images/zongziDay/zongzi_right.png'" alt="">
          <img class="hotshop_bgc" :src="this.five.moduleImg" @click="jump(five.id, five.activiId)" />
          <rob_list class="rob_list" :hall="'zongzi'" :list="five.list" :id="five.id" :activiId="five.activiId"
            @jump-click="jump">
          </rob_list>
          <li class="hotshop_btn" @click="anotherPage(3)">
            <p>点击换一批</p>
            <img class="hotshop_btnimg" :src="globalData.staticUrl + '/images/MotherDay/style_but.png'" />
          </li>
        </ul>
        <!-- 二级会场模块2 -->
        <ul class="twohot1" :style="{ height: twohot1H + 'px' }">
          <div v-for="(item, index) in five.comActivitiCards" :key="index" class="twohot_img">
            <img :src="item.cardImg" alt="" @click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
          </div>
        </ul>
        <!-- 抢模块3 -->
        <ul class="hotshop">
          <img class="zongzi3" :src="globalData.staticUrl + '/images/zongziDay/zongzi-left.png'" alt="">
          <img class="hotshop_bgc" :src="this.seven.moduleImg" @click="jump(seven.id, seven.activiId)" />
          <rob_list class="rob_list" :hall="'zongzi'" :list="seven.list" :id="seven.id" :activiId="seven.activiId"
            @jump-click="jump">
          </rob_list>
          <li class="hotshop_btn" @click="anotherPage(4)">
            <p>点击换一批</p>
            <img class="hotshop_btnimg" :src="globalData.staticUrl + '/images/MotherDay/style_but.png'" />
          </li>
        </ul>
        <!-- 二级会场模块3 -->
        <ul class="twohot2" :style="{ height: twohot2H + 'px' }">
          <div v-for="(item, index) in seven.comActivitiCards" :key="index" class="twohot_img">
            <img :src="item.cardImg" alt="" @click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
          </div>
        </ul>
        <!-- 更多商品 -->
        <ul class="moregoods">
          <img class="title_nav3" :src="moreSign" alt="">
          <li class="more_goodslist">
            <div class="more_goodstitle" v-for="(todo, index) in  nine.list " :key="todo.id" @click="change(index)"
              ref="more_goodstitle">
              <p class="more_goodstitletext" :class="{ more_goodstitletext_active: active == index }">{{
                todo.groupName
              }}</p>
            </div>
          </li>
          <li class="more_goods">
            <good_list :list="morelist" :state="'main'" @good-click1="toGoodInfo"></good_list>
          </li>
          <li v-if="pageNo <= nine.list[this.active].prodClsList.pages" class="morelist_text" @click="getMoreList">
            加载更多
          </li>
          <li v-else class="morelist_img">
            <img :src="globalData.staticUrl + '/images/nomore_bgc.png'" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div class="messagePopup" :style="popup" v-if="popup == 'opacity:1'">
      <ul>{{ popuptext }}</ul>
    </div>
    <div class="skeletonbox" v-if="activefa != false && couponfa != false && rotatefa != false">
      <Skt :activeloading="activefa" :couponloading="couponfa" :rotateloading="rotatefa"></Skt>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk"
import { globalData } from "../../utils/config";
import { findCouponByActiv, receiveMemberCouponV4 } from '@/utils/api/api'
import { fatherList, fatherMoreList, fatherCoupon, receiveMemberCouponV3 } from '../../utils/api/fatherDayapi'
import good_list from "@/components/good_list.vue";
import fall_list from "@/components/fall_list.vue";//直降模块
import rob_list from "@/components/rob_list.vue";//抢购模块
import $ from "@/assets/js/jquery-2.1.4"
import Swiper from "@/assets/js/swiper-4.4.1"
import Skt from "@/components/skeleton_screen/zongziDay/zongziDay.vue"

export default {
  data() {
    return {
      loadingState: [false, false, false, false],
      flag: true,
      boxunder: "",
      boxunderCopy: "",
      boxup: "",
      boxupCopy: "",
      active: 0,
      loopcontrol: 0,
      loopcontrol_reach: 0,
      // 图片
      pagebgc: "",
      moreSign: "",
      childLimit: "",
      hotRecommed: "",
      middleClose: "",
      bigClose: "",
      smallClose: "",
      // 状态
      popup: "opacity:0",
      genderstate: true,
      pageNo: 2,
      anopageNo: 1,
      // 数据
      globalData: globalData,
      routerParams: "",
      comActivitiCards: "",
      couponList: [],
      one: {},
      two: {},
      three: {},
      four: {},
      five: {},
      six: {},
      seven: {},
      eight: {},
      nine: { list: [{ prodClsList: { pages: 4 } }] },
      morelist: [],
      popuptext: "",
      shuffledArray: [],
      Carousel: [],//会场数据
      activityID: 0,//活动id
      // 骨架屏
      activefa: true,
      couponfa: true,
      rotatefa: true,
      clientWidth: '',
      twohot1H: document.documentElement.clientWidth * 0.48 * 1.85,//第一二个会场高度
      twohot2H: document.documentElement.clientWidth * 0.48 * 2.45,//第三个个会场高度
      loading: false,
      finished: false,
    };
  },
  components: { good_list, fall_list, rob_list, Skt },
  created() {
    let obj = {}
    let reg = /([^?=&]+)=([^?=&]+)/g
    let data = window.location.href.replace("#/", "");
    data.replace(reg, (...arg) => {
      obj[arg[1]] = arg[2]
    })
    console.log(window.location.href, data, obj);
    if (obj.hid) {
      localStorage.setItem("Userhid", obj.hid);
    } else {
      localStorage.setItem("Userhid", "")
    }
    if (obj.token) {
      localStorage.setItem("Usertoken", obj.token);
    } else {
      localStorage.setItem("Usertoken", "")
    }
    this.clientWidth = document.documentElement.clientWidth//获取屏幕宽度 跑马灯
    this.getBodyAllList()
    window.getUserInfoTokenIos = this.getUserInfoTokenIos
    setInterval(() => {
      this.rotate()
      setTimeout(() => {
        this.rotateback()
      }, 4000)
    }, 9000);

  },
  computed: {},

  mounted() {
    setTimeout(this.carouselList, 500);
    window.addEventListener('scroll', this.onpageScroll);
    window.onload(function () {
      if (window.MdhShop && window.MdhShop instanceof Function) {
        let accessToken = window.MdhShop.webViewBridgeAccessToken(false)
        localStorage.setItem("Usertoken", accessToken)
      }
    })
  },
  updated() {
    if (this.loopcontrol == 0) {
      this.change(0)
      this.loopcontrol = 1
    }
  },
  methods: {
    carouselList() {
      // 跑马灯
      var options = {
        speed: 3000,//匀速时间
        autoplay: {
          delay: 0,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        loop: true,
        loopedSlides: 5,
        slidesPerView: this.clientWidth / 142,
        spaceBetween: 15,
        observer: true,
        observeParents: true, // 当Swiper的父元素变化时，会更新swiper
      }
      $.extend({
        "swiperOption": function (f1, f2) {
          let swiper = new Swiper(f1, f2);
          return swiper;
        },
      });

      $.swiperOption($('.famous_school_item_swiper').eq(0).find('.swiper-container'), options);
    },

    // 更多商品标题选中
    change(index) {
      this.active = index
      this.morelist = this.nine.list[index].prodClsList.records
      this.pageNo = 2
    },
    // 全局数据
    getBodyAllList() {
      fatherList({ activiNum: "112" }).then((res) => {
        if (res.code == 200) {
          console.log('总', JSON.parse(JSON.stringify(res.result)));
          this.pagebgc = res.result.activImage//主图
          this.activefa = false
          this.moreSign = res.result.modules[9].moduleImg//更多
          this.childLimit = res.result.modules[2].moduleImg//会场
          this.Carousel = res.result.modules[2].comActivitiCards  //会场数组
          this.activityID = res.result.id
          if (res.result.modules.length > 0) {
            //旋转模块
            let one = res.result.modules
            this.one = one[0]
            console.log(1, JSON.parse(JSON.stringify(one[0])));
            this.one.all = res.result.modules[0].prodClsList.records
            let param = {
              moduleId: this.one.id,
              pageNo: 1,
              pageSize: 10,
            };
            fatherMoreList(param).then((res) => {
              if (res.code == 200) {
                for (let i = 0; i < res.result.records.length; i++) {
                  this.one.all.push(res.result.records[i])
                }
                this.one.list = this.one.all.splice(0, 4)
                this.nine.list[0].prodClsList.records = res.result.records
                this.morelist = res.result.records
                let end = this.one.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
                this.one.moduleImg = end[0];
                this.one.moduleImgnav = end[2];
                this.rotatefa = false
              }
            });

          }
          if (res.result.modules.length > 1) {
            //  直降模块
            let two = res.result.modules
            this.two = two[1]
            this.two.list = two[1].prodClsList.records.splice(0, 6)
            let end = this.two.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            this.two.moduleImg = end[0];
          }
          if (res.result.modules.length > 2) {
            //时尚女装 抢模块1
            let three = res.result.modules
            this.three = three[3]
            console.log(3, JSON.parse(JSON.stringify(three[3])));
            this.three.list = three[3].prodClsList.records.splice(0, 6)
            this.three.comActivitiCards = three[3].comActivitiCards
            console.log('3会场', JSON.parse(JSON.stringify(this.three.comActivitiCards)));
            let end = this.three.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            this.three.moduleImg = end[0];
          }
          if (res.result.modules.length > 4) {
            // 品质男装 抢模块2
            let five = res.result.modules
            this.five = five[5]
            let end = this.five.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            this.five.moduleImg = end[0];
            this.five.list = five[5].prodClsList.records.splice(0, 6)

            this.five.comActivitiCards = five[5].comActivitiCards
            console.log('5会场', JSON.parse(JSON.stringify(this.five.comActivitiCards)));
          }
          if (res.result.modules.length > 6) {
            //潮流童装 抢模块3
            let seven = res.result.modules
            this.seven = seven[7]
            let end = this.seven.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
            this.seven.moduleImg = end[0];
            this.seven.list = seven[7].prodClsList.records.splice(0, 6)
            this.seven.comActivitiCards = seven[7].comActivitiCards
            console.log('7会场', JSON.parse(JSON.stringify(this.seven.comActivitiCards)));
          }
          if (res.result.modules.length > 9) {
            // 更多会场
            let nine = res.result.modules
            this.nine.list = nine[9].groups
            // 为除推荐外其他分类获取第一页数据
            let param = {
              activId: "1666978281875836929",
              moduleId: "1666983829555908609",
              pageNo: 1,
              pageSize: 10,
            };
            for (let i = 0; i < this.nine.list.length; i++) {
              param.groupId = this.nine.list[i].id
              fatherMoreList(param).then((res) => {
                if (res.code == 200) {
                  this.nine.list[i + 1].prodClsList.records = [].concat(res.result.records)
                }
              });
            }
            // 插入推荐类
            this.nine.list.unshift({
              groupName: "精选",
              id: "",
              prodClsList: {
                pages: 4,
                records: []
              }
            })
            this.nine.list = this.nine.list
          }
        }
      });
      // 优惠券
      this.getCouponList()
    },

    // 更多数据
    getMoreList() {
      console.log(this.nine.list);
      if (this.nine.list) {
        let item = this.nine.list[this.active]
        let param = {
          activId: item.activiId ? item.activiId : "1666978281875836929",
          moduleId: item.moduleId ? item.moduleId : "1666983829555908609",
          groupId: item.id ? item.id : "",
          pageNo: this.pageNo,
          pageSize: 10,
        };
        fatherMoreList(param).then((res) => {
          if (res.code == 200) {
            if (this.morelist == undefined) {
              return
            }
            this.morelist = this.morelist.concat(res.result.records)
            item.prodClsList.pages = res.result.pages
            this.pageNo += 1
          }
          this.loopcontrol_reach = 0
        });
      }
    },
    // 获取优惠券
    getCouponList() {
      let params = {
        activiId: this.activId
      }
      findCouponByActiv(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          console.log('优惠券', res);
          this.couponList = res.result.coupons.slice(0, 3)
          this.couponfa = false
        }
      });
    },

    // 换一批数据
    anotherPage(e) {
      switch (e) {
        case 1:
          this.twoModuleProList(false)
          break;
        case 2:
          this.threeModuleProList(false)
          break;
        case 3:
          this.fiveModuleProList(false)
          break;
        case 4:
          this.sevenModuleProList(false)
          break;
        default:
          console.log("antoher");
      }
    },
    twoModuleProList(reset = true) {
      // 直降模块
      let item = this.two
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNo,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.code == 200) {
          if (res.result.records && res.result.records.length >= 0) {
            this.two.list = [].concat(res.result.records)
            this.two.pages = res.result.pages
            this.two.pageNo = res.result.current
            console.log(1, this.two.list);
            if (res.result.records.length < 6) {
              this.anopageNo = 2
              param.pageNo = 1
              fatherMoreList(param).then((res) => {
                this.two.list = [].concat(res.result.records)
                console.log(2, this.two.list);
                this.$forceUpdate();
              })
              return
            }
            this.anopageNo += 1
            this.$forceUpdate();
          } else if (reset) {
            this.two.list = []
            this.two.pages = 1
            this.two.pageNo = 1
          }
        }
      });
    },
    threeModuleProList(reset = true) {
      // 抢模块1
      let item = this.three
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNo,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.code == 200) {
          console.log(res.result.records);
          if (res.result.records && res.result.records.length >= 0) {
            this.three.list = [].concat(res.result.records)
            this.three.pages = res.result.pages
            this.three.pageNo = res.result.current
            console.log(1, res.result.records);
            if (res.result.records.length < 6) {
              this.anopageNo = 2
              param.pageNo = 1
              fatherMoreList(param).then((res) => {
                this.three.list = [].concat(res.result.records)
                console.log(2, this.three.list);
                this.$forceUpdate();
              })
              return
            }
            this.anopageNo += 1
            this.$forceUpdate();
          } else if (reset) {
            this.three.list = []
            this.three.pages = 1
            this.three.pageNo = 1
          }
        }
      });
    },
    fiveModuleProList(reset = true) {
      // 抢模块1
      let item = this.five
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNo,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.code == 200) {
          console.log(res.result.records);
          if (res.result.records && res.result.records.length >= 0) {
            this.five.list = [].concat(res.result.records)
            this.five.pages = res.result.pages
            this.five.pageNo = res.result.current
            console.log(1, res.result.records);
            if (res.result.records.length < 6) {
              this.anopageNo = 2
              param.pageNo = 1
              fatherMoreList(param).then((res) => {
                this.five.list = [].concat(res.result.records)
                console.log(2, this.five.list);
                this.$forceUpdate();
              })
              return
            }
            this.anopageNo += 1
            this.$forceUpdate();
          } else if (reset) {
            this.five.list = []
            this.five.pages = 1
            this.five.pageNo = 1
          }
        }
      });
    },
    sevenModuleProList(reset = true) {
      // 抢模块3
      let item = this.seven
      let param = {
        activId: item.activiId,
        moduleId: item.id,
        pageNo: this.anopageNo,
        pageSize: 6,
      };
      fatherMoreList(param).then((res) => {
        if (res.code == 200) {
          if (res.result.records && res.result.records.length >= 0) {
            this.seven.list = [].concat(res.result.records)
            this.seven.pages = res.result.pages
            this.seven.pageNo = res.result.current
            if (res.result.records.length < 6) {
              this.anopageNo = 2
              param.pageNo = 1
              fatherMoreList(param).then((res) => {
                this.seven.list = [].concat(res.result.records)
                this.$forceUpdate();
              })
              return
            }
            this.anopageNo += 1
            this.$forceUpdate();
          } else if (reset) {
            this.seven.list = []
            this.seven.pages = 1
            this.seven.pageNo = 1
          }
        }
      });
    },

    // 监听页面滚动
    onpageScroll() {
      // 存储触底条件相关数据
      let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        // 兼容初次调用
        if (this.loopcontrol == 2) {
          this.getMoreList()
          this.loopcontrol = 3
          return

        }
        // 后续调用
        if (this.loopcontrol_reach == 0) {
          console.log(1);
          this.getMoreList()
          this.loopcontrol_reach = 1
        }
      }
    },
    // 旋转
    rotate() {
      setTimeout(() => {
        this.boxunder = 'transform:rotateY(180deg)'
        this.boxup = 'transform:rotateY(0deg)'
      }, 2000)
      setTimeout(() => {
        this.boxunderCopy = 'transform:rotateY(180deg)'
        this.boxupCopy = 'transform:rotateY(0deg)'
      }, 4000)
    },
    // 反向旋转
    rotateback() {
      setTimeout(() => {
        this.boxunder = 'transform:rotateY(0deg)'
        this.boxup = 'transform:rotateY(-180deg)'
      }, 2000)

      setTimeout(() => {
        this.boxunderCopy = 'transform:rotateY(0deg)'
        this.boxupCopy = 'transform:rotateY(-180deg)'
      }, 4000)
    },
    // 跳转
    jump(id, activiId, sortGoodsId) {
      console.log(id, activiId, sortGoodsId);
      this.$router.push({
        name: 'sendzongziDay',
        params: { id: id, activiId: activiId, sortGoodsId: sortGoodsId }
      });
    },
    // 点击领取优惠券
    clickCheckToUsePageHandle(item, index) {
      if (item) {
        let data = {
          activId: item.activId ? item.activId : null,
          activName: item.activName ? item.activName : null,
          fromTable: item.fromTable,
          couponId: item.id,
        }
        this.couponRquest = data
        let clientType = localStorage.getItem('Userhid')
        alert(clientType)
        if (!clientType) {
          alert("未找到设备参数")
        }
        let accessToken = ''
        if (clientType == '1') {
          accessToken = "1";
          if (!localStorage.getItem('Usertoken')) {
            wx.miniProgram.redirectTo({
              appId: 'wx5b714f2180ea9ea0',
              url: '/pages/login/index?fakey=1'
            });
          }
          // 安卓
        } else if (clientType == '2') {
          accessToken = "2";
          if (!localStorage.getItem('Usertoken')) {
            accessToken = this.webViewBridgeAccessToken()
          }
          // iOS
        } else if (clientType == '3') {
          accessToken = "3";
          if (localStorage.getItem('Usertoken') == "X1") {
            this.getUserInfoToken(index);
          }
        }
        data.token = localStorage.getItem('Usertoken')
        if (accessToken) {
          receiveMemberCouponV4(data).then((res) => {
            console.log(res);
            if (res.code == 200) {
              this.touchPopup(1)
              this.getCouponList()

            } else {
              this.touchPopup(2)
              this.getCouponList()
            }
          });
        }
      }
    },
    // 立即使用优惠券
    useCoupon(index) {
      let item = this.couponList[index];
      if (item) {
        console.log(item);
        wx.miniProgram.navigateTo({
          appId: 'wx5b714f2180ea9ea0',
          url: '/pages2/childDay/coupon?id=' + item.activCouponId
        });
      }
    },
    // 跳转商品页
    toGoodInfo(id) {
      console.log(id);
      var hid = ""
      var andid = `com.mshop.shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
      var iosid = `ZYProductDetailsVC?${id}`
      console.log(andid);
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        if (hid == 2) {
          // 安卓
          window.MdhShop.openComActivityDetailPage(andid)
        } else if (hid == 3) {
          // ios
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: '/pages1/goodInfo/goodInfo?id=' + id,
          });
        }
      }
    },
    // 三端跳转会场
    toJingangUrlPage(wxurl, andUrl, iosUrl) {
      console.log(wxurl, andUrl, iosUrl);
      console.log(localStorage.getItem('Userhid'));
      var hid = ""
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        if (hid == 2) {
          // 安卓
          window.MdhShop.openComActivityDetailPage(andUrl)
        } else if (hid == 3) {
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosUrl);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: wxurl,
          });
        }
      }
    },
    // 弹出层
    touchPopup(e) {
      if (e == 1) {
        this.$toast.success('领取成功');
      } else {
        this.$toast.fail('领取失败,请重试');
      }
    },
    // 安卓端处理Token(获取存储)
    webViewBridgeAccessToken() {
      let accessToken = localStorage.getItem('Usertoken')
      alert(accessToken)
      if (!accessToken) {
        accessToken = window.MdhShop.webViewBridgeAccessToken(true)
        localStorage.setItem("Usertoken", accessToken)
        this.couponRquest.token = localStorage.getItem("Usertoken")
        receiveMemberCouponV4(this.couponRquest).then((res) => {
          alert(this.couponRquest)
          if (res.code == 200) {
            this.touchPopup(1)
            this.getCouponList()

          } else {
            this.touchPopup(2)
            this.getCouponList()
          }
        });
      }
      return accessToken
    },
    // iOS端处理Token(获取存储)
    getUserInfoToken(index) {
      alert("five")
      accessToken = window.webkit.messageHandlers.getUserInfoToken.postMessage(this.couponList[index]);
      return accessToken
    },
    getUserInfoTokenIos(data) {
      localStorage.setItem("Usertoken", data)
      receiveMemberCouponV4(this.couponRquest).then((res) => {
        alert(this.couponRquest)
        if (res.code == 200) {
          this.touchPopup(1)
          this.getCouponList()

        } else {
          this.touchPopup(2)
          this.getCouponList()
        }
      });
    }
  }

};
</script>

<style lang="scss" scoped>
@import '@/assets/css/swiper-4.4.1.css';

.coupon_topcarth,
.coupon_toplimit li,
.coupon_botom ul:nth-child(1),
.boomshop_carthbox,
.more_goodstitle,
.morecontentl_sign,
.morecontentl_carth {
  font-family: PingFangSC-Medium, PingFang SC;
}

/* --------------------------------- */
.box {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  justify-content: center;
}


.pages {
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: PingFangSC-Medium, PingFang SC;
}

.pages_bgc {
  width: 100%;
  height: 346px;
  display: block;
}

.pages_content {
  margin-top: -0.5%;
  padding: 0 12px 0 12px;
  background: #51B176;
}

// 模块单独标题
.title_nav1 {
  width: 70.4%;
  height: 23px;
  margin-left: 14.8%;
}

.title_nav2 {
  width: 82.7%;
  height: 22px;
  margin-left: 8.65%;
  margin-bottom: 12px;
}

.title_nav3 {
  width: 91.5%;
  height: 41px;
  margin-left: 4.25%;
}

/* 优惠券开始 */
.couponbox {
  width: 100%;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
}

.coupon {
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  width: 32%;

  &_img {
    width: 100%;
    height: 105px;
  }

  &_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    color: #FFFFFF;

    .info_price {
      display: flex;
      align-items: flex-end;
      font-size: 50px;

      p {
        font-size: 18px;
        margin-bottom: 10%;
      }
    }

    .info_text {
      margin-top: -9%;
      font-size: 10px;
    }
  }
}

/* 优惠券结束 */
/* 旋转模块开始 */
.boomshop {
  width: 100%;
  height: 355px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 8px;

}

.zongzi1 {
  width: 28px;
  height: 60px;
  position: absolute;
  top: -15%;
  right: -12px;
  z-index: 1;
}

/* 旋转与抢模块图片 */
.boomshop_bgc,
.hotshop_bgc {
  height: 100%;
  width: 100%;
  z-index: 1;

}

.boomshopbox_out {
  position: absolute;
  top: 8%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  z-index: 1;
}

.boomshop_listbox {
  margin-bottom: 1%;
  height: 159px;
  width: 32%;
  perspective: 1500;
  -webkit-perspective: 1500;
  -moz-perspective: 1500;
}

.boomshop_listbox:nth-child(1) {
  margin-left: 68%;

}

.boomshop_list {
  width: 100%;
  transition: all 2s;
  backface-visibility: hidden;
  border-radius: 8px;
  overflow: hidden;
  transform-style: preserve-3d;
  perspective: 800px;
}

.boomshop_under {
  background-color: white;
}

.boomshop_up {
  margin-top: -159px;
  background-color: white;
  position: relative;
  z-index: -1;
  transform: rotateY(180deg) translateZ(1px);

}


.boomshop_list .list_img {
  object-fit: cover;
  width: 100%;
  height: 129px;
  border-radius: 4px 4px 0 0;

}

.boomshop_carthbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3%;
  width: 100%;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}

.boomshop_carthbox {
  width: 100%;
  top: -4px;
  box-shadow: inset 0 -8px 18px 0 #FF9F23;
}

.boomshop_carthbox p:nth-child(1) {
  margin-top: 3px;
}

.boomshop_carthbox p:nth-child(2) {
  font-size: 22px;
}

/* 旋转模块结束 */
/* 会场开始 */
.boutiqueshop {
  position: relative;
}

.zongzi2 {
  position: absolute;
  top: -7.8%;
  left: -12px;
  width: 38px;
  height: 65px;
}

.famous_school_item_swiper {
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.famous_school_item_swiper .swiper-container {
  width: 100%;
  height: 100%;
}

.famous_school_item_swiper .swiper-wrapper {
  -webkit-transition-timing-function: linear;
  /*之前是ease-out*/
  -moz-transition-timing-function: linear;
  -ms-transition-timing-function: linear;
  -o-transition-timing-function: linear;
  transition-timing-function: linear;
}

.famous_school_item_swiper .swiper-slide {
  height: 178px;
}

.famous_school_item_swiper .swiper-slide img {
  width: 132px;
  height: 100%;
}


/* 会场模块结束 */

/* rob抢模块 */
.hotshop {
  width: 100%;
  height: 435px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  margin-top: 12px;
}

.zongzi3 {
  position: absolute;
  right: -12px;
  top: -3%;
  width: 28px;
  height: 60px;
}

.zongzi4 {
  position: absolute;
  left: -12px;
  top: -5%;
  width: 38px;
  height: 65px;
}

.rob_list {
  width: 95%;
  position: absolute;
  top: 12.5%;
  z-index: 1;
}

.hotshop_btn {
  position: absolute;
  top: 89.5%;
  left: 10%;
  width: 80%;
  height: 36px;
  background: rgba(255, 255, 255, 0.6);
  box-shadow: inset 0px 0px 12px 0px #FFFFFF;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  z-index: 1;
  color: #333333;
}

.hotshop_btn p {
  margin-right: 5px;
}

/* rob抢模块结束 */
/* 直降模块开始 */
.dressstyle {
  width: 100%;
  height: 455px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;

}

.dressstyle_bgc {
  width: 100%;
  height: 100%;
}

.fall_list {
  width: 96%;
  position: absolute;
  top: 10%;
}


.dressstyle_btnimg,
.hotshop_btnimg {
  width: 18px;
  height: 16px;
}

.dressstyle_btn {
  position: absolute;
  top: 90.5%;
  left: 10%;
  width: 80%;
  height: 36px;
  background: #B8CB5F;
  box-shadow: inset 0px 0px 12px 0px #FFFFFF;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}


/* 直降模块结束 */

/* 更多商品开始*/

.more_goodslist {
  padding-top: 3%;
  padding-right: 12px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #51B176;
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
  height: 10px;
}

.more_goodstitle {
  font-weight: 400;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}


.more_goodstitletext {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 98px;
  height: 34px;
  font-size: 16px;
  color: #fff;
}

.more_goodstitletext_active {
  color: #333333;
  background: #FFE559;
  box-shadow: inset 0px 1px 8px 0px rgba(255, 255, 255, 0.5);
  border: 1px solid #FFFFFF;
  border-radius: 17px;
}

.more_goods {
  width: 100%;
}


.morelist_text {
  width: 100%;
  text-align: center;
  font-size: 16px;
  color: rgb(58, 57, 57);
  padding: 5px 0;
  cursor: pointer;
}


.morelist_img {
  text-align: center;
  padding: 10px 0 10px 0px;
}

.more_titleimg {
  margin-top: 9px;
}

/* 更多商品结束 */
.messagePopup {
  width: 48%;
  height: 25%;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  position: fixed;
  top: 20%;
  z-index: 999999;
  transition: all .4s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.messagePopup img {
  width: 20%;
  height: 20%;
}

.messagePopup ul {
  color: white;
  font-size: 18px;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.messagePopupbtn {
  width: 15%;
  height: 15%;
  position: fixed;
  top: 20%;
}

// 二级会场
.twohot1,
.twohot2 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}


.twohot_img {
  width: 48.5%;
  border-radius: 4px;
  margin-bottom: 0.5%;
}

.twohot_img img {
  width: 100%;
  height: 100%;
}

.skeletonbox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 500vh;
  background-color: white;
}
</style>
