<template>
    <div>
        <!-- 首页背景 -->
        <div class="body_content">
            <img src="../../assets/image/homePage/home_bgc.png" alt="">
            <ul class="body_contentdownbox">
                <a href="https://jw.modewe.com/美都汇商城.apk">
                    <ul class="body_contentdown">
                        <p>立即下载</p>
                        <img src="../../assets/image/homePage/down.png" alt="">
                    </ul>
                </a>
            </ul>
        </div>
        <!-- 介绍部分 -->
        <div class="shop_content">
            <ul class="text_1">欢迎来到美都汇商城</ul>
            <ul class="text_2">优惠商品天天抢，选购便捷，七天无理由退换</ul>
            <ul class="shop_Introduction">
                <img src="../../assets/image/homePage/home.png" alt="">
                <img src="../../assets/image/homePage/home_conference .png" alt="">
                <img src="../../assets/image/homePage/charts.png" alt="">
                <img src="../../assets/image/homePage/details.png" alt="">
            </ul>
        </div>
        <!-- 下载部分 -->
        <div class="body_download">
            <ul class="body_downloadcont">根据您的手机，选择下载</ul>
            <ul class="body_downloadoperate">
                <li class="downClassify_ios">
                    <img src="../../assets/image/homePage/iSO.png" alt="">
                    <a href="https://apps.apple.com/cn/app/id1660493522"><img
                            src="../../assets/image/homePage/iSO_active.png" alt="" class="active"></a>
                    <p>iPhone</p>
                </li>
                <li class="downClassify_ad">
                    <img src="../../assets/image/homePage/Android.png" alt="">
                    <a href="https://jw.modewe.com/美都汇商城.apk"> <img src="../../assets/image/homePage/Android_active.png"
                            alt="" class="active"></a>

                    <p>Android</p>
                </li>
                <li class="downClassify">
                    <img src="../../assets/image/homePage/mdhshop.apk.png" alt="">
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            aboutState: false,
            aboutUasUnfold: ""
        };
    },
    methods: {
        changeState() {
            this.aboutState == false ? this.aboutState = true : this.aboutState = false
            this.aboutState == false ? this.aboutUasUnfold = "height:100%" : this.aboutUasUnfold = "height:240%"
        }
    },
};
</script>
  
<style scoped>
* {
    user-select: none;
}

header p {
    width: 100%;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}

.homePage p:hover {
    color: #FF0E3E;
}

.download:hover .download_sign,
.aboutUas:hover .aboutUas_sign {
    color: #FF0E3E;
}

.introduction:hover,
.protocols:hover {
    color: #FF0E3E;
}

.header {

    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.header_content {
    height: 50%;
    width: 28%;

    display: flex;
    justify-content: center;
}

.pageSign,
.homePage,
.download {
    width: 25%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.aboutUas {
    width: 25%;
    height: 100%;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s;
    background-color: white;
}

.pageSign img {
    height: 100%;
}

.download_sign {
    font-size: 18px;
    transform: rotate(180deg);
}



.aboutUas p {
    margin-top: 14px;
}

.aboutUas_sign {
    margin-top: 15px;
    position: absolute;
    top: 0;
    left: 82%;
}

.aboutUas_sign,
.download_sign {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* body样式 */
.body_content,
.shop_content,
.body_download {
    height: 1080px;
    position: relative;
    overflow: hidden;
}

.body_content img {
    position: relative;
    height: 100%;
}

.body_contentdownbox {
    position: absolute;
    top: 884px;
    width: 100%;
}

.body_contentdown {
    margin-left: 1015px;
    width: 366px;
    height: 96px;
    background: #FFFFFF;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.body_contentdown p {
    width: 240px;
    height: 84px;
    font-size: 60px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #FF0E3E;
    line-height: 84px;
}

.body_contentdown img {
    width: 46px;
    height: 49px;
    margin-left: 20px;
}

/* 商品页 */
.text_1 {
    margin: 0 auto;
    width: 324px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 188px;
}

.text_2 {
    margin: 0 auto;
    width: 480px;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 33px;
    margin-top: 22px;
}

.shop_Introduction {
    margin-top: 100px;

    display: flex;
    justify-content: center;
}

.shop_Introduction img {
    width: 230px;
    height: 498px;
}

.shop_Introduction img:nth-child(2) {
    margin: 0 10px 0 10px;
}

.shop_Introduction img:nth-child(3) {
    margin-right: 10px;
}

.body_downloadcont {
    margin: 0 auto;
    width: 396px;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 200px;
}

.body_downloadoperate {

    margin-top: 200px;
    display: flex;
    justify-content: center;
}

.body_downloadoperate li:nth-child(2) {
    margin: 0 10px 0 10px;
}

.downClassify_ad,
.downClassify_ios,
.downClassify {
    width: 290px;
    height: 290px;
    border: 1px solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    position: relative;
}

.downClassify_ios {
    margin-left: 12px;
}

.downClassify_ad:hover,
.downClassify_ios:hover {
    background-color: #FF0E3E;
    box-shadow: 5px 15px 30px rgba(0, 0, 0, .3);
}

.downClassify_ad:hover p,
.downClassify_ios:hover p {
    color: white;
}

.downClassify_ad:hover .active,
.downClassify_ios:hover .active {
    display: block;
}

.downClassify_ios img {
    width: 60px;
    height: 74px;
}

.downClassify_ad img {
    width: 60px;
    height: 72px;
}

.downClassify img {
    width: 180px;
    height: 180px;
}

.body_downloadoperate p {
    width: 52px;
    height: 25px;
    font-size: 18px;
    font-family: STKaitiSC-Regular, STKaitiSC;
    font-weight: 400;
    color: #777777;
    line-height: 25px;
    margin-top: 20px;
}


.downClassify_ad p {
    margin-right: 14px;
}

.active {
    position: absolute;
    top: 86px;
    left: 115px;
    display: none;
}

.bottomInfo {
    height: 400px;
    background: rgba(239, 239, 239, 0.77);
}

/* 底部信息 */
.bottomInfo_text1 {
    margin: 0 auto;
    width: 26%;
}

.bottomInfo_text1 li {
    width: 80px;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 22px;

}

.bottomInfo_text2 {
    margin: 0 auto;
    margin-top: 68px;
    width: 357px;
    height: 72px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bottomInfo_text2 img {
    width: 15px;
    height: 14px;
}

.bottomInfo_text2 li:nth-child(3) {
    display: flex;
    align-items: center;
}
</style>
