<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
document.addEventListener('touchstart', function (event) {
  if (event.touches.length > 1) {
    event.preventDefault()
  }
}, {
  capture: false,
  passive: false
});


export default {
  data() {
    return {};
  },
  methods: {
  },
  created() {
    // 禁止页面缩放
    window.onload = function () {
      var lastTouchEnd = 0;
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      });
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault()
      })
      // 禁用双击放大
      document.documentElement.addEventListener('touchend', function (event) {
        var now = Date.now();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, {
        passive: false
      });
    };
  },
};
</script>

<style >
* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none
}
</style>
