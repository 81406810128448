<template>
	<div class="box">
		<vanpopup :textpopup="textpopup" :showpopup="showpopup" @home-click="goHome"></vanpopup>
		<div class="pages" v-if="!showpopup">
			<img class="pages_bgc" :style="pages_bgcHe" :src="pagebgc" />
			<div class="pages_content">
				<!-- 优惠券 -->
				<ul class="couponbox" v-for="(todo, index) in couponList" :key="todo.id">
					<img class="couponboxbg" src="@/assets/image/qixi/coupon.png" alt="">
					<div class="coupon_info">
						<div class="couinfo_left">
							<span>￥</span>
							{{ todo.discountPrice }}
							<span>元</span>
						</div>
						<div class="couinfo_right">
							<div class="right_info">
								<p>{{ todo.name }}</p>
								<p>限七夕活动商品使用</p>
							</div>
							<div class="right_but" @click="clickCheckToUsePageHandle(todo, index)"
								v-if="parseInt(todo.couponLimit) != todo.useCount">立即领取</div>
							<div class="right_but" @click="useCoupon(index)" v-else>立即使用</div>
						</div>
					</div>
				</ul>
				<!-- 旋转模块 -->
				<ul class="boomshop" v-if="boomshopst != 1">
					<img class="boomshop_bgc" :src="moduleImg"
						@click="toJingangUrlPage(onecomActivitiCards[0].wxUrl, onecomActivitiCards[0].andUrl, onecomActivitiCards[0].iosUrl)" />
					<van-count-down v-if="timestate == 1" :time="time" class="countdown" format="DD:HH:mm:ss">
						<template #default="timeData">
							<span class="colon">距离结束还有</span>
							<span class="block"><span v-if="timeData.days < 10">0</span>{{ timeData.days }}</span>
							<span class="colon">天</span>
							<span class="block"><span v-if="timeData.hours < 10">0</span> {{ timeData.hours }}</span>
							<span class="colon">:</span>
							<span class="block"><span v-if="timeData.minutes < 10">0</span>{{ timeData.minutes }}</span>
							<span class="colon">:</span>
							<span class="block"><span v-if="timeData.seconds < 10">0</span>{{ timeData.seconds }}</span>
						</template>
					</van-count-down>
					<div class="boomshopbox_out">
						<div class="boomshop_listbox" v-for="(todo, index) in one.listUnder" :key="todo.goodsId">
							<li class="boomshop_list boomshop_under" :style="boxunder" @click="toGoodInfo(todo.goodsId)">
								<img class="burst" src="@/assets/image/qixi/burst.png" alt="">
								<img class="list_img" :src="todo.goodsLogo" alt="">
								<div class="boomshop_listcarth">
									<img class="roblabel" src="@/assets/image/qixi/roblabel.png" alt="">
									<div class="roblabel_info">
										<p><span>￥</span>{{ todo.marketPrice }}</p>
										<p v-if="todo.marketPriceOffline - todo.marketPrice > 0">￥{{
											todo.marketPriceOffline }}</p>
									</div>
								</div>
							</li>
							<li class="boomshop_list boomshop_up" :style="boxup"
								@click="toGoodInfo(one.list[index].goodsId)">
								<img class="burst" src="@/assets/image/qixi/burst.png" alt="">
								<img class="list_img" :src="one.list[index].goodsLogo" alt="">
								<div class="boomshop_listcarth">
									<img class="roblabel" src="@/assets/image/qixi/roblabel.png" alt="">
									<div class="roblabel_info">
										<p><span>￥</span>{{ one.list[index].marketPrice }}</p>
										<p v-if="one.list[index].marketPriceOffline - one.list[index].marketPrice > 0">
											￥{{ one.list[index].marketPriceOffline }}</p>
									</div>
								</div>
							</li>
						</div>
					</div>
				</ul>
				<img class="biaoti" :src="childLimit" alt="">
				<!-- 跑马灯 热销品类 -->
				<ul class="boutiqueshop">
					<div class="famous_school_item_swiper">
						<div class="swiper-container">
							<div class="swiper-wrapper">
								<div class="swiper-slide" v-for="(item, index) in boomshop" :key="index"
									@click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
									<img :src="item.cardImg" alt="">
								</div>
							</div>
						</div>
					</div>
				</ul>
				<!-- 精选会场 -->
				<img class="biaoti2" :src="selectedtags" alt="">
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.two.moduleImg" @click="jump(two.id, two.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.two.list" :key="todo.id"
							@click="jump(two.id, two.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb1">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.three.moduleImg" @click="jump(three.id, three.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.three.list" :key="todo.id"
							@click="jump(three.id, three.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb2">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.four.moduleImg" @click="jump(four.id, four.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.four.list" :key="todo.id"
							@click="jump(four.id, four.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb3">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.five.moduleImg" @click="jump(five.id, five.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.five.list" :key="todo.id"
							@click="jump(five.id, five.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb4">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.six.moduleImg" @click="jump(six.id, six.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.six.list" :key="todo.id"
							@click="jump(six.id, six.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb5">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<ul class="hotshop" :style="hotshopHe">
					<img class="hotshop_bgc" :src="this.seven.moduleImg" @click="jump(seven.id, seven.activiId)" />
					<div class="hotshop_listbox" :style="hotshop_listboxHe">
						<li class="hotshop_list" :style="hotshop_listboxHe" v-for="todo in this.seven.list" :key="todo.id"
							@click="jump(seven.id, seven.activiId, todo.id)">
							<img class="list_img" :style="list_imgHe" :src="todo.thumbnail" alt="">
							<div class="hotshop_listcarth hotshop_leb6">
								<ul class="hotshop_carthbox">
									<p>￥</p>
									<p>{{ todo.marketPrice }}</p>
								</ul>
								<img class="qixi_go" src="@/assets/image/qixi/qixi_go.png" alt="">
							</div>
						</li>
					</div>
				</ul>
				<!-- 金刚区男女童大分类 -->
				<img class="biaoti3" :src="moreSign" alt="">
				<ul class="pattern">
					<img v-for="(item, index) in this.eight.comActivitiCards" :key="index"
						@click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)" class="pattern-img"
						:src="item.cardImg" alt="">
				</ul>
				<img class="placeholder" src="@/assets/image/qixi/placeholder.png" alt="">
			</div>
		</div>
		<div class="skeletonbox" v-if="activefa != false && couponfa != false && rotatefa != false">
			<Skt :activeloading="activefa" :couponloading="couponfa" :rotateloading="rotatefa"></Skt>
		</div>
	</div>
</template>
  
<script>
import wx from "weixin-js-sdk"
import { globalData } from "@/utils/config";
import { fatherList, fatherMoreList, fatherCoupon, receiveMemberCouponV3 } from '@/utils/api/fatherDayapi'
import { findCouponByActiv, receiveMemberCouponV4, getIndexActiveList, } from '@/utils/api/api'
import good_list from "@/components/good_list.vue";
import vanpopup from "@/components/vanpopup.vue";
import Skt from "@/components/skeleton_screen/fatherDay/fatherDay.vue"
import $ from "@/assets/js/jquery-2.1.4"
import Swiper from "@/assets/js/swiper-4.4.1"
export default {
	data() {
		return {
			showpopup: false,//没有活动弹出层
			textpopup: '',//弹出层提示
			loadingState: [false, false, false, false],
			boxunder: "",
			boxunderCopy: "",
			boxup: "",
			boxupCopy: "",
			active: 0,
			loopcontrol: 0,
			loopcontrol_reach: 0,
			// 图片
			pagebgc: "",
			moreSign: "",//花样专区
			childLimit: "",//热销品类
			selectedtags: '',//精选会场
			hotRecommed: "",
			middleClose: "",
			bigClose: "",
			smallClose: "",
			// 状态
			popup: "opacity:0",
			activefa: true,
			couponfa: true,
			rotatefa: true,
			genderstate: true,
			pageNo: 2,
			anopageNo: 1,
			anopageNofive: 1,
			anopageNosix: 1,
			anopageNoseven: 1,
			// 数据
			couponRquest: {},
			activId: "",
			globalData: globalData,
			routerParams: "",
			comActivitiCards: "",
			couponList: [],
			one: {},
			two: {},
			three: {},
			four: {},
			five: {},
			six: {},
			seven: {},
			eight: {},
			morelist: [],
			boomshop: '',
			popuptext: "",
			pages_bgcHe: 'height: 503px;',
			hotshop_listboxHe: 'height: 136px;',
			list_imgHe: 'height: 110px;',
			hotshopHe: 'height: 180px;',
			time: '',
			timestate: 2,
			moduleImg: '',
			onecomActivitiCards: '',//限时抢购金刚区
			boomshopst: 0//判断旋转
		};
	},
	components: { good_list, Skt, vanpopup },
	created() {
		let obj = {}
		let reg = /([^?=&]+)=([^?=&]+)/g
		let data = window.location.href.replace("#/", "");
		data.replace(reg, (...arg) => {
			obj[arg[1]] = arg[2]
		})
		console.log(window.location.href, data, obj);
		if (obj.hid) {
			localStorage.setItem("Userhid", obj.hid);
		} else {
			localStorage.setItem("Userhid", "")
		}
		if (obj.token) {
			localStorage.setItem("Usertoken", obj.token);
		} else {
			localStorage.setItem("Usertoken", "")
		}
		this.getBodyAllList()
		this.clientWidth = document.documentElement.clientWidth//获取屏幕宽度 跑马灯
		if (this.clientWidth > 400) {
			this.pages_bgcHe = 'height: 550px;'
			this.hotshop_listboxHe = 'height: 150px;'
			this.list_imgHe = 'height: 120px;'
			this.hotshopHe = 'height: 195px;'
		}
		window.getUserInfoTokenIos = this.getUserInfoTokenIos
		setInterval(() => {
			this.rotate()
			setTimeout(() => {
				this.rotateback()
			}, 4000)
		}, 9000)
	},
	mounted() {
		window.addEventListener('scroll', this.onpageScroll);
		setTimeout(this.carouselList, 500);//跑马灯
	},
	methods: {
		carouselList() {
			// 跑马灯
			var options = {
				speed: 3000,//匀速时间
				autoplay: {
					delay: 0,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
				loop: true,
				loopedSlides: 9,
				slidesPerView: this.clientWidth / 110,
				spaceBetween: 15,//间隔
				observer: true,
				observeParents: true, // 当Swiper的父元素变化时，会更新swiper
			}

			$.extend({
				"swiperOption": function (f1, f2) {
					let swiper = new Swiper(f1, f2);
					return swiper;
				},
			});

			$.swiperOption($('.famous_school_item_swiper').eq(0).find('.swiper-container'), options);
		},
		// 更多商品标题选中
		change(index) {
			this.active = index
			this.morelist = this.eight.list[index].prodClsList.records
			this.loopcontrol += 1
			this.pageNo = 1
		},
		// 全局数据
		getBodyAllList() {
			fatherList({ activiNum: "113" }).then((res) => {
				if (res.code == 500) {
					this.showpopup = true
					this.textpopup = res.message
				}
				console.log(res);
				if (res.code == 200) {
					this.showpopup = false
					console.log('总数据', JSON.parse(JSON.stringify(res)));
					this.pagebgc = res.result.activImage//主图
					this.activefa = false
					this.moreSign = res.result.modules[8].moduleImg//花样专区
					this.childLimit = res.result.modules[1].moduleImg//热销品类
					this.boomshop = res.result.modules[1].comActivitiCards//会场数组跑马灯
					this.activId = res.result.id//活动id
					var activEnd = res.result.activEnd//结束日期
					var enddata = new Date(activEnd);
					var date = new Date();
					var startdata = date.toLocaleDateString()//当前日期
					let myDate_1 = Date.parse(enddata)
					let myDate_2 = res.timestamp//开始日期
					// 将两个日期都转换为毫秒格式，然后做差
					let diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值
					if (Date.parse(startdata) >= Date.parse(res.result.activStart)) {
						this.timestate = 1
						this.time = diffDate
					} else {
						this.timestate = 2
					}
					if (res.result.modules.length > 0) {
						//翻转模块
						let one = res.result.modules
						console.log(111, JSON.parse(JSON.stringify(one[0])));
						this.onecomActivitiCards = one[0].comActivitiCards
						console.log(this.activId);
						getIndexActiveList({ activId: '1692423504047116289' }).then((res) => {
							console.log(111, res);
							this.one = res.result.records[0]
							console.log(this.one);
							if (res.result.records.length > 0) {
								let all = this.one.goodList
								console.log('all', all);
								this.one.list = all.splice(0, 6)
								this.one.listUnder = this.one.list.splice(0, 3)
								console.log('listUnder', this.one.listUnder);
							} else {
								this.boomshopst = 1
							}
						})
						let end = one[0].moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
						this.moduleImg = end[0];
						this.rotatefa = false
					}
				}
				if (res.code == 200) {
					// 女青少小衫
					if (res.result.modules.length > 2) {
						let two = res.result.modules[2]
						this.two = two
						this.two.list = two.prodClsList.records.splice(0, 2)
						console.log(this.two.moduleImg);
						if (this.two.moduleImg.indexOf(",") != -1) {
							let end = this.two.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.two.moduleImg = end[0];
							this.selectedtags = end[2]// 精选会场标签
						}
					}
				}
				if (res.code == 200) {
					// 男装外套
					if (res.result.modules.length > 3) {
						let three = res.result.modules[3]
						this.three = three
						this.three.list = three.prodClsList.records.splice(0, 2)
						if (this.three.moduleImg.indexOf(",") != -1) {
							let end = this.three.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.three.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 女中年毛衫
					if (res.result.modules.length > 4) {
						let four = res.result.modules[4]
						this.four = four
						this.four.list = four.prodClsList.records.splice(0, 2)
						if (this.four.moduleImg.indexOf(",") != -1) {
							let end = this.four.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.four.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 男士休闲鞋
					if (res.result.modules.length > 5) {
						let five = res.result.modules[5]
						this.five = five
						this.five.list = five.prodClsList.records.splice(0, 2)
						if (this.five.moduleImg.indexOf(",") != -1) {
							let end = this.five.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.five.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 女士休闲鞋
					if (res.result.modules.length > 6) {
						let six = res.result.modules[6]
						this.six = six
						this.six.list = six.prodClsList.records.splice(0, 2)
						if (this.six.moduleImg.indexOf(",") != -1) {
							let end = this.six.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.six.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 童鞋会场
					if (res.result.modules.length > 7) {
						let seven = res.result.modules[7]
						this.seven = seven
						this.seven.list = seven.prodClsList.records.splice(0, 2)
						if (this.seven.moduleImg.indexOf(",") != -1) {
							let end = this.seven.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.seven.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					//花样专区
					if (res.result.modules.length > 8) {
						let eight = res.result.modules[8]
						this.eight = eight
						this.eight.comActivitiCards = eight.comActivitiCards
						this.eight.moduleImg = eight.moduleImg
					}
				}
				// 优惠券
				this.getCouponList()
			})
		},
		// 获取优惠券
		getCouponList() {
			let params = {
				activiId: this.activId
			}
			findCouponByActiv(params).then((res) => {
				if (res.code == 200) {
					console.log('优惠券', JSON.parse(JSON.stringify(res)));
					this.couponList = res.result.coupons
					this.couponfa = false
				}
			});
		},
		// 监听页面滚动
		onpageScroll() {
			// 存储触底条件相关数据
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
			let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
			if (scrollTop + clientHeight + 1 >= scrollHeight) {
				// 后续调用
				if (this.loopcontrol_reach == 0) {
					this.loopcontrol_reach = 1
				}
			}
		},
		// 旋转
		rotate() {
			setTimeout(() => {
				this.boxunder = 'transform:rotateY(180deg)'
				this.boxup = 'transform:rotateY(0deg)'
			}, 2000)
			setTimeout(() => {
				this.boxunderCopy = 'transform:rotateY(180deg)'
				this.boxupCopy = 'transform:rotateY(0deg)'
			}, 4000)
		},
		// 反向旋转
		rotateback() {
			setTimeout(() => {
				this.boxunder = 'transform:rotateY(0deg)'
				this.boxup = 'transform:rotateY(-180deg)'
			}, 2000)
			setTimeout(() => {
				this.boxunderCopy = 'transform:rotateY(0deg)'
				this.boxupCopy = 'transform:rotateY(-180deg)'
			}, 4000)
		},
		// 跳转
		jump(id, activiId, sortGoodsId) {
			console.log(id, activiId, sortGoodsId);
			this.$router.push({
				name: 'sendQixiDay',
				params: { id: id, activiId: activiId, sortGoodsId: sortGoodsId, time: this.time, timestate: this.timestate }
			});
		},
		// 点击领取优惠券
		clickCheckToUsePageHandle(item, index) {
			if (item) {
				let data = {
					activId: item.activId ? item.activId : null,
					activName: item.activName ? item.activName : null,
					fromTable: item.fromTable,
					couponId: item.id,
				}
				this.couponRquest = data
				let clientType = localStorage.getItem('Userhid')
				let accessToken = ''
				if (clientType == '1') {
					accessToken = "1";
					if (!localStorage.getItem('Usertoken')) {
						wx.miniProgram.redirectTo({
							appId: 'wx5b714f2180ea9ea0',
							url: '/pages/login/index?fakey=1'
						});
					}
					// 安卓
				} else if (clientType == '2') {
					accessToken = "2";
					if (!localStorage.getItem('Usertoken')) {
						accessToken = this.webViewBridgeAccessToken()
					}
					// iOS
				} else if (clientType == '3') {
					accessToken = "3";
					if (localStorage.getItem('Usertoken') == "X1") {
						this.getUserInfoToken(index);
					}
				}
				data.token = localStorage.getItem('Usertoken')
				if (accessToken) {
					receiveMemberCouponV4(data).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.touchPopup(1)
							this.getCouponList()

						} else {
							this.touchPopup(2)
							this.getCouponList()
						}
					});
				}
			}
		},
		// 立即使用优惠券
		useCoupon(index) {

			let item = this.couponList[index];
			if (item) {
				console.log(item);
				wx.miniProgram.navigateTo({
					appId: 'wx5b714f2180ea9ea0',
					url: '/pages2/childDay/coupon?id=' + item.activCouponId
				});
			}
			this.getUserInfoToken(index);
		},
		// 跳转商品详情页
		toGoodInfo(id) {
			console.log(id);
			var hid = ""
			var andid = `shopcart.ui.fragment.ShopLargeFragment?id=${id}`
			var iosid = `ZYProductDetailsVC?${id}`
			console.log(andid);
			if (localStorage.getItem('Userhid')) {
				hid = localStorage.getItem('Userhid');
				if (hid == 2) {
					// 安卓
					window.MdhShop.openComActivityDetailPage(andid)
				} else if (hid == 3) {
					// ios
					window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
				} else {
					wx.miniProgram.navigateTo({
						appId: 'wx5b714f2180ea9ea0',
						url: '/pages1/goodInfo/goodInfo?id=' + id,
					});
				}
			}
		},
		// 跳转首页
		goHome() {
			console.log(1);
			wx.miniProgram.switchTab({
				appId: 'wx5b714f2180ea9ea0',
				url: '/pages/index/index',
			});
		},
		// 三端跳转会场
		toJingangUrlPage(wxurl, andUrl, iosUrl) {
			console.log(wxurl, andUrl, iosUrl);
			console.log(localStorage.getItem('Userhid'));
			var hid = ""
			if (localStorage.getItem('Userhid')) {
				hid = localStorage.getItem('Userhid');
				if (hid == 2) {
					// 安卓
					window.MdhShop.openComActivityDetailPage(andUrl)
				} else if (hid == 3) {
					window.webkit.messageHandlers.openProductDetailPage.postMessage(iosUrl);
				} else {
					wx.miniProgram.navigateTo({
						appId: 'wx5b714f2180ea9ea0',
						url: wxurl,
					});
				}
			}
		},
		// 弹出层
		touchPopup(e) {
			if (e == 1) {
				this.$toast.success('领取成功');
			} else {
				this.$toast.fail('领取失败,请重试');
			}
		},
		// 安卓端处理Token(获取存储)
		webViewBridgeAccessToken() {
			let accessToken = localStorage.getItem('Usertoken')
			alert(accessToken)
			if (!accessToken) {
				accessToken = window.MdhShop.webViewBridgeAccessToken(true)
				localStorage.setItem("Usertoken", accessToken)
				this.couponRquest.token = localStorage.getItem("Usertoken")
				receiveMemberCouponV4(this.couponRquest).then((res) => {
					alert(this.couponRquest)
					if (res.code == 200) {
						this.touchPopup(1)
						this.getCouponList()

					} else {
						this.touchPopup(2)
						this.getCouponList()
					}
				});
			}
			return accessToken
		},
		// iOS端处理Token(获取存储)
		getUserInfoToken(index) {
			accessToken = window.webkit.messageHandlers.getUserInfoToken.postMessage(this.couponList[index]);
			return accessToken
		},
		getUserInfoTokenIos(data) {
			localStorage.setItem("Usertoken", data)
			receiveMemberCouponV4(this.couponRquest).then((res) => {
				alert(this.couponRquest)
				if (res.code == 200) {
					this.touchPopup(1)
					this.getCouponList()

				} else {
					this.touchPopup(2)
					this.getCouponList()
				}
			});
		}
	}
};
</script>
  
<style scoped>
/* image边框 */

.coupon_topcarth,
.coupon_toplimit li,
.coupon_botom ul:nth-child(1),
.boomshop_carthbox,
.hotshop_carthbox,
.more_goodstitle,
.dressstyle_gotext,
.upcarth ul,
.morecontentl_sign,
.morecontentl_carth,
.goodssign_box p {
	font-family: PingFangSC-Medium, PingFang SC;
}

/* --------------------------------- */
.box {
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	justify-content: center;

}


.pages {
	padding: 0;
	margin: 0;
	width: 100%;
	height: auto;
}

.pages_bgc {
	width: 100%;
	height: 503px;
	display: block;
}

.pages_content {
	margin-top: -45%;
	padding: 0 3.2%;
	background: #FFCCD9;
	display: flex;
	flex-direction: column;
}

/* 优惠券开始 */
.couponbox {
	width: 100%;
	height: 96px;
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	margin-bottom: 3px;
	position: relative;
}

.couponboxbg {
	width: 100%;
	height: 100%;
}

.coupon_info {
	position: absolute;
	top: -2%;
	width: 100%;
	height: 96px;
	display: flex;
	align-content: center;
}

.couinfo_left {
	width: 34%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 48px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FF1B5C;
}

.couinfo_left span:nth-child(1) {
	font-size: 12px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FF1B5C;
	line-height: 17px;
	margin-top: 22%;
}

.couinfo_left span:nth-child(2) {
	width: 18px;
	height: 18px;
	border-radius: 50%;
	font-size: 10px;
	background: #FF1B5C;
	color: #FFFFFF;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 16%;
}

.couinfo_right {
	width: 59%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-left: 3%;
}

.right_info p:nth-child(1) {
	height: 22px;
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #000000;
	line-height: 22px;
	margin-bottom: 8px;
}

.right_info p:nth-child(2) {
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #777777;
	line-height: 17px;
}

.right_but {
	width: 72px;
	height: 25px;
	background: linear-gradient(180deg, #FFFFFF 0%, #FFCDDB 100%);
	box-shadow: 0px 2px 4px 0px rgba(255, 134, 170, 0.63);
	border-radius: 13px;
	font-size: 12px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FF1B5C;
	display: flex;
	align-items: center;
	justify-content: center;
}

/* 优惠券结束 */

.boomshop {
	width: 100%;
	height: 216px;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 8px;

}

/* 倒计时 */
.countdown {
	position: absolute;
	top: 17%;
}


.colon {
	display: inline-block;
	margin: 0 3px;
	color: #000000;
	font-weight: bold;
	font-size: 14px;
}

.block {
	display: inline-block;
	height: 21px;
	width: 30px;
	color: #fff;
	font-size: 14px;
	text-align: center;
	background-color: #000000;
	border-radius: 2px;
	padding: 0 2px;
	box-sizing: border-box;
}

/* 倒计时结束 */
/* 翻转模块 */
.boomshop_bgc,
.hotshop_bgc {
	height: 100%;
	width: 100%;

}

.boomshopbox_out {
	position: absolute;
	top: 30%;
	width: 95%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 auto;

}

.boomshop_listbox {
	margin-bottom: 1%;
	height: 146px;
	width: 32%;
	perspective: 1500;
	-webkit-perspective: 1500;
	-moz-perspective: 1500;
}

.boomshop_list {
	width: 100%;
	height: 146px;
	transition: all 2s;
	backface-visibility: hidden;
	border-radius: 8px;
	overflow: hidden;
	transform-style: preserve-3d;
	perspective: 800px;
	position: relative;
}

.burst {
	position: absolute;
	top: 2px;
	left: 2px;
	width: 23px;
	height: 16px;

}

.boomshop_under {
	background-color: white;
}

.boomshop_up {
	margin-top: -146px;
	background-color: white;
	position: relative;
	z-index: -1;
	transform: rotateY(180deg) translateZ(1px);

}

.boomshop_listcarth {
	width: 100%;
	position: relative;
}

.roblabel {
	width: 100%;
	height: 100%;
}

.roblabel_info {
	position: absolute;
	top: 5%;
	left: 13px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.roblabel_info p:nth-child(1) {
	color: #FFFFFF;
	font-size: 14px;
	margin-right: 4px;
}

.roblabel_info p:nth-child(1) span {
	font-size: 8px;
}

.roblabel_info p:nth-child(2) {
	color: #FFFFFF;
	font-size: 10px;
	text-decoration: line-through
}

.roblabel_info p:nth-child(2) span {
	font-size: 6px;
}

/* 翻转模块结束 */
.hotshop_list {
	background-color: #FFFFFF;
}

.boomshop_list .list_img,
.hotshop_list .list_img {
	object-fit: cover;
	width: 100%;
	border-radius: 4px;

}

.boomshop_list .list_img {
	height: 110px;
}

.boomshop_carthbox {
	width: 100%;
	top: -4px;
}

/* 标题 */
.biaoti {
	height: 45px;
	width: 201px;
	margin: 0 auto;
}

.biaoti2 {
	height: 45px;
	width: 273px;
	margin: 0 auto;
}

.biaoti3 {
	height: 45px;
	width: 215px;
	margin: 0 auto;
}

/* 精选会场 */
.hotshop {
	width: 100%;
	height: 177px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	margin-bottom: 8px;
}


.hotshop_listbox {
	width: 64%;
	position: absolute;
	top: 20%;
	right: 3px;
	display: flex;
	align-items: center;
	justify-content: space-around;
	flex-wrap: wrap;
}

.hotshop_list {
	border-radius: 4px;
	width: 48%;
	height: 136px;
}

.hotshop_listcarth {
	width: 100%;
	height: 26px;
	border-radius: 0px 0px 4px 4px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.qixi_go {
	width: 32px;
	height: 16px;
	margin-right: 6px;
}

.hotshop_carthbox {
	display: flex;
	align-items: flex-end;
	margin-left: 12px;
}

.hotshop_carthbox p:nth-child(1) {
	font-size: 10px;
	margin-bottom: 2px;
}

.hotshop_carthbox p:nth-child(2) {
	font-size: 18px;
}

.hotshop_leb1 {
	background: linear-gradient(180deg, #FFFFFF 0%, #FFD0D2 100%);
}

.hotshop_leb2 {
	background: linear-gradient(180deg, #FFFFFF 0%, #CBD9FF 100%);
}

.hotshop_leb3 {
	background: linear-gradient(180deg, #FFFFFF 0%, #FFD0BA 100%);
}

.hotshop_leb4 {
	background: linear-gradient(180deg, #FFFFFF 0%, #E5D2FF 100%);
}

.hotshop_leb5 {
	background: linear-gradient(180deg, #FFFFFF 0%, #FFC6DF 100%);
}

.hotshop_leb6 {
	background: linear-gradient(180deg, #FFFFFF 0%, #CBE4B2 100%);
}

/* 精选会场结束 */

.skeletonbox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500vh;
	background-color: white;
}

/* 跑马灯会场开始 */
.boutiqueshop {
	position: relative;
	margin-bottom: 8px;
	height: 125px;
}

.famous_school_item_swiper {
	width: 100%;
	height: 120px;
	overflow: hidden;
}

.famous_school_item_swiper .swiper-container {
	width: 100%;
	height: 100%;
}

.famous_school_item_swiper .swiper-wrapper {
	-webkit-transition-timing-function: linear;
	/*之前是ease-out*/
	-moz-transition-timing-function: linear;
	-ms-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}

.famous_school_item_swiper .swiper-slide {
	height: 120px;
}

.famous_school_item_swiper .swiper-slide img {
	width: 100px;
	height: 100%;
}


/* 跑马灯会场模块结束 */
/* 男女童大类开始 */
.pattern-img:first-child {
	width: 49%;
	float: left;
}

.pattern-img:nth-child(2),
.pattern-img:nth-child(3) {
	margin-bottom: 4px;
	width: 49%;
	float: right;
}

.placeholder {
	width: 100px;
	height: 24px;
	margin: 20px auto;
}
</style>
  