<template>
	<div class="box">
		<vanpopup :textpopup="textpopup" :showpopup="showpopup" @home-click="goHome"></vanpopup>
		<div class="pages" v-if="!showpopup">
			<img class="pages_bgc" :style="pages_bgcHe" :src="pagebgc" />
			<div class="pages_content">
				<!-- 优惠券 -->
				<ul class="couponbox_outside" v-if="couponList.length > 0">
					<img class="couponbox_bgc" src="../../assets/image/nationary/nationcoupon_bgc_.png" alt="">
					<li class="couponcase" v-for="(todo, index) in couponList" :key="todo.id">
						<div class="coupon_dicount">
							<ul>￥</ul>
							<ul>{{ todo.discountPrice }}</ul>
						</div>
						<div class="coupon_rules">{{ todo.name }}</div>
						<div class="coupon_btn" @click="clickCheckToUsePageHandle(todo, index)"
							v-if="parseInt(todo.couponLimit) != todo.useCount">立即领取</div>
						<div class="coupon_btn coupon_btnuse" @click="useCoupon(index)"
							v-if="parseInt(todo.couponLimit) == todo.useCount && todo.useCount != todo.usedCount">立即使用</div>
						<div class="coupon_btn coupon_btnused" @click="useCoupon(index)"
							v-if="parseInt(todo.couponLimit) == todo.useCount && todo.useCount == todo.usedCount">已领完</div>
					</li>
				</ul>
				<!-- 主推爆款模块（旋转模块） -->
				<ul class="boomshop" v-if="boomshopState == true">
					<img class="boomshop_bgc" :src="moduleImg1"
						@click="toJingangUrlPage(onecomActivitiCards[0].wxUrl, onecomActivitiCards[0].andUrl, onecomActivitiCards[0].iosUrl)" />
					<div class="boomshopbox_out">
						<div class="boomshop_listbox" v-for="(todo, index) in one.listunder" :key="todo.goodsId">
							<li class="boomshop_list boomshop_under" :style="boxunder" @click="toGoodInfo(todo.goodsId)">
								<div class="boomshop_contentcase">
									<img class="boomshop_photo" :src="todo.goodsLogo" alt="" style="object-fit: cover;">
									<div class="boomshop_text">{{ todo.goodsName }}</div>
									<img class="boomshop_trends" src="../../assets/image/nationary/nation_policy.png"
										alt="">
									<div class="boomshop_btn">
										<div class="boomshop_btn_sign">￥</div>
										<div class="boomshop_btn_carth">{{ todo.marketPrice }}</div>
									</div>
								</div>
							</li>
							<li class="boomshop_list boomshop_up" :style="boxup"
								@click="toGoodInfo(one.list[index].goodsId)">
								<div class="boomshop_contentcase">
									<img class="boomshop_photo" :src="one.list[index].goodsLogo" alt=""
										style="object-fit: cover;">
									<div class="boomshop_text">{{ one.list[index].goodsName }}</div>
									<img class="boomshop_trends" src="../../assets/image/nationary/nation_policy.png"
										alt="">
									<div class="boomshop_btn">
										<div class="boomshop_btn_sign">￥</div>
										<div class="boomshop_btn_carth">{{ one.list[index].marketPrice }}</div>
									</div>
								</div>
							</li>
						</div>
					</div>
				</ul>
				<!-- 热销品类 -->
				<img class="biaoti" :src="biaoti1_img" alt="">
				<ul class="boutiqueshop" v-if="boutiqueshopState == true">
					<div class="boutiqueshop_cont" v-for="(todo, index) in  two.list" :key="todo.id"
						@click="toJingangUrlPage(todo.wxUrl, todo.andUrl, todo.iosUrl)">
						<img class="boutiqueshop_img" :src="todo.cardImg" alt="">
					</div>
				</ul>
				<!-- 活动专区 -->
				<!-- 1.爆款直降(跑马灯) -->
				<img class="biaoti2" :src="biaoti2_img" alt="">
				<ul class="hotshop" v-if="hotshopState == true">
					<img class="hotshop_bgc" :src="three.moduleImg" @click="jump(three.id, three.activiId)" />
					<div class="hotshop_listbox">
						<div class="famous_school_item_swiper">
							<div class="swiper-container">
								<div class="swiper-wrapper">
									<div class="swiper-slide" v-for="( item, index ) in three.list" :key="item.id"
										@click="jump(three.id, three.activiId, item.id)">
										<img class="hotshop_photo" :src="item.thumbnail" alt="" style="object-fit: cover;">
										<div class="hotshop_text">{{ item.prodName }}</div>
										<div class="hotshop_carth">
											<img class="hotshop_carthsign"
												src="../../assets/image/nationary/nation_fall.png" alt="">
											<ul class="hotshop_carth_top">
												<li v-if="item.marketPriceOffline != item.marketPrice">
													￥{{ item.marketPriceOffline }}
												</li>
												<li v-else></li>
												<div>
													<li>￥</li>
													<li>{{ item.marketPrice }}</li>
												</div>
											</ul>
											<ul class="hotshop_carth_bootm"
												v-if="item.marketPriceOffline - item.marketPrice != 0">
												直降{{ item.marketPriceOffline - item.marketPrice }}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</ul>
				<!-- 2.满减专区 -->
				<ul class="fullreduction" v-if="fullreductionState == true">
					<li class="fullreduction_title">
						<img :src="four.moduleImg" alt="" @click="jump(four.id, four.activiId)" style="object-fit: cover">
					</li>
					<li class="fullreduction_case" v-for="( todo, index ) in  four.list" :key="todo.id"
						@click="jump(four.id, four.activiId, todo.id)">
						<div class="fullreduction_left">
							<img class="fullreduction_left_photo" :src="todo.thumbnail" alt="" style="object-fit: cover;">
							<img class="fullreduction_left_sign" src="../../assets/image/nationary/fullreduction_sign.png"
								alt="">
						</div>
						<div class="fullreduction_right">
							<ul class="fullreduction_right_text">{{ todo.prodName }}</ul>
							<ul class="fullreduction_right_btn">
								<img class="right_btnbgc" src="../../assets/image/nationary/nation_reduce.png" alt="">
								<li class="right_carthtext">专场活动价</li>
								<li class="right_carthcase">
									<div class="sign">￥</div>
									<div class="carth">{{ todo.marketPrice }}</div>
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<!-- 3.两件88折 -->
				<ul class="discount" v-if="discountState == true">
					<img class="discount_bgc" :src="five.moduleImg" alt="" @click="jump(five.id, five.activiId)">
					<li class="discount_contentcase" v-for="( todo, index ) in  five.list " :key="todo.id"
						@click="jump(five.id, five.activiId, todo.id)">
						<img class="discount_photo" :src="todo.thumbnail" alt="" style="object-fit: cover;">
						<div class="discount_text">{{ todo.prodName }}</div>
						<img class="discount_trends" src="../../assets/image/nationary/nation_policy.png" alt="">
						<div class="discount_btn">
							<ul class="discount_btnsign">￥</ul>
							<ul class="discount_btncarth">{{ todo.marketPrice }}</ul>
						</div>
					</li>
				</ul>
				<!-- 精选会场 -->
				<img class="biaoti3" :src="biaoti3_img" alt="">
				<ul class="pattern" v-if="patternState == true">
					<div class="pattern_case" v-for="( todo, index ) in  six.list " :key="todo.id"
						@click="toJingangUrlPage(todo.wxUrl, todo.andUrl, todo.iosUrl)">
						<img :src="todo.cardImg" alt="">
					</div>
				</ul>
				<!-- 品质专区 -->
				<img class="biaoti4" :src="biaoti4_img" alt="">
				<ul class="pinzhiactive_outside">
					<li class="pinzhiactive" v-for="( todo, index ) in  seven.list " :key="todo.id">
						<img class="pinzhiactive_bgc" :src="todo.moduleImg" alt="">
						<div class="pinzhiactive_contentcases">
							<img class="pinzhiactive_conttitle" src="../../assets/image/nationary/pinzhiactive_title.png"
								alt="">
							<ul class="pinzhiactive_cont">
								<li class="pinzhiactive_infocase" v-for="( item, index ) in  todo.comActivitiCards"
									:key="item.id" @click="toJingangUrlPage(item.wxUrl, item.andUrl, item.iosUrl)">
									<img :src="item.cardImg" alt="">
								</li>
							</ul>
						</div>
					</li>
				</ul>
				<img class="placeholder" src="@/assets/image/qixi/placeholder.png" alt="">
			</div>
		</div>
		<div class="skeletonbox" v-if="activefa != false && couponfa != false && rotatefa != false">
			<Skt :activeloading="activefa" :couponloading="couponfa" :rotateloading="rotatefa"></Skt>
		</div>
	</div>
</template>
  
<script>
import wx from "weixin-js-sdk"
import { globalData } from "@/utils/config";
import { fatherList, fatherMoreList, fatherCoupon, receiveMemberCouponV3 } from '@/utils/api/fatherDayapi'
import { findCouponByActiv, receiveMemberCouponV4, getIndexActiveList, } from '@/utils/api/api'
import good_list from "@/components/good_list.vue";
import vanpopup from "@/components/vanpopup.vue";
import Skt from "@/components/skeleton_screen/fatherDay/fatherDay.vue"
import $ from "@/assets/js/jquery-2.1.4"
import Swiper from "@/assets/js/swiper-4.4.1"
export default {
	data() {
		return {
			showpopup: false,//没有活动弹出层
			textpopup: '',//弹出层提示
			loadingState: [false, false, false, false],
			boxunder: "",
			boxunderCopy: "",
			boxup: "",
			boxupCopy: "",
			active: 0,
			loopcontrol: 0,
			loopcontrol_reach: 0,
			// 图片
			pagebgc: "",
			biaoti1_img: "",
			biaoti2_img: "",
			biaoti3_img: "",
			biaoti4_img: "",
			hotRecommed: "",
			middleClose: "",
			bigClose: "",
			smallClose: "",
			// 状态
			popup: "opacity:0",
			activefa: true,
			couponfa: true,
			rotatefa: true,
			genderstate: true,
			pageNo: 2,
			boomshopState: false,
			boutiqueshopState: false,
			hotshopState: false,
			fullreductionState: false,
			discountState: false,
			patternState: false,
			// 数据
			couponRquest: {},
			activId: "",
			globalData: globalData,
			routerParams: "",
			comActivitiCards: "",
			couponList: [],
			one: {},
			two: {},
			three: {},
			four: {},
			five: {},
			six: {},
			seven: {},
			eight: {},
			morelist: [],
			boomshop: '',
			popuptext: "",
			pages_bgcHe: 'height: 423px;',
			hotshop_listboxHe: 'height: 136px;',
			list_imgHe: 'height: 110px;',
			hotshopHe: 'height: 180px;',
			time: '',
			timestate: 2,
			moduleImg1: '',
			onecomActivitiCards: '',//主推爆款
			boomshopst: 0,//判断旋转
			cuoponloop: 1
		};
	},
	components: { good_list, Skt, vanpopup },
	created() {
		let obj = {}
		let reg = /([^?=&]+)=([^?=&]+)/g
		let data = window.location.href.replace("#/", "");
		data.replace(reg, (...arg) => {
			obj[arg[1]] = arg[2]
		})
		console.log(window.location.href, data, obj);
		if (obj.hid) {
			localStorage.setItem("Userhid", obj.hid);
		} else {
			localStorage.setItem("Userhid", "")
		}
		if (obj.token) {
			localStorage.setItem("Usertoken", obj.token);
		} else {
			localStorage.setItem("Usertoken", "")
		}
		this.getBodyAllList()
		this.clientWidth = document.documentElement.clientWidth//获取屏幕宽度 跑马灯
		if (this.clientWidth > 400) {
			this.pages_bgcHe = 'height: 550px;'
			this.hotshop_listboxHe = 'height: 150px;'
			this.list_imgHe = 'height: 120px;'
			this.hotshopHe = 'height: 195px;'
		}
		window.getUserInfoTokenIos = this.getUserInfoTokenIos
		setInterval(() => {
			this.rotate()
			setTimeout(() => {
				this.rotateback()
			}, 4000)
		}, 9000)
	},
	mounted() {
		window.addEventListener('scroll', this.onpageScroll);
		setTimeout(this.carouselList, 500);//跑马灯
	},
	methods: {
		carouselList() {
			// 跑马灯
			var options = {
				speed: 3000,//匀速时间
				autoplay: {
					delay: 0,
					stopOnLastSlide: false,
					disableOnInteraction: false,
				},
				loop: true,
				loopedSlides: 9,
				slidesPerView: this.clientWidth / 180,
				spaceBetween: 8,//间隔
				observer: true,
				observeParents: true, // 当Swiper的父元素变化时，会更新swiper
			}

			$.extend({
				"swiperOption": function (f1, f2) {
					let swiper = new Swiper(f1, f2);
					return swiper;
				},
			});

			$.swiperOption($('.famous_school_item_swiper').eq(0).find('.swiper-container'), options);
		},
		// 更多商品标题选中
		change(index) {
			this.active = index
			this.morelist = this.eight.list[index].prodClsList.records
			this.loopcontrol += 1
			this.pageNo = 1
		},
		// 全局数据
		getBodyAllList() {
			fatherList({ activiNum: "114" }).then((res) => {
				if (res.code == 500) {
					this.showpopup = true
					this.textpopup = res.message
				}
				if (res.code == 200) {
					this.showpopup = false
					console.log('总数据', JSON.parse(JSON.stringify(res)));
					this.pagebgc = res.result.activImage//主图
					this.moduleImg1 = res.result.modules[0].moduleImg//主推爆款背景
					this.activefa = false
					this.activId = res.result.id//活动id	
					if (res.result.modules.length > 0) {
						//翻转模块
						this.onecomActivitiCards = res.result.modules[0].comActivitiCards//会场跳转地址
						getIndexActiveList({ activId: '1702216037304373249' }).then((res) => {
							if (res.code == 200) {
								if (res.result.records[0].goodList.length >= 6) {
									this.one.all = res.result.records[0].goodList.splice(0, 6)//全部
									this.one.list = JSON.parse(JSON.stringify(this.one.all))//渲染正面
									this.one.listunder = this.one.list.splice(0, 3)//渲染反面
									this.boomshopState = true
								} else {
									console.log('主推爆款数据不足');
								}
							}
							console.log(111, this.one);
						})
						this.rotatefa = false
					}
				}
				if (res.code == 200) {
					// 热销品类
					if (res.result.modules.length > 1) {
						this.biaoti1_img = res.result.modules[1].moduleImg
						if (res.result.modules[1].comActivitiCards.length > 1) {
							let two = res.result.modules[1]
							this.two = two
							this.two.list = two.comActivitiCards
							this.twocomActivitiCards = res.result.modules[1].comActivitiCards//会场跳转地址
							this.boutiqueshopState = true
						}
					}
				}
				if (res.code == 200) {
					// 活动专区 1.爆款直降
					if (res.result.modules.length > 2) {
						let three = res.result.modules[2]
						this.three = three
						if (three.prodClsList.records.length > 0) {
							this.three.list = three.prodClsList.records
							this.hotshopState = true
						}
						if (this.three.moduleImg.indexOf(",") != -1) {
							let end = this.three.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.three.moduleImg = end[0];
							this.biaoti2_img = end[2];
						}
					}
				}
				if (res.code == 200) {
					// 活动专区 2.满减专区
					if (res.result.modules.length > 3) {
						let four = res.result.modules[3]
						this.four = four
						if (four.prodClsList.records.length > 0 && four.prodClsList.records.length <= 3) {
							this.four.list = four.prodClsList.records
							this.fullreductionState = true
						}
						if (four.prodClsList.records.length > 3) {
							this.four.list = four.prodClsList.records.splice(0, 3)
							this.fullreductionState = true
						}
						if (this.four.moduleImg.indexOf(",") != -1) {
							let end = this.four.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.four.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 活动专区 3.两件88折
					if (res.result.modules.length > 4) {
						let five = res.result.modules[4]
						this.five = five
						if (five.prodClsList.records.length > 0 && five.prodClsList.records.length <= 4) {
							this.five.list = five.prodClsList.records
							this.discountState = true
						}
						if (five.prodClsList.records.length > 4) {
							this.five.list = five.prodClsList.records.splice(0, 4)
							this.discountState = true
						}
						if (this.five.moduleImg.indexOf(",") != -1) {
							let end = this.five.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.five.moduleImg = end[0];
						}
					}
				}
				if (res.code == 200) {
					// 精选会场
					if (res.result.modules.length > 5) {
						let six = res.result.modules[5]
						this.six = six
						if (six.comActivitiCards.length > 0) {
							this.six.list = six.comActivitiCards
							this.patternState = true
						}
						this.threecomActivitiCards = res.result.modules[5].comActivitiCards//会场跳转地址
						if (this.six.moduleImg.indexOf(",") != -1) {
							let end = this.six.moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
							this.biaoti3_img = end[0]
							this.biaoti4_img = end[1]
						}
					}
				}
				if (res.code == 200) {
					// 品质专区
					if (res.result.modules.length > 6) {
						let seven = res.result.modules[6]
						this.seven = seven
						this.seven.list = res.result.modules.splice(6, res.result.modules.length)
					}
				}
				// 优惠券
				this.getCouponList()
			})
		},
		// 获取优惠券
		getCouponList() {
			let params = {
				activiId: this.activId
			}
			findCouponByActiv(params).then((res) => {
				if (res.code == 200) {
					console.log('优惠券', JSON.parse(JSON.stringify(res)));
					this.couponList = res.result.coupons.slice(0, 3)
					this.couponfa = false
				}
			});
		},
		// 监听页面滚动
		onpageScroll() {
			// 存储触底条件相关数据
			let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
			let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
			let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
			if (scrollTop + clientHeight + 1 >= scrollHeight) {

				// 后续调用
				if (this.loopcontrol_reach == 0) {
					this.loopcontrol_reach = 1
				}
			}
		},
		// 旋转
		rotate() {
			setTimeout(() => {
				this.boxunder = 'transform:rotateY(180deg)'
				this.boxup = 'transform:rotateY(0deg)'
			}, 2000)
			setTimeout(() => {
				this.boxunderCopy = 'transform:rotateY(180deg)'
				this.boxupCopy = 'transform:rotateY(0deg)'
			}, 4000)
		},
		// 反向旋转
		rotateback() {
			setTimeout(() => {
				this.boxunder = 'transform:rotateY(0deg)'
				this.boxup = 'transform:rotateY(-180deg)'
			}, 2000)
			setTimeout(() => {
				this.boxunderCopy = 'transform:rotateY(0deg)'
				this.boxupCopy = 'transform:rotateY(-180deg)'
			}, 4000)
		},
		// 跳转
		jump(id, activiId, sortGoodsId) {
			console.log(id, activiId, sortGoodsId);
			this.$router.push({
				name: 'sendnational',
				params: { id: id, activiId: activiId, sortGoodsId: sortGoodsId, time: this.time, timestate: this.timestate }
			});
		},
		// 点击领取优惠券
		clickCheckToUsePageHandle(item, index) {
			if (item) {
				let data = {
					activId: item.activId ? item.activId : null,
					activName: item.activName ? item.activName : null,
					fromTable: item.fromTable,
					couponId: item.id,
				}
				this.couponRquest = data
				let clientType = localStorage.getItem('Userhid')
				let accessToken = ''
				if (clientType == '1') {
					accessToken = "1";
					if (!localStorage.getItem('Usertoken')) {
						wx.miniProgram.redirectTo({
							appId: 'wx5b714f2180ea9ea0',
							url: '/pages/login/index?fakey=1'
						});
					}
					// 安卓
				} else if (clientType == '2') {
					accessToken = "2";
					if (!localStorage.getItem('Usertoken')) {
						accessToken = this.webViewBridgeAccessToken()
					}
					// iOS
				} else if (clientType == '3') {
					accessToken = "3";
					if (localStorage.getItem('Usertoken') == "X1") {
						this.getUserInfoToken(index);
					}
				}
				data.token = localStorage.getItem('Usertoken')
				if (accessToken && parseInt(item.couponLimit) != item.useCount) {
					receiveMemberCouponV4(data).then((res) => {
						console.log(res);
						if (res.code == 200) {
							this.touchPopup(1)
							this.getCouponList()
						} else {
							this.touchPopup(2)
							this.getCouponList()
						}
					});
				}
			}
		},
		// 立即使用优惠券
		useCoupon(index) {
			// 小程序/苹果端跳转使用券
			if (localStorage.getItem('Userhid')) {
				if (localStorage.getItem('Userhid') == 1 || localStorage.getItem('Userhid') == 3) {
					let item = this.couponList[index];
					if (item) {
						console.log(item);
						wx.miniProgram.navigateTo({
							appId: 'wx5b714f2180ea9ea0',
							url: '/pages2/childDay/coupon?id=' + item.activCouponId
						});
					}
					this.getUserInfoToken(index);
				}
			}
			// 安卓跳转使用券
			var andid = `shopcart.ui.fragment.ShopLargeFragment?id=${this.couponList[index].id}`
			window.MdhShop.openComActivityDetailPage(andid)
		},
		// 跳转商品详情页
		toGoodInfo(id) {
			console.log(id);
			var hid = ""
			var andid = `shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
			var iosid = `ZYProductDetailsVC?${id}`
			console.log(andid);
			if (localStorage.getItem('Userhid')) {
				hid = localStorage.getItem('Userhid');
				if (hid == 2) {
					// 安卓
					window.MdhShop.openComActivityDetailPage(andid)
				} else if (hid == 3) {
					// ios
					window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
				} else {
					wx.miniProgram.navigateTo({
						appId: 'wx5b714f2180ea9ea0',
						url: '/pages1/goodInfo/goodInfo?id=' + id,
					});
				}
			}
		},
		// 跳转首页
		goHome() {
			console.log(1);
			wx.miniProgram.switchTab({
				appId: 'wx5b714f2180ea9ea0',
				url: '/pages/index/index',
			});
		},
		// 三端跳转会场
		toJingangUrlPage(wxurl, andUrl, iosUrl) {
			console.log(wxurl, andUrl, iosUrl);
			console.log(localStorage.getItem('Userhid'));
			var hid = ""
			if (localStorage.getItem('Userhid')) {
				hid = localStorage.getItem('Userhid');
				if (hid == 2) {
					// 安卓
					window.MdhShop.openComActivityDetailPage(andUrl)
				} else if (hid == 3) {
					window.webkit.messageHandlers.openProductDetailPage.postMessage(iosUrl);
				} else {
					wx.miniProgram.navigateTo({
						appId: 'wx5b714f2180ea9ea0',
						url: wxurl,
					});
				}
			}
		},
		// 弹出层
		touchPopup(e) {
			if (e == 1) {
				this.$toast.success('领取成功');
			} else {
				this.$toast.fail('领取失败,请重试');
			}
		},
		// 安卓端处理Token(获取存储)
		webViewBridgeAccessToken() {
			let accessToken = localStorage.getItem('Usertoken')
			if (!accessToken) {
				accessToken = window.MdhShop.webViewBridgeAccessToken(true)
				localStorage.setItem("Usertoken", accessToken)
				this.couponRquest.token = localStorage.getItem("Usertoken")
				receiveMemberCouponV4(this.couponRquest).then((res) => {
					if (res.code == 200) {
						this.touchPopup(1)
						this.getCouponList()

					} else {
						this.getCouponList()
					}
				});
			}
			return accessToken
		},
		// iOS端处理Token(获取存储)
		getUserInfoToken(index) {
			accessToken = window.webkit.messageHandlers.getUserInfoToken.postMessage(this.couponList[index]);
			return accessToken
		},
		getUserInfoTokenIos(data) {
			localStorage.setItem("Usertoken", data)
			receiveMemberCouponV4(this.couponRquest).then((res) => {
				if (res.code == 200) {
					this.touchPopup(1)
					this.getCouponList()
				} else {
					this.getCouponList()
				}
			});
		}
	}

};
</script>
  
<style scoped>
/* image边框 */

.coupon_topcarth,
.coupon_toplimit li,
.coupon_botom ul:nth-child(1),
.boomshop_carthbox,
.hotshop_carthbox,
.more_goodstitle,
.dressstyle_gotext,
.upcarth ul,
.morecontentl_sign,
.morecontentl_carth,
.goodssign_box p {
	font-family: PingFangSC-Medium, PingFang SC;
}

/* --------------------------------- */
.box {
	padding: 0;
	margin: 0;
	width: 100%;
	display: flex;
	justify-content: center;

}


.pages {
	padding: 0;
	margin: 0;
	width: 100%;
	height: auto;
}

.pages_bgc {
	width: 100%;
	height: 100%;
	display: block;
	position: relative;
}

.pages_content {
	margin-top: -1%;
	padding: 0 3.2%;
	background: #FFA88D;
	display: flex;
	flex-direction: column;
}

/* 优惠券开始 */
.couponbox_outside {
	display: flex;
	justify-content: flex-start;
	height: 171px;
	position: relative;
	margin-bottom: 12px;
}

.couponbox_bgc {
	width: 100%;
	height: 100%;
	position: absolute;
}

.couponcase {
	width: 33%;
	height: 124px;
	margin-top: 31px;
	position: relative;
	z-index: 1;
}


.coupon_dicount {
	display: flex;
	justify-content: center;
	height: 74px;
}


.coupon_dicount ul:nth-child(1) {
	width: 10px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 110px;
}

.coupon_dicount ul:nth-child(2) {
	height: 74px;
	font-size: 53px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 74px;
}

.coupon_rules {
	text-align: center;
	height: 20px;
	margin: 0 auto;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 20px;
}

.coupon_btn {
	width: 61%;
	height: 24px;
	margin: 0 auto;
	background: #FF604A;
	border-radius: 12px;
	margin-top: 6px;

	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 20px;
}

.coupon_btnuse {
	background: rgba(255, 255, 255, 0);
	border: 1px solid #FF604A;
	color: #FF604A;
}

.coupon_btnused {
	color: #AAAAAA;
	background-color: #DDDDDD;
}

/* 优惠券结束 */
/* 主推爆款模块（旋转） 开始 */
.boomshop {
	width: 100%;
	height: 249px;
	position: relative;
	display: flex;
	justify-content: space-around;
	margin-top: 8px;
	margin-bottom: 12px;
}

.boomshop_bgc,
.hotshop_bgc {
	position: absolute;
	z-index: 0;
	height: 100%;
	width: 100%;
}

.boomshop_contentcase {
	width: 100%;
	height: 197px;
	position: relative;
	z-index: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	align-content: flex-start;
}

.boomshop_photo {
	width: 100%;
	height: 126px;
}

.boomshop_text {
	width: 88%;
	height: 28px;
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 14px;
	margin-top: 4px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	word-break: break-all;
	word-wrap: break-word;
}

.boomshop_trends {
	width: 80%;
	height: 12px;
	margin-top: 3px;
}

.boomshop_btn {
	width: 80%;
	height: 22px;
	background: linear-gradient(180deg, #FF604A 0%, #FF0022 100%);
	border-radius: 14px;
	margin-top: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.boomshop_btn_sign {
	width: 12px;
	height: 14px;
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 16px;
}

.boomshop_btn_carth {
	width: 20px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 22px;
}

/* 主推爆款模块（旋转） 结束 */

/* 活动专区,爆款直降 开始 */
.hotshop_list {
	background-color: #FFFFFF;
}

.boomshop_list .list_img,
.hotshop_list .list_img {
	object-fit: cover;
	width: 100%;
	border-radius: 4px;

}

.boomshop_list .list_img {
	height: 110px;
}

.boomshop_carthbox {
	width: 100%;
	top: -4px;
}




/* 活动专区 开始*/
.hotshop {
	width: 100%;
	height: 224px;

	position: relative;
	margin-bottom: 8px;
	margin-top: 12px;
}


.hotshop_listbox {
	width: 68%;
	height: 196px;
	position: absolute;
	top: 9%;
	right: 0;
	display: flex;
	overflow: scroll;
}

.hotshop_photo {
	width: 100% !important;
	height: 126px !important;
	overflow: hidden;
	background-color: #FFA88D;
}

.hotshop_text {
	width: 87%;
	height: 14px;
	margin: 0 auto;
	margin-top: 4px;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 14px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.hotshop_carth {
	width: 87%;
	height: 48px;
	margin: 0 auto;
	margin-top: 7px;
	display: flex;
	flex-direction: column;
	position: relative;
}

.hotshop_carth_top {
	width: 100%;
	height: 28px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 1;
}

.hotshop_carth_top li:nth-child(1) {
	width: 32px;
	height: 17px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #AAAAAA;
	line-height: 17px;
	text-decoration: line-through;
}

.hotshop_carth_top div {
	width: 64%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.hotshop_carth_top div li:nth-child(1) {
	width: 12px;
	height: 17px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 22px;
	text-decoration: none;
}

.hotshop_carth_top div li:nth-child(2) {
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 28px;
}

.hotshop_carth_bootm {
	height: 14px;
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FF0022;
	line-height: 14px;
	position: relative;
	z-index: 1;
}

.hotshop_carthsign {
	position: absolute;
	top: 0;
	left: 0;
	width: 100% !important;
	height: 100% !important;
}

.fullreduction {
	border-radius: 4px;
	background-color: #FFFFFF;
}

.fullreduction_title {
	border-radius: 4px 4px 0 0;
	height: 51px;
}

.fullreduction_title img {
	width: 100%;
	height: 100%;
}

.fullreduction_case {
	width: 96%;
	margin: 0 auto;
	height: 120px;
	display: flex;
	justify-content: space-between;
	margin-bottom: 6px;
	box-shadow: 0px 0px 4px 0px #DDDDDD;
	border-radius: 4px;
}


.fullreduction_left {
	position: relative;
	width: 34%;
}

.fullreduction_left_photo {
	width: 100%;
	height: 100%;
	border-radius: 4px;
}

.fullreduction_left_sign {
	position: absolute;
	top: 0;
	left: 0;
	width: 27px;
	height: 12px;
}

.fullreduction_right {
	width: 66%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.fullreduction_right_text {
	width: 92%;
	height: 42px;
	font-size: 15px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 21px;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	word-break: break-all;
	word-wrap: break-word;
}

.fullreduction_right_btn {
	width: 92%;
	height: 34px;
	margin-top: 32px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 45px 0 6px;
	box-sizing: border-box;
	position: relative;
}

.right_btnbgc {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
}

.right_carthtext {
	height: 14px;
	font-size: 14px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 14px;
	position: relative;
	z-index: 1;
}

.right_carthcase {
	position: relative;
	z-index: 1;
	display: flex;
}

.right_carthcase .sign {
	width: 12px;
	height: 17px;
	font-size: 12px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #000000;
	line-height: 32px;
}

.right_carthcase .carth {
	height: 28px;
	font-size: 20px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 28px;
}

.discount {
	height: 443px;
	margin-top: 8px;
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	justify-content: space-around;
	margin-bottom: 12px;
	position: relative;
}

.discount_bgc {
	position: absolute;
	width: 100%;
	height: 100%;
}

.discount_contentcase {
	width: 32%;
	height: 205px;
	background: #FFFFFF;
	border-radius: 4px;
	margin-top: 5px;
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: 1;
}

.discount_contentcase:nth-child(2) {
	margin-left: 66.5%;
	margin-top: 25px;
}

.discount_photo {
	width: 100%;
	height: 126px;
	border-radius: 4px;
}

.discount_text {
	margin: 0 auto;
	margin-top: 4px;
	width: 82%;
	height: 26px;
	font-size: 13px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	line-height: 14px;

	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	word-break: break-all;
	word-wrap: break-word;
}

.discount_trends {
	width: 53%;
	height: 12px;
	margin: 0 auto;
	margin-top: 3px;
}

.discount_btn {
	width: 65%;
	height: 22px;
	background: linear-gradient(180deg, #FF604A 0%, #FF0022 100%);
	border-radius: 14px;
	margin: 0 auto;
	margin-top: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.discount_btnsign {
	width: 12px;
	height: 14px;
	font-size: 10px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #FFFFFF;
	line-height: 16px;
}

.discount_btncarth {
	width: 20px;
	height: 22px;
	font-size: 16px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 22px;
}

/* 活动专区 结束 */

.skeletonbox {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 500vh;
	background-color: white;
}

/* 常规会场 热销品类开始 */
.boutiqueshop {
	height: 185px;
	margin-top: 12px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 12px;
}

.boutiqueshop_cont {
	width: 49%;
	height: 100px;
	border-radius: 4px;
	flex-grow: 0;
	flex-shrink: 0;
}

.boutiqueshop_cont:nth-child(n+3) {
	width: 32%;
	height: 80px;
	flex-grow: 0;
	flex-shrink: 0;
	margin-top: 5px;
}

.boutiqueshop_img {
	width: 100%;
	height: 100%;
}


/* 常规会场 热销品类结束 */
/* 精选会场 开始 */
.pattern {
	height: 332px;
	margin-top: 12px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 12px;
}

.pattern_case {
	width: 49%;
	height: 181px;
	border-radius: 4px;
	overflow: hidden;
}

.pattern_case img {
	width: 100%;
	height: 100%;
}

.pattern_case:nth-child(n+3) {
	width: 32%;
	height: 146px;
	margin-top: 5px;
}

/* 精选会场 结束 */
.pinzhiactive_outside {
	margin-top: 12px;
}

.pinzhiactive {
	position: relative;
	height: 290px;
	margin-bottom: 8px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	border-radius: 4px;
	overflow: hidden;
}

.pinzhiactive_bgc {
	position: absolute;
	width: 100%;
	height: 100%;
}

.pinzhiactive_contentcases {
	width: 50.8%;
	height: 266px;
	margin-right: 12px;
	position: relative;
	z-index: 1;
	display: flex;
	flex-direction: column;
}

.pinzhiactive_conttitle {
	width: 100%;
	height: 26px;
}

.pinzhiactive_cont {
	height: 240px;
	display: flex;
	flex-wrap: wrap;
}

.pinzhiactive_infocase {
	width: 50%;
	height: 120px;
	display: flex;
	flex-direction: column;
}

.pinzhiactive_infocase img {
	width: 100%;
	height: 100%;
}



.pinzhiactive_infoctext {
	height: 17px;
	font-size: 12px;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 500;
	color: #000000;
	line-height: 17px;
	margin-right: 5px;
}


.placeholder {
	width: 100px;
	height: 24px;
	margin: 20px auto;
}

/* 跑马灯样式 开始 */

.famous_school_item_swiper {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.famous_school_item_swiper .swiper-container {
	width: 100%;
	height: 100%;
}

.famous_school_item_swiper .swiper-wrapper {
	-webkit-transition-timing-function: linear;
	-moz-transition-timing-function: linear;
	-ms-transition-timing-function: linear;
	-o-transition-timing-function: linear;
	transition-timing-function: linear;
}

.famous_school_item_swiper .swiper-slide {
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: white;
	border-radius: 4px;
	overflow: hidden;
}

.famous_school_item_swiper .swiper-slide img {
	width: 100px;
	height: 100%;
}

/* 跑马灯样式 结束 */
/* 标题 */
.biaoti,
.biaoti2,
.biaoti3,
.biaoti4 {
	height: 30px;
	width: 198px;
	margin: 0 auto;
}


/* 翻转模块 */
.boomshopbox_out {
	position: absolute;
	top: 17%;
	width: 98%;
	height: 200px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	margin: 0 auto;
}

.boomshop_listbox {
	height: 100%;
	width: 32%;
	perspective: 1500;
	-webkit-perspective: 1500;
	-moz-perspective: 1500;
}

.boomshop_list {
	width: 100%;
	height: 100%;
	transition: all 2s;
	backface-visibility: hidden;
	border-radius: 8px;
	overflow: hidden;
	transform-style: preserve-3d;
	perspective: 800px;
	position: relative;
}



.boomshop_under {
	background-color: white;
}

.boomshop_up {
	margin-top: -197px;
	background-color: white;
	position: relative;
	z-index: -1;
	transform: rotateY(180deg) translateZ(1px);

}

/* 翻转模块结束 */
</style>
  