<template>
    <div>
        <div class="goods" v-if="state == false" @click="toGoodInfo(item.id)">
            <img class="goods_img" :src="item.thumbnail" alt="" />
            <div class="goods_name">{{ item.prodName }}</div>
            <div class="goods_price">
                <img class="good_bg" v-if="(item.marketPriceOffline - item.marketPrice) > 0"
                    :src="globalData.staticUrl + '/images/MotherDay/good_bg.png'" alt="" />
                <img class="secondary_qianggou" v-if="(item.marketPriceOffline - item.marketPrice) <= 0"
                    :src="globalData.staticUrl + '/images/MotherDay/secondary_qianggou.png'" alt="" />
                <div class="price_info" v-if="(item.marketPriceOffline - item.marketPrice) > 0">
                    <div class="price_info_top">直降{{ item.marketPriceOffline - item.marketPrice }}元</div>
                    <div class="price_info_bom">
                        <div class="bom_left">
                            <p>￥</p>
                            {{ item.marketPrice }}
                        </div>
                        <div class="bom_right">
                            去抢购<img class="good_jiantou" :src="globalData.staticUrl + '/images/MotherDay/good_jiantou.png'"
                                alt="" />
                        </div>
                    </div>
                </div>
                <div class="price_info" v-if="(item.marketPriceOffline - item.marketPrice) <= 0">
                    <div class="price_rob">
                        <p>抢购价：</p>
                        <div class="bom_left">
                            <p>￥</p>{{ item.marketPrice }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 普通开始 -->
        <div class="goods" v-if="state == true" @click="toGoodInfo(item.id)">
            <img class="good_img" :src="item.thumbnail" alt="" />
            <div class="goods_name ">
                <div class="activImage" v-if="item.goodLabels&&item.goodLabels[0].activImage">
                    <img :src="item.goodLabels[0].activImage" alt="">
                </div>
                <p class="new" v-if="item.isNew == 1">新品</p>
                {{ item.prodName }}

            </div>
            <div class="good_price">
                <div class="price_left">
                    <p>￥</p>
                    {{ item.marketPrice }}
                </div>
                <div class="price_right" v-if="item.marketPriceOffline - item.marketPrice > 0">￥{{ item.marketPriceOffline
                }}
                </div>
            </div>
            <div class="good_label" v-if="item.coupons&&item.coupons.length>0">
                <p >{{ item.coupons[0].name }}</p>
            </div>
        </div>
        <!-- 首页样式 -->
        <div class="more_contentl" v-if="state == 'main'" @click="toGoodInfo(item.id)">
            <img class="morecontentl_img" :src="item.thumbnail" alt="">
            <p class="morecontentl_text">{{ item.prodName }}</p>
            <!-- 含直降 -->
            <li class="morecontentl_carthx" v-if="item.marketPriceOffline - item.marketPrice > 0">
                <p class="morecontentl_sign">￥</p>
                <p class="morecontentl_carth">{{ item.marketPrice }}</p>
                <img :src="globalData.staticUrl + '/images/MotherDay/goods_price.png'" alt="">
                <div class="upcarth">
                    <ul>直降</ul>
                    <ul>{{ item.marketPriceOffline - item.marketPrice }}</ul>
                </div>
            </li>
            <!-- 不含直降 -->
            <li class="morecontentl_cartzj" v-if="item.marketPriceOffline - item.marketPrice <= 0">
                <p class="morecontentl_sign">￥</p>
                <p class="morecontentl_carth">{{ item.marketPrice }}</p>
            </li>
        </div>
    </div>
</template>
<script>
import { globalData } from "@/utils/config";
export default {
    props: ['item', 'state'],
    data() {
        return {
            globalData: globalData,
        }
    },
    mounted() {
    },
    watch: {



    },
    methods: {
        toGoodInfo(id) {
            this.$emit('good-click', id);
        }
    }
};
</script>
<style lang="scss" scoped>
.goods {
    width: 100%;
    position: relative;
    background-color: #ffffff;
    color: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 4px;
    // border: 1px solid #000;

    // 直降开始
    .goods_img {
        width: 100%;
        height: 190px;
        // object-fit: contain;
        object-fit: cover;
        border-radius: 6px 6px 0px 0px;
        margin-bottom: 2px;
    }

    &_text {
        width: 28px;
        height: 38px;
        position: absolute;
        top: 4px;
        left: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            position: relative;
        }

        .burst {
            position: absolute;
            top: 14px;
            font-size: 15px;
            z-index: 1;
        }
    }

    .new {
        width: 30px;
        height: 16px;
        margin-left: 3px;
        background: linear-gradient(90deg, #CFB98E 0%, #A27842 100%);
        border-radius: 2px;
        font-size: 10px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
    }

    &_name {
        width: 96%;
        font-size: 14px;
        margin-bottom: 2px;
        // line-height: 20px;
        color: #333333;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden; //溢出内容隐藏
        text-overflow: ellipsis; //文本溢出部分用省略号表示
        display: -webkit-box; //特别显示模式
        -webkit-line-clamp: 2; //行数
        line-clamp: 2;
        -webkit-box-orient: vertical;

        p {
            width: 34px;
            height: 16px;
            background: #ffd4d1;
            border-radius: 4px;
            display: inline-block;
            line-height: 16px;
            font-size: 11px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #FF0022;
            text-align: center;
        }
    }

    &_price {
        width: 100%;
        height: 50px;
        // border: 1px solid #000;
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        // border: 1px solid #000;
        .good_bg {
            width: 96%;
            height: 100%;
        }

        .secondary_qianggou {
            margin-top: 3%;
            width: 96%;
            height: 80%;
            // border: 1px solid #000;
        }

        .price_info {
            width: 100%;
            height: 100%;
            position: absolute;
            bottom: 0;
            font-size: 12px;

            &_top {
                margin-left: 22%;
                margin-top: 4px;
            }

            &_bom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-left: 22%;
                height: 28px;
                padding-right: 5%;
                box-sizing: border-box;

                .good_jiantou {
                    width: 8px;
                    height: 8px;
                }
            }

            .bom_left {
                font-size: 20px;

                p {
                    font-size: 12px;
                    margin-bottom: 7%;
                }

                display: flex;
                align-items: self-end;
            }

            .price_rob {
                font-size: 10px;
                position: absolute;
                bottom: 20%;
                left: 5%;
                display: flex;
                align-items: self-end;

                // border: 1px solid #000;
                p {
                    font-size: 10px;
                    margin-bottom: 3%;
                    // border: 1px solid #000;
                }

                .bom_left {
                    p {
                        font-size: 10px;
                        margin-bottom: 7%;
                        // border: 1px solid #000;
                    }
                }

            }
        }
    }

    // 直降结束
    // 普通开始
    .good_img {
        width: 100%;
        height: 194px;
        // object-fit: contain;
        object-fit: cover;
        border-radius: 6px 6px 0px 0px;
        margin-bottom: 2px;
    }

    .good_price {
        width: 100%;
        padding: 0 4px;
        // border: 1px solid #000;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .price_left {
            p {
                font-size: 14px;
                margin-bottom: 2px;
            }

            font-size: 18px;
            font-weight: 600;
            color: #FF0022;
            display: flex;
            align-items: self-end;
        }

        .price_right {
            font-size: 16px;
            color: #aaaaaa;
            text-decoration: line-through;
        }
    }

    .good_label1 {
        height: 16px;
    }

    .good_label {
        width: 100%;
        padding: 0 4px;
        box-sizing: border-box;
        color: #FF0022;
        display: flex;
        font-size: 11px;
        margin-bottom: 4px;

        p {
            padding: 0 4px;
            box-sizing: border-box;
            height: 16px;
            border-radius: 4px;
            border: 1px solid #fc5044;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 4px;
        }
    }
}

// 首页样式
.more_contentl,
.more_contentr {
    /* border: 1px solid #000; */
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 6px;
    border-radius: 6px;
}

.more_contentl:nth-child(2n) {
    margin-left: 5px;
}

.morecontentl_img,
.morecontentr_img {
    border-radius: 6px 6px 0 0;
    /* border: 1px solid #000; */
    width: 100%;
    height: 180px;
    // object-fit: contain;
    object-fit: cover;
}

.morecontentl_text,
.morecontentr_text {
    /* border: 1px solid #000; */
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0;
    margin-top: 2px;
    width: 95%;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
}

.morecontentl_carthx,
.morecontentl_cartzj {
    /* border: 1px solid #000; */
    width: 95%;
    height: 32px;
    background: #FFD8D6;
    border-radius: 4px;
    margin-bottom: 3%;
    position: relative;
}

.morecontentr_carthx,
.morecontentl_cartzj {
    /* border: 1px solid #000; */
    width: 95%;
    height: 25px;
}

.morecontentl_cartzj {
    background: white;
}

.morecontentl_sign,
.morecontentl_carth {
    float: left;
    margin: 0;
    margin: 7px 0 0 2px;
    font-size: 12px;
    font-weight: 500;
    color: #FF0022;
    line-height: 17px;
}


.morecontentr_sign {
    margin-top: 4px;
}

.morecontentr_carth {
    font-size: 20px;
}



.morecontentl_sign {
    margin-top: 10px;
}

.morecontentl_carth {
    font-size: 24px;
    font-weight: 600;
    margin-left: 0;
}

.morecontentl_carthx img {
    width: 42px;
    height: 32px;
    float: right;
}

.upcarth {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 10%;
    right: 5.5%;
}

.upcarth ul {
    font-size: 10px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 12px;
}

.activImage {
    position: relative;
    top: 2px;
    width: 48px;
    height: 14px;
    margin-right: 2px;
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
    }
}</style>