<template>
    <div class="outSideCase">
        <!-- 标题栏 -->
        <div class="header">
            <ul class="header_content">
                <li class="pageSign">
                    <img src="../../assets/image/homePage/sign_text.png" alt="">
                    <img src="../../assets/image/homePage/sign.png" alt="" @click="ababab(1, 0)">
                </li>
                <li class="homePage">
                    <a href="#/">首页</a>
                </li>
                <li class="download">
                    <p><a href="https://jw.modewe.com/美都汇商城.apk">下载美都汇</a></p>
                    <van-icon name="back-top" class="download_sign" />
                </li>
                <li class="aboutUas" @click="changeState" :style="aboutUasUnfold">
                    <div>
                        <p>关于我们</p>
                        <van-icon v-if="aboutState == false" name="arrow-down" class="aboutUas_sign" />
                        <van-icon v-else name="arrow-up" class="aboutUas_sign" />
                        <p class="introduction" @click="ababab(2, 1)">
                            <a href="#/companyInfo">公司简介</a>
                        </p>
                        <p class="introduction">
                            <a href="#/privacypolicy">隐私协议</a>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 选择栏 -->
        <div class="bodyContent">
            <ul class="bodyContent_title">
                <li v-for="(item, index) in list" :class="{ actived: active == index }" @click="change(index)">
                    <p @click="test">{{ item }}</p>
                </li>
            </ul>
        </div>
        <!-- 公司简介内容 -->
        <div>
            <ul class="bodyContent_text1">
                公司简介
            </ul>
            <ul class="bodyContent_text2">
                <li>美都汇是以经营服装、鞋类、饰品、百货、家居为主的专业性时尚平价商城，总部设立在山东省会济南市。</li>
                <li>美都汇从2002年第一家门店开业至今，已经发展成立了21家连锁门店，遍布12座地级市，门店单店经营面积从5000平方至20000平方不等，</li>
                <li>2022年美都汇线上商城同步上线，让顾客购物更便捷，公司从商品采购，物流配送，再到门店运营，全程一条龙管理，</li>
                <li>同时还培养了数千人的门店运营团队和专业采购团队人才，</li>
                <li>与全国厂商直接合作，千家厂商直接供货，省去中间差价，对接前沿最新流行趋势。</li>
                <li>商品品类涵盖男、女、童鞋服、百货、饰品、家居在内的万款商品。</li>
            </ul>
            <ul class="connection">
                <li class="connection_img">
                    <div>
                        <img src="../../assets/image/homePage/mdhshop.apk.png" alt="">
                    </div>
                </li>
                <li class="connection_number">
                    <div class="numtitle">联系方式</div>
                    <div class="num">联系电话：18064626163</div>
                    <div class="mail">邮箱：mdh_appshangjia@126.com</div>
                    <div class="location">公司总部地址</div>
                    <div class="location_contene">
                        <ul>济南美都汇电子商务有限公司</ul>
                        <ul>山东省济南市天桥区济洛路82号齐鲁鞋城二期商务楼912</ul>
                    </div>
                </li>
            </ul>
        </div>
        <!-- 底部信息部分 -->
        <div class="bottomInfo">
            <ul class="bottomInfo_single">
                <img src="../../assets/image/homePage/sign_text.png" alt="">
            </ul>
            <ul class="bottomInfo_text1">
                <li><a href="https://jw.modewe.com/美都汇商城.apk">下载美都汇</a></li>
                <li><router-link to="/companyInfo">公司简介</router-link></li>
                <li><router-link to="/privacypolicy">隐私协议</router-link></li>
                <li>联系我们</li>
            </ul>
            <ul class="bottomInfo_text2">
                <li>www.jw.modewe.com 2021-2023 All Rights Reserved. </li>
                <li>营业执照：91370105MA7G4ED826</li>
                <li>
                    <img src="../../assets/image/homePage/emblem.png" alt="">
                    <p style="white-space: nowrap;"> © 2023-2024 modewe.com 版权所有</p>&nbsp;
                    <a class="bottomInfo_text2a" href="https://beian.miit.gov.cn/#/Integrated/index">
                        鲁ICP备2022009892号-2
                    </a>
                </li>
            </ul>
        </div>

    </div>
</template>
  
<script>
import homeContent from './homeContent.vue';
import secretInfo from '@/views/mdhOfficial/secretInfo.vue';
export default {
    props: ['aa', 'bb'],
    components: {
        secretInfo, homeContent
    },
    watch: {
        aa(aa) {
            console.log(aa);

        },
        bb(bb) {
            console.log(bb);
            if (bb == 1) {
                this.active = 0
            } else {
                this.active = 1
            }
        }
    },
    data() {
        return {
            aboutState: false,
            aboutUasUnfold: "",
            //记录用户点击按钮的下标
            active: 0,
            list: ["公司简介", "隐私协议"]
        }
    },
    created() {
    },
    methods: {
        changeState() {
            this.aboutState == false ? this.aboutState = true : this.aboutState = false
            this.aboutState == false ? this.aboutUasUnfold = "height:100%" : this.aboutUasUnfold = "height:240%"
        },
        /*切换需要高亮的按钮*/
        change(index) {
            if (index == 1) {
                this.$router.push("/privacypolicy");
            }
        },
        ababab(a, b) {
            if (a == 1 && b == 0) {//首页
                if (this.isindex != true) {
                    this.$router.push("/");
                }
            }
        },
        test() {
            console.log(this.comName);
        },
    },
};
</script>
<style scoped>
.bodyContent {
    height: auto;
}

.bodyContent_title p {
    margin-top: 34px;
    white-space: nowrap;
}

.actived {
    border-bottom: 4px solid #FF0E3E;
}

.bodyContent_title {
    display: flex;
    height: 85px;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 25px;
}

.bodyContent_title li:nth-child(1) {
    margin-left: 23.6%;
}

.bodyContent_title li:nth-child(2) {
    margin-left: 103px;
}

.bodyContent_text1 {
    text-align: center;
    height: 42px;
    font-size: 30px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 42px;
    margin-top: 115px;
}

.bodyContent_text2 {
    margin-top: 100px;
}

.bodyContent_text2 li {
    text-align: center;
    width: 1000px;
    margin: 0 auto;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 26px;
}

.connection {
    margin-top: 161px;
    margin-bottom: 211px;
    display: flex;
    justify-content: center;
}

.connection_img {
    border-right: 1px dashed;
    width: 301px;
    height: 202px;
    margin-left: 205px;
}

.connection_number {

    width: 397px;
    height: 202px;
    margin-left: 99px;
}

.connection_img img {
    height: 100%;
    width: 202px;

}

.numtitle,
.location {
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
}

.num,
.mail,
.location_contene {

    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 22px;
    white-space: nowrap;
}

.num,
.location_contene {
    margin-top: 14px;
}

.location {
    margin-top: 24px;
}

* {
    user-select: none;
}

a {
    color: #333333;
}

.header p {
    width: 100%;
    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    line-height: 22px;
    text-align: center;
}

.homePage p:hover {
    color: #FF0E3E;
}

.download:hover .download_sign,
.aboutUas:hover .aboutUas_sign {
    color: #FF0E3E;
}

.introduction:hover a,
.protocols:hover a {
    color: #FF0E3E;
}

.header {
    height: 100px;
}

.header_content {
    height: 50%;
    width: 500px;
    margin: 0 auto;
    position: relative;
    top: 24px;
    display: flex;
    white-space: nowrap;
}



.pageSign,
.homePage,
.download {
    width: 25%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}
.pageSign img:nth-child(1) {
    position: absolute;
    top: 0;
    left: -66px;
    transform: scale(0.5);
}

.aboutUas {
    width: 25%;
    height: 100%;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
    z-index: 1;
    overflow: hidden;
    transition: all .3s;
    background-color: white;
}

.pageSign img {
    height: 100%;
}

.download_sign {
    font-size: 18px;
    transform: rotate(180deg);
}



.aboutUas p {
    margin-top: 14px;
}

.aboutUas_sign {
    margin-top: 15px;
    position: absolute;
    top: 0;
    left: 82%;
}

.aboutUas_sign,
.download_sign {
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

}

/* body样式 */
.body_content,
.shop_content,
.body_download {
    height: 1080px;

    overflow: hidden;
}

.body_content img {
    position: relative;
    width: 100%;
    height: 100%;
}

/* 商品页 */
.text_1 {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 188px;
}

.text_2 {
    text-align: center;
    height: 33px;
    font-size: 24px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 33px;
    margin-top: 22px;
}

.shop_Introduction {
    margin-top: 100px;

    display: flex;
    justify-content: center;
}

.shop_Introduction img {
    width: 230px;
    height: 498px;
}

.shop_Introduction img:nth-child(2) {
    margin: 0 10px 0 10px;
}

.shop_Introduction img:nth-child(3) {
    margin-right: 10px;
}

.body_downloadcont {
    text-align: center;
    height: 50px;
    font-size: 36px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 50px;
    margin-top: 200px;
}

.body_downloadoperate {

    margin-top: 200px;
    display: flex;
    justify-content: center;
}

.body_downloadoperate li:nth-child(2) {
    margin: 0 10px 0 10px;
}

.downClassify_ad,
.downClassify_ios,
.downClassify {
    width: 290px;
    height: 290px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all .4s;
    position: relative;
}

.downClassify_ad:hover,
.downClassify_ios:hover {
    background-color: #FF0E3E;
}

.downClassify_ad:hover p,
.downClassify_ios:hover p {
    color: white;
}

.downClassify_ad:hover .active,
.downClassify_ios:hover .active {
    display: block;
}

.downClassify_ios img {
    width: 60px;
    height: 74px;
}

.downClassify_ad img {
    width: 60px;
    height: 72px;
}

.body_downloadoperate p {
    width: 52px;
    height: 25px;
    font-size: 18px;
    font-family: STKaitiSC-Regular, STKaitiSC;
    font-weight: 400;
    color: #777777;
    line-height: 25px;
    margin-top: 20px;
}


.downClassify_ad p {
    margin-right: 14px;
}

.active {
    position: absolute;
    top: 86px;
    display: none;
}

.bottomInfo {
    padding-top: 100px;
    height: 400px;
    background: rgba(239, 239, 239, 0.77);
}

/* 底部信息 */
.bottomInfo_single {
    width: 220px;
    height: 100px;
    margin: 0 auto;
}

.bottomInfo_single img {
    width: 100%;
    height: 100%;
}

.bottomInfo_text1 {
    margin: 0 auto;

    width: 26%;
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
}

.bottomInfo_text1 li,
.bottomInfo_text1 a {

    height: 22px;
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 22px;
}

.bottomInfo_text1 li {
    width: 80px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottomInfo_text2 {
    margin: 0 auto;
    margin-top: 68px;
    width: 357px;
    height: 72px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #777777;
    line-height: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.bottomInfo_text2a {
    white-space: nowrap;
    color: #777777
}

.bottomInfo_text2a:hover {
    color: #FF0E3E;
}

.bottomInfo_text2 img {
    width: 15px;
    height: 14px;
}

.bottomInfo_text2 li:nth-child(3) {
    display: flex;
    align-items: center;

}
</style>


  