<template>
  <div class="bg" :style="{ background: moduleColor }">
    <img v-if="this.id == '1666979843713011713'" class="moduleImg1" :src="moduleImg" alt="" />
    <img v-else class="moduleImg2" :src="moduleImg" alt="" />
    <div class="con" v-if="this.id == '1666981055460020226'">
      <!-- 直降-->
      <good_list :list="goodsList" :state="false" @good-click1="toGoodInfo"></good_list>
    </div>
    <div class="con" v-else>
      <!-- 普通 -->
      <good_list :list="goodsList" :state="true" @good-click1="toGoodInfo"></good_list>
    </div>
    <div class="loading" @click="getModuleProListByModule">{{ loading }}</div>
  </div>
</template>
<script >
import wx from "weixin-js-sdk"
import { globalData } from "../../utils/config";
import { fatherMoreList, findShopComActivitiModuleById } from '../../utils/api/fatherDayapi'
import good_list from "@/components/good_list.vue";
export default {
  name: 'Secondary',
  components: { good_list },
  data() {
    return {
      loopcontrol: 0,
      loopcontrol_reach: 0,
      globalData: globalData,
      moduleImg: '',//标题
      moduleColor: '',//背景颜色
      goodsList: [],//数组
      pageNo: 1,//请求页数
      loading: '加载更多',//加载更多名字
      open: false,
      id: ''
    };
  },
  created() {
    this.getStyle()
  },
  mounted() {
    this.getModuleProListByModule()
    window.addEventListener('scroll', this.handleScroll);//监听滚动条
  },
  methods: {
    // 滚动到底部时加载更多数据
    handleScroll() {
      if (this.open) {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        let clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
        let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
          if (this.loopcontrol_reach == 0) {
            this.getModuleProListByModule()
            this.loopcontrol_reach = 1
          }
        }
      }
    },

    // 获取模块下的商品
    getModuleProListByModule() {
      var route = this.$route.params
      var list = this.goodsList
      var param = {
        activId: route.activiId,
        moduleId: route.id,
        sortGoodsId: route.sortGoodsId,
        pageNo: this.pageNo,
        pageSize: 9,
      };
      fatherMoreList(param).then(res => {
        if (res.code == 200) {
          this.goodsList = this.goodsList.concat(res.result.records)
          this.pageNo += 1
          this.open = true
          if (res.result.records && res.result.records.length > 0) {
            if (this.pageNo > res.result.pages) {
              this.loading = '没有更多了'
            } else {
              this.loading = '加载更多';
              this.loopcontrol_reach = 0
            }
          }
        }
      });

    },
    // 获取背景颜色和标题
    getStyle() {
      var route = this.$route.params
      this.id = route.id
      var param = {
        id: route.id
      }
      findShopComActivitiModuleById(param).then(res => {
        let end = res.result.records[0].moduleImg.split(","); // 将字符串以空格为分隔符转换成数组
        this.moduleImg = end[1];
        this.moduleColor = res.result.records[0].moduleColor
      })
    },
    // 调转到小程序详情页
    toGoodInfo(id) {
      console.log(id);
      var hid = ""
      var andid = `com.mshop.shopcart.ui.fragment.ProdDetailFragment?prodClsId=${id}`
      var iosid = `ZYProductDetailsVC?${id}`
      console.log(andid);
      if (localStorage.getItem('Userhid')) {
        hid = localStorage.getItem('Userhid');
        if (hid == 2) {
          // 安卓
          window.MdhShop.openComActivityDetailPage(andid)
        } else if (hid == 3) {
          // ios
          window.webkit.messageHandlers.openProductDetailPage.postMessage(iosid);
        } else {
          wx.miniProgram.navigateTo({
            appId: 'wx5b714f2180ea9ea0',
            url: '/pages1/goodInfo/goodInfo?id=' + id,
          });
        }
      }
    },
    back() {
      this.$router.replace("/");
    }
  },
};
</script>

<style lang="scss" scoped>
.bg {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: PingFangSC-Regular, PingFang SC;

  .moduleImg1 {
    width: 65.6%;
    height: 42px;
    margin: 0 auto;
    margin-top: 12px;
    margin-bottom: 5px;
  }

  .moduleImg2 {
    width: 93.6%;
    height: 51px;
    margin: 0 auto;
    margin-top: 12px;
  }

  .con {
    width: 93.6%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

  }

  .loading {
    margin: 0 auto;
    color: #000;
  }
}
</style>
