import Vue from "vue";
import VueRouter from "vue-router";
// 父亲节
import father from "../views/fatherDay/index";
import secondary from "../views/fatherDay/secondary";
// 端午节
import zongziDay from "../views/zongziDay/index";
import sendzongziDay from "../views/zongziDay/sendzongziDay";
//七夕
import qixi from "../views/qixiDay/index";
import sendQixiDay from "../views/qixiDay/sendQixiDay";
//国庆
import national from "../views/nationalDay/index";
import sendnational from "../views/nationalDay/sendnational";
//万圣节
import halloweenDay from "../views/halloweenDay/index";
//双十一
import doubleeleven from "../views/doubleeleven/index";
// 首页
import mdhOfficial from "../views/mdhOfficial/index";
import companyInfo from "../views/mdhOfficial/companyInfo"
import privacypolicy from "../views/mdhOfficial/secretInfo";
import jumpPrompt from "../views/mdhOfficial/jumpPrompt";
import jumpWx from "../views/jumpWx.vue";



Vue.use(VueRouter);

const routes = [];

const router = new VueRouter({
  mode: "hash",
  //基本的路由请求的路径
  base: process.env.BASE_URL,
  routes: [
    // 官网
    { path: "/", name: 'mdhOfficial', component: mdhOfficial },
    { path: "/companyInfo", name: 'companyInfo', component: companyInfo },
    { path: "/privacypolicy", name: 'privacypolicy', component: privacypolicy },
    { path: "/jumpPrompt", name: 'jumpPrompt', component: jumpPrompt },
    // 父亲
    { path: "/father", name: 'father', component: father },
    { path: "/secondary", name: 'Secondary', component: secondary },
    // 中秋
    { path: "/zongziDay", name: 'zongziDay', component: zongziDay },
    { path: "/sendzongziDay", name: 'sendzongziDay', component: sendzongziDay },
    // 七夕
    { path: "/qixi", name: 'qixi', component: qixi },
    { path: "/sendQixiDay", name: 'sendQixiDay', component: sendQixiDay },
    // 国庆
    { path: "/national", name: 'national', component: national },
    { path: "/sendnational", name: 'sendnational', component: sendnational },
    // 万圣节
    { path: "/halloweenDay", name: 'halloweenDay', component: halloweenDay },
    { path: "/jumpWx", name: 'jumpWx', component: jumpWx },
    // 双十一
    { path: "/doubleeleven", name: 'doubleeleven', component: doubleeleven },

    // { path: "/mdhOfficial", name: 'mdhOfficial', component: mdhOfficial },
    // {
    //   path: "/mdhOfficial",
    //   component: mdhOfficial,
    //   children: [{ path: "childPage", name: "childPage", component: childPage }],
    // },
  ],
});

export default router;
