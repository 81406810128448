<template>
    <div>跳转
        <div v-html="wxHtml"></div>
    </div>
</template>
<script >
import wx from "weixin-js-sdk"

export default {
    data() {
        return {
            wxHtml: ''
        }
    },
    methods: {
        config() {
            wx.config({
                debug: true, // 调试时可开启
                appId: 'wx5b714f2180ea9ea0',
                timestamp: 0, // 必填，填任意数字即可
                nonceStr: 'nonceStr', // 必填，填任意非空字符串即可
                signature: 'signature', // 必填，填任意非空字符串即可
                jsApiList: ['chooseImage'], // 安卓上必填一个，随机即可
                openTagList: ['wx-open-launch-weapp'], // 填入打开小程序的开放标签名
            })
            this.getHtml()


        },
        getHtml() {
            let script = document.createElement('script')
            script.type = 'text/wxtag-template'
            script.text = `<button class="btn blue mt1" style="height: 50px;width: 100px;">跳转小程序</button>`
            this.wxHtml =
                `<wx-open-launch-weapp style="height: 50px;width: 100px;"
                                  id="launch-btn"
                                    appid="wx5b714f2180ea9ea0"
                                    username="gh_b445ff915955"
                                    path="pages/index/index.wxml"
                                  >${script.outerHTML}
                                </wx-open-launch-weapp>`
            this.$nextTick(() => {
                let launchButton = document.getElementById('launch-btn')
                launchButton.addEventListener('launch', function (e) {
                    console.log('success', e)
                })

                launchButton.addEventListener('error', function (e) {
                    console.log('fail', e)
                })
            })
        }
    },
    mounted() {
        this.config()
    },

}
</script>